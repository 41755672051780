


// import React, { useState, useEffect, useRef } from 'react';
// import { useInView } from 'react-intersection-observer';
// import { ChevronRight } from 'lucide-react';
// import './LandingPage.css';

// /** Example: your existing imports **/
// import UploadedImage from '../icons/img_screenshot_nonpayables.png';
// import Icon1 from '../icons/img_full_cover_icon.png';
// import Icon2 from '../icons/img_target_recover.png';
// import Icon3 from '../icons/img_reduce_operation_png.png';
// import before_ice from '../icons/before_ice.png';
// import with_ice from '../icons/with_ice.png';
// import before_ice2 from '../icons/2before_ice.png';
// import with_ice2 from '../icons/2withice.png';
// import before_ice3 from '../icons/before_ice3.png';
// import with_ice3 from '../icons/with_ice3.png';
// import robot from "../icons/30.png";
// import human from "../icons/29.png";
// import chat from '../icons/chat-2.png';
// import logo from '../icons/logonew.svg';
// /** A small helper to clamp a value within [min, max]. */
// function clamp(value, min, max) {
//   return Math.max(min, Math.min(value, max));
// }

// const LandingPage = () => {
//   // === 1) Intersection Observers for text + cards
//   const [textRef, textInView] = useInView({ threshold: 0.2, triggerOnce: true });
//   const [cardsRef, cardsInView] = useInView({ triggerOnce: true });

//   const [visibleLetters, setVisibleLetters] = useState([]);
//   const [cardsVisible, setCardsVisible] = useState(false);

//   // The text to appear letter by letter
//   const text =
//     'Revolutionize your claim handling with ICE, where speed meets precision. ' +
//     'Our AI-driven platform processes claims in under 10 minutes, topping ' +
//     'industry TAT numbers, eliminating human errors, and cutting operational costs.';

//   useEffect(() => {
//     if (textInView) {
//       text.split('').forEach((_, index) => {
//         setTimeout(() => {
//           setVisibleLetters((prev) => [...prev, index]);
//         }, index * 10);
//       });
//     }
//   }, [textInView, text]);

//   useEffect(() => {
//     if (cardsInView) {
//       setCardsVisible(true);
//     }
//   }, [cardsInView]);

//   return (
//     <div className="landing-container">
//       {/* ========== Navigation ========== */}
//       <nav className="nav-container">
//     <div className="logo-container">
//       <img
//         src={logo} // Replace with the actual path of your logo image
//         alt="ICE Logo"
//         className="logo-image"
//       />
//       <span className="logo-text">ICE</span>
//     </div>
//     <div className="nav-buttons">
//       <button className="btn-request">Request access</button>
//       <button className="btn-sign-in">Sign In</button>
//     </div>
//   </nav>


//       {/* ========== Hero Section ========== */}
//       <main className="hero-section">
//         <div className="hero-content">
//           <h1 className="hero-title">
//             The <span className="highlight">AI-Powered</span> Platform for
//             <br />
//             Insurance Claim Processing
//           </h1>
//           <p className="hero-subtitle">
//             Slash your turn around time and process a claim in under
//             <br />
//             10 minutes using ICE's cutting edge AI.
//           </p>
//           <button className="cta-button">
//             Apply for Access
//             <ChevronRight size={20} />
//           </button>

//           {/* Example hero image */}
//           <div className="image-container">
//             <img
//               src={UploadedImage}
//               alt="Uploaded visualization"
//               className="uploaded-image"
//             />
//           </div>

//           {/* Animated Text Section */}
//           <div ref={textRef} className="animated-text-container">
//             <p className="animated-text">
//               {text.split('').map((letter, index) => (
//                 <span
//                   key={index}
//                   className={visibleLetters.includes(index) ? 'visible' : 'hidden'}
//                 >
//                   {letter}
//                 </span>
//               ))}
//             </p>
//           </div>
//         </div>

//         {/* ========== Why ICE Section ========== */}
//         <div
//           ref={cardsRef}
//           className={`cards-section ${cardsVisible ? 'visible' : ''}`}
//         >
//           <h2 className="why-ice-title">Why ICE</h2>
//           <div className={`cards-container ${cardsVisible ? 'visible' : ''}`}>
//             <div className="card card-1">
//               <img src={Icon1} alt="Fast Processing" className="card-logo" />
//               <h3>Shatter your Turnaround Time Targets</h3>
//               <p>
//                 ICE's advanced AI technology processes claims rapidly and accurately.
//                 By reducing the claim processing time to under four minutes flat, we
//                 ensure your workflows are faster, enabling quicker payouts and enhancing
//                 customer satisfaction.
//               </p>
//             </div>
//             <div className="card card-2">
//               <img src={Icon2} alt="Precision" className="card-logo" />
//               <h3>Precision At Scale</h3>
//               <p>
//                 Deploy the most sophisticated OCR and machine learning algorithms to
//                 handle and parse large volumes of claims without sacrificing accuracy.
//                 Our system minimizes errors and ensures that every detail is captured
//                 correctly, supporting your team in making informed decisions.
//               </p>
//             </div>
//             <div className="card card-3">
//               <img src={Icon3} alt="Cost Reduction" className="card-logo" />
//               <h3>Cost Reduction</h3>
//               <p>
//                 Cut down on operational costs by automating the bulk of claim processing
//                 tasks. ICE's AI-driven system reduces the need for extensive manual
//                 intervention, allowing you to allocate resources more effectively and
//                 focus on strategic growth.
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* ========== #1, #2, #3 Before / With ICE ========== */}
//         {/* #1 Before ICE */}
//         <div className="before-ice">
//           <h2>#1 Before ICE</h2>
//           <p className="claim-tag">Claim #112256325</p>
//           <p className="claim-description">
//             A claim in April 2024 for laparoscopic hernia repair surgery. The patient has
//             been enrolled in the plan since November 2022.
//           </p>
//         </div>
//         <div className="before-ice-section">
//           <div className="claim-details">
//             <img src={before_ice} alt="Claim Details" className="claim-image" />
//           </div>
//         </div>

//         {/* #1 With ICE */}
//         <div className="before-ice">
//           <h2>#1 With ICE</h2>
//           <p className="claim-tag">Claim #112256325</p>
//           <p className="claim-description">
//             ICE spots in the policy wording that hernia treatment coverage requires that
//             the beneficiary be enrolled for 24 months.
//           </p>
//           <p className="claim-description">
//             Since only 17 months have elapsed, ICE flags the claim for denial and
//             highlights the exact sections of the claim and policy wording that are in
//             conflict.
//           </p>
//         </div>
//         <div className="before-ice-section">
//           <div className="claim-details">
//             <img src={with_ice} alt="Claim Details" className="claim-image" />
//           </div>
//         </div>

//         {/* #2 Before ICE */}
//         <div className="before-ice">
//           <h2>#2 Before ICE</h2>
//           <p className="claim-tag">Claim #112256325</p>
//           <p className="claim-description">
//             A claim with investigations for a patient hospitalized with acute
//             diverticulitis.
//           </p>
//         </div>
//         <div className="before-ice-section">
//           <div className="claim-details">
//             <img src={before_ice2} alt="Claim Details" className="claim-image" />
//           </div>
//         </div>

//         {/* #2 With ICE */}
//         <div className="before-ice">
//           <h2>#2 With ICE</h2>
//           <p className="claim-tag">Claim #112256325</p>
//           <p className="claim-description">
//             ICE identifies that a different claim submitted from the same provider that has
//             the exact report values, and flags these claims for review for potential fraud.
//           </p>
//         </div>
//         <div className="before-ice-section">
//           <div className="claim-details">
//             <img src={with_ice2} alt="Claim Details" className="claim-image" />
//           </div>
//         </div>

//         {/* #3 Before ICE */}
//         <div className="before-ice">
//           <h2>#3 Before ICE</h2>
//           <p className="claim-tag">Claim #112256325</p>
//           <p className="claim-description">
//             An in patient bill including room charges for a patient hospitalized due to
//             tuberculosis.
//           </p>
//         </div>
//         <div className="before-ice-section">
//           <div className="claim-details">
//             <img src={before_ice3} alt="Claim Details" className="claim-image" />
//           </div>
//         </div>

//         {/* #3 With ICE */}
//         <div className="before-ice">
//           <h2>#3 With ICE</h2>
//           <p className="claim-tag">Claim #112256325</p>
//           <p className="claim-description">
//             ICE identifies that a different claim submitted from the same provider that has
//             the exact report values, and flags these claims for review for potential fraud.
//           </p>
//         </div>
//         <div className="before-ice-section">
//           <div className="claim-details">
//             <img src={with_ice3} alt="Claim Details" className="claim-image" />
//           </div>
//         </div>

//         <h2 className="rapid-title">Rapid. Accurate. Efficient.</h2>
//         <div className="rapid-details">
//           <img src={with_ice3} alt="rapid-details" className="rapid-image" />
//         </div>

//         {/* 
//           ========== POST-RAPID PRESENTATION ==========
//           This pinned container does the discrete steps:
//             PHASE 0: small box
//             PHASE 1: box full
//             PHASE 2: text #1
//             PHASE 3: text #2
//             PHASE 4: text #3
//         */}
//         <PostRapidPresentation />
//       </main>

//       {/* Some extra space so user can scroll back up if needed */}
//       <div style={{ height: '20px' }} />
//     </div>
//   );
// };



// function PostRapidPresentation() {
//   const [phase, setPhase] = useState(0);
//   const [scrollProgress, setScrollProgress] = useState(0); // Track cumulative scroll progress
//   const [isInView, setIsInView] = useState(false);
//   const containerRef = useRef(null);
//   const boxRef = useRef(null);

//   const SCROLL_THRESHOLD = 1; // Number of scrolls needed to go to the next phase

//   // Monitor container visibility
//   useEffect(() => {
//     const handleScroll = () => {
//       const container = containerRef.current;
//       if (!container) return;

//       const rect = container.getBoundingClientRect();
//       const isContainerInView = rect.top < window.innerHeight && rect.bottom > 0;
//       setIsInView(isContainerInView);
//     };

//     window.addEventListener("scroll", handleScroll);
//     handleScroll(); // Initial check
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   useEffect(() => {
//     const handleMouseMove = (e) => {
//       const container = containerRef.current;
//       const box = boxRef.current;

//       if (!container || !box) return;

//       const containerRect = container.getBoundingClientRect();
//       const boxRect = box.getBoundingClientRect();

//       const isAlignedVertically =
//         e.clientY >= containerRect.top && e.clientY <= containerRect.bottom;
//       const isAlignedHorizontally =
//         e.clientX >= boxRect.left && e.clientX <= boxRect.right;

//       if (isAlignedVertically || isAlignedHorizontally) {
//         setIsInView(true);
//       }
//     };

//     window.addEventListener("mousemove", handleMouseMove);
//     return () => window.removeEventListener("mousemove", handleMouseMove);
//   }, []);

//   // Handle scroll event to increment phase only after threshold
//   useEffect(() => {
//     if (!isInView) return;

//     const onWheel = (e) => {
//       const delta = e.deltaY;

//       if (delta > 0) {
//         // Increment scroll progress when scrolling down
//         setScrollProgress((prev) => prev + 1);
//         if (scrollProgress + 1 >= SCROLL_THRESHOLD) {
//           setPhase((prev) => Math.min(prev + 1, 6)); // Go to the next phase
//           setScrollProgress(0); // Reset scroll progress after advancing
//         }
//       } else if (delta < 0) {
//         // Decrement scroll progress when scrolling up
//         setScrollProgress((prev) => prev - 1);
//         if (scrollProgress - 1 <= -SCROLL_THRESHOLD) {
//           setPhase((prev) => Math.max(prev - 1, 0)); // Go to the previous phase
//           setScrollProgress(0); // Reset scroll progress after decrementing
//         }
//       }

//       e.preventDefault();
//     };

//     const container = containerRef.current;
//     if (container) {
//       container.addEventListener("wheel", onWheel, { passive: false });
//       return () => container.removeEventListener("wheel", onWheel);
//     }
//   }, [isInView, scrollProgress]);

//   const isFullScreen = phase >= 1;

//   const getContent = () => {
//     switch (phase) {
//       case 2:
//         return (
//           <div className="text-container">
//             <div className="intelligence-row">
//               <span className="text-bold">Human</span>
//               <img src={human} alt="Human" className="intelligence-icon" />
//               <span className="text-bold">Intelligence</span>
//             </div>
//             <div className="intelligence-row">
//               <span className="text-bold">Robot</span>
//               <img src={robot} alt="Robot" className="intelligence-icon" />
//               <span className="text-bold">Intelligence</span>
//             </div>
//           </div>
//         );
//       case 3:
//         return <span className="excellence-text">= Excellence</span>;
//       case 4: // New phase
//         return (
//           <div className="new-text-container">
//             <p className="new-text">
//               real-time answers to questions during claim  
//             </p>
//             <p className="new-text">processing</p>
//             <hr className="divider" />
//           </div>
//         );
//       case 5: // New phase
//         return (
//           <div className="new-text-container">
//             <p className="new-text">Did the patient need to be hospitalized?</p>
//             <hr className="case-5-divider" />
//           </div>
//         );
//       case 6: // Display an image in the center
//         return (
//           <div className="image-container">
//             <img
//               src={chat} // Replace with the actual path
//               alt="Centered Image"
//               className="centered-image"
//             />
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div
//       ref={containerRef}
//       className={`post-rapid-container ${isFullScreen ? "full-screen" : ""}`}
//     >
//       <div
//         ref={boxRef}
//         className={`post-rapid-box ${isFullScreen ? "full-screen-box" : "small-box"}`}
//       >
//         {getContent() && (
//           <div className="box-text-step">
//             {getContent()}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default LandingPage;



// // testing

import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { ChevronRight } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import './LandingPage.css';

/** Example: your existing imports **/
import UploadedImage from '../icons/img_screenshot_nonpayables.png';
import Icon1 from '../icons/img_full_cover_icon.png';
import Icon2 from '../icons/img_target_recover.png';
import Icon3 from '../icons/img_reduce_operation_png.png';
import before_ice from '../icons/before_ice.png';
import with_ice from '../icons/Dashboard-5-O1.png';
import before_ice2 from '../icons/2before_ice.png';
import with_ice2 from '../icons/Dashboard-5-O2.png';
import before_ice3 from '../icons/before_ice3.png';
import with_ice3 from '../icons/Dashboard-5-O3.png';
import robot from "../icons/30.png";
import human from "../icons/29.png";
import chat from '../icons/chat-2.png';
import logo from '../icons/logonew.svg';
import expandIcon from "../icons/expand.png";
import closeIcon from "../icons/close.png";
import claim from '../icons/claim_details.png';
import bot_video from '../icons/recording.mp4';
/** A small helper to clamp a value within [min, max]. */
function clamp(value, min, max) {
  return Math.max(min, Math.min(value, max));
}

const LandingPage = () => {
  // === 1) Intersection Observers for text + cards
  const [textRef, textInView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [cardsRef, cardsInView] = useInView({ triggerOnce: true });

  const [visibleLetters, setVisibleLetters] = useState([]);
  const [cardsVisible, setCardsVisible] = useState(false);
  const navigate = useNavigate();
  
  // The text to appear letter by letter
  const text =
    'Revolutionize your claim handling with ICE, where speed meets precision. ' +
    'Our AI-driven platform processes claims in under 10 minutes, topping ' +
    'industry TAT numbers, eliminating human errors, and cutting operational costs.';

  // Define the text to highlight
  const highlightText = '10 minutes';
  const highlightStart = text.indexOf(highlightText);
  const highlightEnd = highlightStart + highlightText.length;

  useEffect(() => {
    if (textInView) {
      text.split('').forEach((_, index) => {
        setTimeout(() => {
          setVisibleLetters((prev) => [...prev, index]);
        }, index * 10);
      });
    }
  }, [textInView, text]);


  useEffect(() => {
    if (textInView) {
      text.split('').forEach((_, index) => {
        setTimeout(() => {
          setVisibleLetters((prev) => [...prev, index]);
        }, index * 10);
      });
    }
  }, [textInView, text]);

  useEffect(() => {
    if (cardsInView) {
      setCardsVisible(true);
    }
  }, [cardsInView]);

  return (
    <div className="landing-container">
      {/* ========== Navigation ========== */}
      <nav className="nav-container">
        <div className="logo-container">
          <img src={logo} alt="ICE Logo" className="logo-image" />
          <span className="logo-text">ICE</span>
        </div>
        <div className="nav-buttons">
          <button className="btn-request" onClick={() => navigate("/access")}>Request access</button>
          <button className="btn-sign-in" onClick={() => navigate("/login")}>Sign In</button>
        </div>
      </nav>

      {/* ========== Hero Section ========== */}
      <main className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">
            The <span className="highlight">AI-Powered</span> Platform for
            <br />
            Insurance Claim Processing
          </h1>
          <p className="hero-subtitle">
            Slash your turn around time and process a claim in under
            <br />
            <span className='hero-subtitle-time'>10 minutes</span> with ICE's advanced claim processing engine.
          </p>
          <button className="cta-button" onClick={() => navigate("/access")}>
            Apply for Access
            <ChevronRight size={20} />
          </button>

          {/* Hero Image */}
          <div className="image-container">
            <img src={UploadedImage} alt="Uploaded visualization" className="uploaded-image" />
          </div>

          {/* Animated Text Section */}
          <div ref={textRef} className="animated-text-container">
            <p className="animated-text">
              {text.split('').map((letter, index) => (
                <span
                  key={index}
                  className={`${visibleLetters.includes(index) ? 'visible' : 'hidden'} ${
                    index >= highlightStart && index < highlightEnd ? 'highlight' : ''
                  }`}
                >
                  {letter}
                </span>
              ))}
            </p>
          </div>
        </div>
        {/* ========== Why ICE Section ========== */}
        <div ref={cardsRef} className={`cards-section ${cardsVisible ? 'visible' : ''}`}>
  <h2 className="why-ice-title">Why ICE</h2>
  <div className={`cards-container ${cardsVisible ? 'visible' : ''}`}>
    {/* Card 1 */}
    <div className="card card-1">
      <img src={Icon1} alt="Fast Processing" className="card-logo" />
      <h3>Shatter your Turnaround Time Targets</h3>
      <ul className="card-bullet-points">
        <li>Processes claims rapidly and accurately.</li>
        <li>Reduces claim processing time to under four minutes.</li>
        <li>Enhances workflows for quicker payouts.</li>
        <li>Improves customer satisfaction with fast resolutions.</li>
      </ul>
    </div>

    {/* Card 2 */}
    <div className="card card-2">
      <img src={Icon2} alt="Precision" className="card-logo" />
      <h3>Precision At Scale</h3>
      <ul className="card-bullet-points">
        <li>Uses sophisticated OCR and machine learning algorithms.</li>
        <li>Handles large claim volumes without accuracy loss.</li>
        <li>Minimizes errors through AI-driven processes.</li>
        <li>Supports decision-making with detailed insights.</li>
      </ul>
    </div>

    {/* Card 3 */}
    <div className="card card-3">
      <img src={Icon3} alt="Cost Reduction" className="card-logo" />
      <h3>Cost Reduction</h3>
      <ul className="card-bullet-points">
        <li>Automates bulk claim processing tasks.</li>
        <li>Reduces the need for extensive manual intervention.</li>
        <li>Optimizes resource allocation for strategic growth.</li>
        <li>Decreases operational costs effectively.</li>
      </ul>
    </div>
  </div>
</div>

        <ComparisonTabs />
        <h2 className="rapid-title">Rapid. Accurate. Efficient.</h2>
        <div className="rapid-details">
          <img src={claim} alt="rapid-details" className="rapid-image" />
        </div>
        
        <div className="new-text-container">
            <p className="new-text">
              real-time answers to questions during claim  
            </p>
            <p className="new-text">processing</p>
            {/* <hr className="divider" /> */}
          </div>
              
          {/* <div className="new-text-container">
            <p className="new-text">Did the patient need to be hospitalized?</p>
            <hr className="case-5-divider" />
          </div> */}

          <div className="image-container">
            {/* <img
              src={chat} // Replace with the actual path
              alt="Centered Image"
              className="centered-image"
            /> */}

            <div className="video-container">
            <video
              className="centered-video"
              src={bot_video} // Replace with the actual path to your video file
              alt="Claim Processing Video"
              autoPlay
              loop
              muted
              preload = 'none'
            />
          </div>
          </div>
        

          <div className="last-section">
  <h2 className="last-section-title">Step Into The Future of Claim Processing</h2>
  <p className="last-section-subtitle">
    Ready to Transform Your Claims Process? Get in Touch
  </p>
  <p className="last-section-subtitle-1">
    for Early Access.
  </p>
  <button className="cta-button" onClick={() => navigate("/access")}>
    Apply for Access
    <ChevronRight size={20} />
  </button>
</div>

    {/* <PostRapidPresentation /> */}
      </main>
    </div>
  );
};

const ComparisonTabs = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [zoomStates, setZoomStates] = useState({
    left: { isZooming: false, coords: { x: 0, y: 0 } },
    right: { isZooming: false, coords: { x: 0, y: 0 } },
  });
  const [expandedImage, setExpandedImage] = useState(null);

  // Data for each tab
  const data = [
    {
      id: 1,
      title: "Claim #1",
      description: "A claim in April 2024 for laparoscopic hernia repair surgery.",
      issues: [
        "Patient enrolled for 17 months (required: 24 months)",
        "Policy conflict identified: Claim denial flagged",
      ],
      solutions: [
        "Flagged the policy wording requirement of 24 months.",
        "Highlighted the exact section of the policy for clarity.",
        "Provided actionable insights for immediate decision-making.",
      ],
      imageBefore: before_ice,
      imageAfter: with_ice,
    },
    {
      id: 2,
      title: "Claim #2",
      description: "Investigation for acute diverticulitis.",
      issues: [
        "Duplicate claim submitted with the same report values.",
        "Suspicious activity identified for potential fraud.",
      ],
      solutions: [
        "Matched duplicate claims automatically.",
        "Flagged the claim for further review.",
        "Streamlined fraud detection processes.",
      ],
      imageBefore: before_ice2,
      imageAfter: with_ice2,
    },
    {
      id: 3,
      title: "Claim #3",
      description:
        "Inpatient bill for a patient hospitalized due to tuberculosis.",
      issues: [
        "Duplicate claim from the same provider identified.",
        "Possible fraudulent activity flagged for review.",
      ],
      solutions: [
        "Highlighted identical claims submitted by the same provider.",
        "Flagged the suspicious claim for immediate review.",
        "Reduced manual intervention with AI-powered fraud detection.",
      ],
      imageBefore: before_ice3,
      imageAfter: with_ice3,
    },
  ];

  // Handles mouse movement over an image for magnify
  const handleMouseMove = (e, container, side) => {
    if (zoomStates[side].isZooming) {
      const bounds = container.getBoundingClientRect();
      const x = ((e.clientX - bounds.left) / bounds.width) * 100;
      const y = ((e.clientY - bounds.top) / bounds.height) * 100;
      setZoomStates((prev) => ({
        ...prev,
        [side]: { ...prev[side], coords: { x, y } },
      }));
    }
  };

  // Starts magnify for a specific image
  const handleMouseEnter = (side) => {
    setTimeout(() => {
      setZoomStates((prev) => ({
        ...prev,
        [side]: { ...prev[side], isZooming: true },
      }));
    }, 2000); // 2-second delay
  };

  // Stops magnify for a specific image
  const handleMouseLeave = (side) => {
    setZoomStates((prev) => ({
      ...prev,
      [side]: { isZooming: false, coords: { x: 0, y: 0 } },
    }));
  };

  // Expands the image
  const handleExpandImage = (image) => {
    setExpandedImage(image);
  };

  // Closes the expanded image
  const handleCloseExpandedImage = () => {
    setExpandedImage(null);
  };

  return (
    <div className="comparison-section">
      {/* Tabs */}
      <div className="pill-toggle-container">
        {data.map((tab) => (
          <button
            key={tab.id}
            className={`pill-toggle-button ${
              activeTab === tab.id ? "active" : ""
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Divider */}
      {/* <div className="section-divider"></div> */}

      {/* Content for Active Tab */}
      <div className="comparison-content">
        {data
          .filter((tab) => tab.id === activeTab)
          .map((tab) => (
            <div key={tab.id} className="comparison-tab-content">
              {/* Description */}
              <div className="comparison-description">
                <h3>Description</h3>
                <p>{tab.description}</p>
              </div>

              <div className="comparison-images">
                {/* Before ICE */}
                <div className="image-section">
                  <h4>Before ICE</h4>
                  <div
                    className="image-container-comparison"
                    onMouseMove={(e) =>
                      handleMouseMove(e, e.currentTarget, "left")
                    }
                    onMouseEnter={() => handleMouseEnter("left")}
                    onMouseLeave={() => handleMouseLeave("left")}
                  >
                    <img
                      src={tab.imageBefore}
                      alt="Before ICE"
                      className={zoomStates.left.isZooming ? "zoom" : ""}
                      style={{
                        transformOrigin: `${zoomStates.left.coords.x}% ${zoomStates.left.coords.y}%`,
                      }}
                    />
                    <button
                      className="expand-button"
                      onClick={() => handleExpandImage(tab.imageBefore)}
                    >
                      <img src={expandIcon} alt="Expand" />
                    </button>
                  </div>
                </div>

                {/* With ICE */}
                <div className="image-section">
                  <h4>With ICE</h4>
                  <div
                    className="image-container-comparison"
                    onMouseMove={(e) =>
                      handleMouseMove(e, e.currentTarget, "right")
                    }
                    onMouseEnter={() => handleMouseEnter("right")}
                    onMouseLeave={() => handleMouseLeave("right")}
                  >
                    <img
                      src={tab.imageAfter}
                      alt="With ICE"
                      className={zoomStates.right.isZooming ? "zoom" : ""}
                      style={{
                        transformOrigin: `${zoomStates.right.coords.x}% ${zoomStates.right.coords.y}%`,
                      }}
                    />
                    <button
                      className="expand-button"
                      onClick={() => handleExpandImage(tab.imageAfter)}
                    >
                      <img src={expandIcon} alt="Expand" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Expanded Image Modal */}
              {expandedImage && (
                <div
                  className="expanded-image-overlay"
                  onClick={handleCloseExpandedImage}
                >
                  <img src={expandedImage} alt="Expanded View" />
                  <button
                    className="close-button"
                    onClick={handleCloseExpandedImage}
                  >
                    <img src={closeIcon} alt="Close" />
                  </button>
                </div>
              )}

              {/* Issues and Solutions Section */}
              <div className="issues-solutions-container">
                {/* Issues */}
                <div className="issues-section">
                  <h5>Issues</h5>
                  <ul>
                    {tab.issues.map((issue, index) => (
                      <li key={index}>
                        <span className="bullet-icon">⚠️</span> {issue}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Solutions */}
                <div className="solutions-section">
                  <h5>Solutions</h5>
                  <ul>
                    {tab.solutions.map((solution, index) => (
                      <li key={index}>
                        <span className="bullet-icon">✅</span> {solution}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

function PostRapidPresentation() {
  const [phase, setPhase] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0); // Track cumulative scroll progress
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef(null);
  const boxRef = useRef(null);

  const SCROLL_THRESHOLD = 1; // Number of scrolls needed to go to the next phase

  // Monitor container visibility
  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (!container) return;

      const rect = container.getBoundingClientRect();
      const isContainerInView = rect.top < window.innerHeight && rect.bottom > 0;
      setIsInView(isContainerInView);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const container = containerRef.current;
      const box = boxRef.current;

      if (!container || !box) return;

      const containerRect = container.getBoundingClientRect();
      const boxRect = box.getBoundingClientRect();

      const isAlignedVertically =
        e.clientY >= containerRect.top && e.clientY <= containerRect.bottom;
      const isAlignedHorizontally =
        e.clientX >= boxRect.left && e.clientX <= boxRect.right;

      if (isAlignedVertically || isAlignedHorizontally) {
        setIsInView(true);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  // Handle scroll event to increment phase only after threshold
  useEffect(() => {
    if (!isInView) return;

    const onWheel = (e) => {
      const delta = e.deltaY;

      if (delta > 0) {
        // Increment scroll progress when scrolling down
        setScrollProgress((prev) => prev + 1);
        if (scrollProgress + 1 >= SCROLL_THRESHOLD) {
          setPhase((prev) => Math.min(prev + 1, 6)); // Go to the next phase
          setScrollProgress(0); // Reset scroll progress after advancing
        }
      } else if (delta < 0) {
        // Decrement scroll progress when scrolling up
        setScrollProgress((prev) => prev - 1);
        if (scrollProgress - 1 <= -SCROLL_THRESHOLD) {
          setPhase((prev) => Math.max(prev - 1, 0)); // Go to the previous phase
          setScrollProgress(0); // Reset scroll progress after decrementing
        }
      }

      e.preventDefault();
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("wheel", onWheel, { passive: false });
      return () => container.removeEventListener("wheel", onWheel);
    }
  }, [isInView, scrollProgress]);

  const isFullScreen = phase >= 1;

  const getContent = () => {
    switch (phase) {
      case 2:
        return (
          <div className="text-container">
            <div className="intelligence-row">
              <span className="text-bold">Human</span>
              <img src={human} alt="Human" className="intelligence-icon" />
              <span className="text-bold">Intelligence</span>
            </div>
            <div className="intelligence-row">
              <span className="text-bold">Robot</span>
              <img src={robot} alt="Robot" className="intelligence-icon" />
              <span className="text-bold">Intelligence</span>
            </div>
          </div>
        );
      case 3:
        return <span className="excellence-text">= Excellence</span>;
      case 4: // New phase
        return (
          <div className="new-text-container">
            <p className="new-text">
              real-time answers to questions during claim  
            </p>
            <p className="new-text">processing</p>
            <hr className="divider" />
          </div>
        );
      case 5: // New phase
        return (
          <div className="new-text-container">
            <p className="new-text">Did the patient need to be hospitalized?</p>
            <hr className="case-5-divider" />
          </div>
        );
      case 6: // Display an image in the center
        return (
          <div className="image-container">
            <img
              src={chat} // Replace with the actual path
              alt="Centered Image"
              className="centered-image"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      ref={containerRef}
      className={`post-rapid-container ${isFullScreen ? "full-screen" : ""}`}
    >
      <div
        ref={boxRef}
        className={`post-rapid-box ${isFullScreen ? "full-screen-box" : "small-box"}`}
      >
        {getContent() && (
          <div className="box-text-step">
            {getContent()}
          </div>
        )}
      </div>
    </div>
  );
}

export default LandingPage;
