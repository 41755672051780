// // Testing to add the data in the backend
// import React, { useEffect, useState, useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
// import { API_ENDPOINTS } from '../config';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [claimPdfData, setClaimPdfData] = useState(null);
//   const [settlementPdfData, setSettlementPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
//   const [actionToConfirm, setActionToConfirm] = useState(null);
//   const [actionHistory, setActionHistory] = useState([]);
//   const [isDocumentType, setIsDocumentType] = useState(true);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [pdfError, setPdfError] = useState(null);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(0);
//   const [iceSettlementPdfData, setIceSettlementPdfData] = useState(null);
//   const [discrepancyData, setDiscrepancyData] = useState(null);
//   const [billedAmountInput, setBilledAmountInput] = useState('');
//   const [exactRateInput, setExactRateInput] = useState('');


//   // Define the missing state variables
//   const [totalPolicyWordingDocumentAmount, setTotalPolicyWordingDocumentAmount] = useState(0);
//   const [totalNonpayablesAmount, setTotalNonpayablesAmount] = useState(0);
//   const [totalTariffDiscrepancyAmount, setTotalTariffDiscrepancyAmount] = useState(0);

//   // State variables for cropping functionality
//   const [isCropping, setIsCropping] = useState(false);
//   const [cropStart, setCropStart] = useState(null);
//   const [cropRect, setCropRect] = useState(null);
//   const [isCroppingMode, setIsCroppingMode] = useState(false);

//   // Use useRef for currentPageNumber and pageRefs
//   const currentPageNumberRef = useRef(null);
//   const pageRefs = useRef({});
//   const pageWrapperRefs = useRef({}); // Refs to the page containers

//   // State variables for the floating box after cropping
//   const [isFloatingBoxVisible, setIsFloatingBoxVisible] = useState(false);
//   const [retrievedText, setRetrievedText] = useState('');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [discrepancyAmountInput, setDiscrepancyAmountInput] = useState('');
//   const [reasonInput, setReasonInput] = useState('');

//   useEffect(() => {
//     console.log('Starting useEffect, docId:', docId);

//     const fetchResponseText = async () => {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
//       try {
//         console.log('Fetching response text from:', url);
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           console.log('Received response text:', data);

//           if (data.response_text && data.response_text[docId]) {
//             setResponseText(data.response_text[docId]);
//           } else {
//             console.warn(`No response text found for docId: ${docId}`);
//             setResponseText('No response text available.');
//           }
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch response text:', errorText);
//           setError('Failed to fetch response text');
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//         setError('Error fetching response text');
//       }
//     };

//     const fetchDocuments = async () => {
//       try {
//         if (docId === 'discrepancy_summary') {
//           console.log('Fetching discrepancy summary documents...');
//           const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?cb=${new Date().getTime()}`;
//           const iceSettlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/ice_settlement?cb=${new Date().getTime()}`;

//           const [settlementPdfResponse, iceSettlementPdfResponse] = await Promise.all([
//             fetch(settlementPdfUrl),
//             fetch(iceSettlementPdfUrl),
//           ]);

//           if (settlementPdfResponse.ok && iceSettlementPdfResponse.ok) {
//             const settlementPdfBlob = await settlementPdfResponse.blob();
//             const iceSettlementPdfBlob = await iceSettlementPdfResponse.blob();

//             setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//             setIceSettlementPdfData(URL.createObjectURL(iceSettlementPdfBlob));
//           } else {
//             throw new Error('Failed to fetch one or both PDFs for discrepancy summary');
//           }
//         } else if (docId === 'nonpayables') {
//           console.log('Fetching nonpayables documents...');
//           const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/get_documents`, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           });

//           if (response.ok) {
//             const data = await response.json();
//             console.log('Received nonpayables document data:', data);

//             const baseUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/`;
//             // const claimPdfUrl = `${baseUrl}${data.claim_pdf.path.replace(/\\/g, '/')}`;
//             const claimPdfUrl = `${baseUrl}${data.nonpayables_claim.path.replace(/\\/g, '/')}`;
//             const nonpayablesPdfUrl = `${baseUrl}${data.nonpayables_pdf.path.replace(/\\/g, '/')}`;

//             console.log('Constructed Claim PDF URL:', claimPdfUrl);
//             console.log('Constructed Nonpayables PDF URL:', nonpayablesPdfUrl);

//             const claimBlob = await fetch(claimPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
//             });
//             const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
//             });

//             const claimBlobUrl = URL.createObjectURL(claimBlob);
//             const nonpayablesBlobUrl = URL.createObjectURL(nonpayablesBlob);
            
//             console.log('Generated Claim PDF Blob URL:', claimBlobUrl);
//             console.log('Generated Nonpayables PDF Blob URL:', nonpayablesBlobUrl);

//             setClaimPdfData(claimBlobUrl);
//             setPdfData(nonpayablesBlobUrl);

//             // Fetch settlement PDF for nonpayables
//             const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?cb=${new Date().getTime()}`;
//             console.log('Fetching settlement PDF from:', settlementPdfUrl);
//             const settlementPdfResponse = await fetch(settlementPdfUrl);
//             if (settlementPdfResponse.ok) {
//               const settlementPdfBlob = await settlementPdfResponse.blob();
//               setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//               console.log('Successfully loaded settlement PDF for nonpayables');
//             } else {
//               console.warn('Settlement PDF not found or failed to load for nonpayables');
//               setSettlementPdfData(null);
//             }
//           } else {
//             const errorText = await response.text();
//             console.error('Failed to fetch documents:', errorText);
//             setError('Failed to fetch documents');
//           }
//         } else {
//           console.log('Fetching other document types...');
//           const fixedPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_fixed_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//           const otherPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_other_pdf?docId=${docId}&cb=${new Date().getTime()}`;
//           const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?cb=${new Date().getTime()}`;

//           console.log('Fetching fixed PDF from:', fixedPdfUrl);
//           const fixedPdfResponse = await fetch(fixedPdfUrl);
//           if (fixedPdfResponse.ok) {
//             const fixedPdfBlob = await fixedPdfResponse.blob();
//             setClaimPdfData(URL.createObjectURL(fixedPdfBlob));
//           } else {
//             throw new Error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
//           }

//           console.log('Fetching other PDF from:', otherPdfUrl);
//           const otherPdfResponse = await fetch(otherPdfUrl);
//           if (otherPdfResponse.ok) {
//             const otherPdfBlob = await otherPdfResponse.blob();
//             setPdfData(URL.createObjectURL(otherPdfBlob));
//           } else {
//             throw new Error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
//           }

//           console.log('Fetching settlement PDF from:', settlementPdfUrl);
//           const settlementPdfResponse = await fetch(settlementPdfUrl);
//           if (settlementPdfResponse.ok) {
//             const settlementPdfBlob = await settlementPdfResponse.blob();
//             setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//           } else {
//             console.warn('Settlement PDF not found or failed to load');
//             setSettlementPdfData(null);
//           }
//         }
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching documents:', error);
//         setError('Error fetching documents');
//         setLoading(false);
//       }
//     };

//     const fetchPolicyStatus = async () => {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setIceAuthorizedAmount(data.ice_authorized_amount); // Set ICE Authorized Amount from API response
//           setEstEligibleAmount(data.ice_authorized_amount); // Set initial Est Eligible Amount
//           setTotalPolicyWordingDocumentAmount(data.total_policy_wording_document_amount);
//           setTotalNonpayablesAmount(data.nonpayables.categories.total_nonpayable_amount);
//           setTotalTariffDiscrepancyAmount(data.tariff.total_tariff_discrepancy_amount);
//           setDiscrepancyData(data); // Store the entire discrepancy data
//           console.log('Policy status fetched:', data);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch policy status:', errorText);
//           setError('Failed to fetch policy status');
//         }
//       } catch (error) {
//         console.error('Error fetching policy status:', error);
//         setError('Error fetching policy status');
//       }
//     };

//     fetchResponseText();
//     fetchDocuments();
//     fetchPolicyStatus();
//   }, [docId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     console.log('Loaded left document with number of pages:', numPages);
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     console.log('Loaded right document with number of pages:', numPages);
//     setNumPagesRight(numPages);
//   }

//   function onDocumentLoadError(error) {
//     console.error('Error loading PDF document:', error);
//     setPdfError('Failed to load PDF file. Please check the file format or try again later.');
//   }

//   // Function to handle confirming discrepancies
//   const handleConfirmDiscrepancy = async () => {
//     if (actionToConfirm === 'overrule') {
//       if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//         const discrepancyAmount = discrepancyAmounts[currentDiscrepancyIndex] || 0;
//         const newIceAuthorizedAmount = iceAuthorizedAmount + discrepancyAmount;

//         let requestBody = {
//           ice_authorized_amount: newIceAuthorizedAmount,
//         };

//         // Calculate new totals before updating the state and request body
//         let newTotalPolicyWordingAmount;
//         let newTotalNonpayablesAmount;
//         let newTotalTariffDiscrepancyAmount;

//         if (docId === 'policy_wording_document') {
//           newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount - discrepancyAmount;
//           requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
//         } else if (docId === 'nonpayables') {
//           newTotalNonpayablesAmount = totalNonpayablesAmount - discrepancyAmount;
//           requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
//         } else if (docId === 'tariff_document') {
//           newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount - discrepancyAmount;
//           requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
//         }

//         try {
//           // Call the FastAPI endpoint to update the data
//           const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-data/`, {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(requestBody),
//           });

//           if (response.ok) {
//             const data = await response.json();
//             console.log(data.message);

//             // Update state only after successful response from the server
//             setIceAuthorizedAmount(newIceAuthorizedAmount);

//             if (docId === 'policy_wording_document') {
//               setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
//             } else if (docId === 'nonpayables') {
//               setTotalNonpayablesAmount(newTotalNonpayablesAmount);
//             } else if (docId === 'tariff_document') {
//               setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
//             }

//             setOverruledDiscrepancies((prevState) => ({
//               ...prevState,
//               [currentDiscrepancyIndex]: true,
//             }));

//             setActionHistory((prevHistory) => [
//               ...prevHistory,
//               { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmount },
//             ]);
//           } else {
//             console.error('Failed to update data:', await response.text());
//           }
//         } catch (error) {
//           console.error('Error updating data:', error);
//         }
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null);
//   };

//   // Function to handle overrule discrepancies
//   const handleOverruleDiscrepancy = () => {
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   // Function to handle discrepancy amount changes
//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
//     setActionHistory((prevHistory) => [
//       ...prevHistory,
//       { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 },
//     ]);
//     setDiscrepancyAmounts((prevState) => ({
//       ...prevState,
//       [discrepancyIndex]: newDiscrepancyAmount,
//     }));
//     setCurrentDiscrepancyIndex(discrepancyIndex);
//   };

//   // Function to revert the last action
//   const revertLastAction = async () => {
//     console.log('revertLastAction called');
//     if (actionHistory.length === 0) {
//       console.log('No actions to revert');
//       return;
//     }

//     const lastAction = actionHistory[actionHistory.length - 1];
//     console.log('Last action:', lastAction);

//     if (lastAction.action === 'overrule') {
//       const revertedAmount = iceAuthorizedAmount - lastAction.amount;
//       let requestBody = {
//         ice_authorized_amount: revertedAmount,
//       };

//       // Declare variables before updating state and request body
//       let newTotalPolicyWordingAmount;
//       let newTotalNonpayablesAmount;
//       let newTotalTariffDiscrepancyAmount;

//       // Revert the specific document amount based on docId
//       if (docId === 'policy_wording_document') {
//         newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount + lastAction.amount;
//         requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
//       } else if (docId === 'nonpayables') {
//         newTotalNonpayablesAmount = totalNonpayablesAmount + lastAction.amount;
//         requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
//       } else if (docId === 'tariff_document') {
//         newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount + lastAction.amount;
//         requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
//       }

//       try {
//         // Call the FastAPI endpoint to update the data on the server
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-data/`, {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(requestBody),
//         });

//         if (response.ok) {
//           const data = await response.json();
//           console.log(data.message);

//           // Update state after successful response from the server
//           setIceAuthorizedAmount(revertedAmount);

//           if (docId === 'policy_wording_document') {
//             setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
//           } else if (docId === 'nonpayables') {
//             setTotalNonpayablesAmount(newTotalNonpayablesAmount);
//           } else if (docId === 'tariff_document') {
//             setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
//           }

//           // Remove the overruled discrepancy from state
//           setOverruledDiscrepancies((prevState) => {
//             const newState = { ...prevState };
//             delete newState[lastAction.index];
//             return newState;
//           });
//         } else {
//           console.error('Failed to update data:', await response.text());
//         }
//       } catch (error) {
//         console.error('Error updating data:', error);
//       }
//     } else if (lastAction.action === 'update') {
//       const revertedAmount = lastAction.amount;
//       const discrepancyKey = docId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [discrepancyKey]: revertedAmount,
//           }),
//         });

//         if (response.ok) {
//           console.log('Discrepancy reverted successfully on the server.');
//           setDiscrepancyAmounts((prevState) => ({
//             ...prevState,
//             [lastAction.index]: revertedAmount,
//           }));
//           setCurrentDiscrepancyIndex(lastAction.index);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to revert discrepancy on the server:', errorText);
//         }
//       } catch (error) {
//         console.error('Error reverting discrepancy:', error);
//       }
//     }

//     // Remove the last action from the history
//     setActionHistory((prevHistory) => prevHistory.slice(0, -1));
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Tariff',
//     nonpayables: 'Non Payables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return (
//       nameMappings[str] ||
//       str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//         if (txt.toLowerCase() === 'stg') {
//           return 'STG';
//         }
//         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//       })
//     );
//   };

//   if (loading) {
//     console.log('Loading data...');
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     console.error('Error encountered:', error);
//     return <div>Error: {error}</div>;
//   }

//   if (docId === 'discrepancy_summary') {
//     if (!settlementPdfData || !iceSettlementPdfData) {
//       console.log(
//         'Missing data for discrepancy_summary - settlementPdfData:',
//         settlementPdfData,
//         'iceSettlementPdfData:',
//         iceSettlementPdfData
//       );
//       return <div>Loading...</div>;
//     }
//   } else if (!responseText || !pdfData || !claimPdfData || (isDocumentType && !settlementPdfData)) {
//     console.log(
//       'Missing data - responseText:',
//       responseText,
//       'pdfData:',
//       pdfData,
//       'claimPdfData:',
//       claimPdfData,
//       'settlementPdfData:',
//       settlementPdfData
//     );
//     return <div>Loading...</div>;
//   }

//   const handleMouseDown = (e, pageNumber) => {
//     if (!isCroppingMode) return;
//     setIsCropping(true);
//     currentPageNumberRef.current = pageNumber;
//     const rect = e.target.getBoundingClientRect();
//     setCropStart({ x: e.clientX - rect.left, y: e.clientY - rect.top });
//   };

//   const handleMouseMove = (e) => {
//     if (!isCropping || !isCroppingMode) return;
//     const rect = e.target.getBoundingClientRect();
//     const currentPoint = { x: e.clientX - rect.left, y: e.clientY - rect.top };
//     setCropRect({
//       left: Math.min(cropStart.x, currentPoint.x),
//       top: Math.min(cropStart.y, currentPoint.y),
//       width: Math.abs(currentPoint.x - cropStart.x),
//       height: Math.abs(currentPoint.y - cropStart.y),
//     });
//   };

//   const handleMouseUp = () => {
//     if (!isCroppingMode) return;
//     setIsCropping(false);
//     handleCrop();
//     setIsCroppingMode(false);
//     setCropRect(null);
//   };

//   const handleCrop = () => {
//     const canvas = pageRefs.current[currentPageNumberRef.current];
//     if (!canvas || !cropRect) {
//       console.error('Canvas or crop rectangle not available');
//       return;
//     }

//     // Calculate scaling factors
//     const scaleX = canvas.width / canvas.clientWidth;
//     const scaleY = canvas.height / canvas.clientHeight;

//     const cropX = cropRect.left * scaleX;
//     const cropY = cropRect.top * scaleY;
//     const cropWidth = cropRect.width * scaleX;
//     const cropHeight = cropRect.height * scaleY;

//     const croppedCanvas = document.createElement('canvas');
//     croppedCanvas.width = cropWidth;
//     croppedCanvas.height = cropHeight;
//     const ctx = croppedCanvas.getContext('2d');

//     ctx.drawImage(
//       canvas,
//       cropX,
//       cropY,
//       cropWidth,
//       cropHeight,
//       0,
//       0,
//       cropWidth,
//       cropHeight
//     );

//     const croppedImageData = croppedCanvas.toDataURL('image/png');
//     sendCroppedImageToBackend(croppedImageData);
//   };

//   const sendCroppedImageToBackend = (imageData) => {
//     fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/save-image`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ imageData }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log('Image saved:', data.filename);
//         setRetrievedText(data.generated_text);
//         setIsFloatingBoxVisible(true);
//       })
//       .catch((error) => {
//         console.error('Error saving image:', error);
//       });
//   };

//   const onPageRenderSuccess = (pageNumber) => {
//     const pageWrapperRef = pageWrapperRefs.current[pageNumber];
//     if (pageWrapperRef && pageWrapperRef.current) {
//       const canvas = pageWrapperRef.current.querySelector('canvas');
//       if (canvas) {
//         pageRefs.current[pageNumber] = canvas;
//       } else {
//         console.error(`Canvas not found for page ${pageNumber}`);
//       }
//     } else {
//       console.error(`Page wrapper ref not found for page ${pageNumber}`);
//     }
//   };

//   const toggleCroppingMode = () => {
//     setIsCroppingMode((prev) => !prev);
//     setCropRect(null);
//   };

//   // const handleFloatingBoxSubmit = () => {
//   //   // Handle submission logic here
//   //   console.log('Submitted data:', {
//   //     retrievedText,
//   //     selectedCategory,
//   //     discrepancyAmountInput,
//   //     reasonInput,
//   //   });

//   //   // Reset the floating box state
//   //   setIsFloatingBoxVisible(false);
//   //   setRetrievedText('');
//   //   setSelectedCategory('');
//   //   setDiscrepancyAmountInput('');
//   //   setReasonInput('');

//   //   // Optionally, update the state or send data to the backend
//   // };

//   const handleFloatingBoxSubmit = async () => {
//     if (!discrepancyData) {
//       console.error('Discrepancy data not available');
//       return;
//     }
  
//     let updatedData = { ...discrepancyData };
  
//     if (selectedCategory === 'nonpayables') {
//       if (!updatedData.nonpayables.discrepancy_count) {
//         updatedData.nonpayables.discrepancy_count = 0;
//       }


//       updatedData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.push({
//         item_name: retrievedText,
//         value: parseFloat(discrepancyAmountInput),
//         reason: reasonInput
//       });
//       // updatedData.nonpayables.categories.total_nonpayable_amount += parseFloat(discrepancyAmountInput);
//       // // updatedData.ice_authorised_amount = updatedData.ice_authorised_amount - parseFloat(discrepancyAmountInput)

//       // console.log("Updated ICE Authorized Amount:",updatedData.ice_authorised_amount)
//       updatedData.nonpayables.discrepancy_count += 1;
//     } else if (selectedCategory === 'tariff') {

//       if (!updatedData.tariff.discrepancy_count) {
//         updatedData.tariff.discrepancy_count = 0;
//       }
//       updatedData.tariff.necessary_items.push({
//         item_name: retrievedText,
//         billed_amount: parseFloat(billedAmountInput), // Using billed amount
//         tariff_amount: parseFloat(exactRateInput), // Using exact rate
//         in_tariff: false,
//         amount_compliant: false,
//         justification: reasonInput,
//         price: parseFloat(exactRateInput),
//         price_range: {
//           min: parseFloat(exactRateInput),
//           max: parseFloat(exactRateInput)
//         }
//       });
//       // updatedData.tariff.total_tariff_discrepancy_amount = parseFloat(billedAmountInput)- parseFloat(exactRateInput);
//       // // Subtract the calculated discrepancy from the ice_authorized_amount
//       // let discrepancy = parseFloat(billedAmountInput) - parseFloat(exactRateInput);

//       // updatedData.ice_authorized_amount -= discrepancy;  // Correct logic to update ice_authorized_amount

//       // console.log("Updated ICE Authorized Amount:", updatedData.ice_authorized_amount);

//       // Increment the discrepancy count
//       updatedData.tariff.discrepancy_count += 1;
//     }
  
//     try {
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/add_discrepancy`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedData),
//       });
  
//       if (response.ok) {
//         console.log('Discrepancy data updated successfully');
//         setDiscrepancyData(updatedData);
//         setTotalNonpayablesAmount(updatedData.nonpayables.categories.total_nonpayable_amount);
//         setTotalTariffDiscrepancyAmount(updatedData.tariff.total_tariff_discrepancy_amount);
//       } else {
//         console.error('Failed to update discrepancy data');
//       }
//     } catch (error) {
//       console.error('Error updating discrepancy data:', error);
//     }
  
//     // Reset the floating box state
//     setIsFloatingBoxVisible(false);
//     setRetrievedText('');
//     setSelectedCategory('');
//     setBilledAmountInput(''); // Reset billed amount
//     setExactRateInput(''); // Reset exact rate
//     setReasonInput('');
//   };
  




//   const handleFloatingBoxCancel = () => {
//     // Reset the floating box state
//     setIsFloatingBoxVisible(false);
//     setRetrievedText('');
//     setSelectedCategory('');
//     setDiscrepancyAmountInput('');
//     setReasonInput('');
//   };

//   const titleCaseDocId = toTitleCase(docId);

//   console.log('Rendering page with titleCaseDocId:', titleCaseDocId, 'responseText:', responseText);

//   return (
//     <div className="audit-details-page">
//       <Header
//         docId={docId}
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0}
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange}
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyIndex={currentDiscrepancyIndex}
//         iceAuthorizedAmount={iceAuthorizedAmount}
//       />
  
//       <div className="button-group">
//         <div className="revert-button-container">
//           <button onClick={revertLastAction} className="revert-button">
//             Revert Last Action
//           </button>
//         </div>
//         <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//           Overrule Deductions
//         </button>
//         <div className="confirm-discrepancy-btn">
//           <button onClick={handleConfirmDiscrepancy}>Confirm Deductions</button>
//           {isConfirmBoxVisible && (
//             <div className="floating-confirm-box">
//               <p>Are you sure you want to confirm this discrepancy?</p>
//             </div>
//           )}
//         </div>
//       </div>
  
//       <div className="pdf-comparison">
//         <div className="left-container pdf-container">
//           <h3>Claim Document</h3>
//           <div className="pdf-viewer">
//             <Document
//               file={claimPdfData}
//               onLoadSuccess={onDocumentLoadSuccessLeft}
//               onLoadError={onDocumentLoadError}
//             >
//               {Array.from(new Array(numPagesLeft), (el, index) => {
//                 const pageNumber = index + 1;
//                 if (!pageWrapperRefs.current[pageNumber]) {
//                   pageWrapperRefs.current[pageNumber] = React.createRef();
//                 }
//                 const pageWrapperRef = pageWrapperRefs.current[pageNumber];
  
//                 return (
//                   <div
//                     key={`fixed_page_container_${pageNumber}`}
//                     style={{ position: 'relative' }}
//                     ref={pageWrapperRef}
//                   >
//                     <Page
//                       key={`fixed_page_${pageNumber}`}
//                       pageNumber={pageNumber}
//                       onRenderSuccess={() => {
//                         onPageRenderSuccess(pageNumber);
//                       }}
//                     />
//                     {isCroppingMode && (
//                       <div
//                         className="crop-overlay"
//                         style={{
//                           position: 'absolute',
//                           top: 0,
//                           left: 0,
//                           width: '100%',
//                           height: '100%',
//                           cursor: 'crosshair',
//                           zIndex: 10,
//                         }}
//                         onMouseDown={(e) => handleMouseDown(e, pageNumber)}
//                         onMouseMove={handleMouseMove}
//                         onMouseUp={handleMouseUp}
//                       >
//                         {cropRect && currentPageNumberRef.current === pageNumber && (
//                           <div
//                             style={{
//                               position: 'absolute',
//                               border: '2px dashed red',
//                               left: cropRect.left,
//                               top: cropRect.top,
//                               width: cropRect.width,
//                               height: cropRect.height,
//                               pointerEvents: 'none',
//                             }}
//                           ></div>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 );
//               })}
//             </Document>
//           </div>
//         </div>
  
//         {/* The button is now positioned between the two containers */}
//         <button onClick={toggleCroppingMode} className="plus-button between">
//           {isCroppingMode ? '+' : '+'}
//         </button>
  
//         <div className="right-container pdf-container">
//           <h3>{titleCaseDocId}</h3>
//           <div className="pdf-viewer">
//             <Document
//               file={pdfData}
//               onLoadSuccess={onDocumentLoadSuccessRight}
//               onLoadError={onDocumentLoadError}
//             >
//               {Array.from(new Array(numPagesRight), (el, index) => (
//                 <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//               ))}
//             </Document>
//           </div>
//         </div>
//       </div>
  
//       {isFloatingBoxVisible && (
//         <div className="floating-box">
//           <h3>Extracted Text</h3>
//           {/* Make the extracted text editable */}
//           <textarea
//             value={retrievedText}
//             onChange={(e) => setRetrievedText(e.target.value)} // Allow editing the text
//             rows="2"
//             style={{ width: '100%' , font:"Manrope" }}
//           />
  
//           <label>Select Category:</label>
//           <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
//             <option value="">Select Category</option>
//             <option value="nonpayables">Non Payables</option>
//             <option value="tariff">Tariff</option>
//             <option value="policy_wording_document">Policy Wording Document</option>
//           </select>
  
//           {selectedCategory === 'tariff' ? (
//             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//               <div style={{ flex: 1, marginRight: '10px' }}>
//                 <label>Billed Amount:</label>
//                 <input
//                   type="number"
//                   value={billedAmountInput}
//                   onChange={(e) => setBilledAmountInput(e.target.value)}
//                   style={{ width: '100%' }}
//                 />
//               </div>

//               <div style={{ flex: 1 }}>
//                 <label>Exact Rate:</label>
//                 <input
//                   type="number"
//                   value={exactRateInput}
//                   onChange={(e) => setExactRateInput(e.target.value)}
//                   style={{ width: '100%'}}
//                 />
//               </div>
//             </div>
//           ) : (
//             <>
//               <label>Discrepancy Amount:</label>
//               <input
//                 type="number"
//                 value={discrepancyAmountInput}
//                 onChange={(e) => setDiscrepancyAmountInput(e.target.value)}
//               />
//             </>
//           )}


  
//           <label>Reason:</label>
//           <textarea value={reasonInput} onChange={(e) => setReasonInput(e.target.value)} rows="4" />
  
//           <button onClick={handleFloatingBoxSubmit}>Submit</button>
//           <button onClick={handleFloatingBoxCancel}>Cancel</button>
//         </div>
//       )}
//     </div>
//   );
  
// }

// export default AuditDetailsPage;






// with s3

// Testing to add the data in the backend
// import React, { useEffect, useState, useRef } from 'react';
// import { useParams, useLocation } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
// import InfoIcon from '../icons/information.svg';
// // import InfoIcon from '../icons/info_1562948.svg';
// import { API_ENDPOINTS } from '../config';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [claimPdfData, setClaimPdfData] = useState(null);
//   const [settlementPdfData, setSettlementPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
//   // const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
//   const [currentDiscrepancyKey, setCurrentDiscrepancyKey] = useState('');


//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
//   const [actionToConfirm, setActionToConfirm] = useState(null);
//   const [actionHistory, setActionHistory] = useState([]);
//   const [isDocumentType, setIsDocumentType] = useState(true);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [pdfError, setPdfError] = useState(null);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(0);
//   const [iceSettlementPdfData, setIceSettlementPdfData] = useState(null);
//   const [discrepancyData, setDiscrepancyData] = useState(null);
//   const [billedAmountInput, setBilledAmountInput] = useState('');
//   const [exactRateInput, setExactRateInput] = useState('');


//   // Define the missing state variables
//   const [totalPolicyWordingDocumentAmount, setTotalPolicyWordingDocumentAmount] = useState(0);
//   const [totalNonpayablesAmount, setTotalNonpayablesAmount] = useState(0);
//   const [totalTariffDiscrepancyAmount, setTotalTariffDiscrepancyAmount] = useState(0);

//   // State variables for cropping functionality
//   const [isCropping, setIsCropping] = useState(false);
//   const [cropStart, setCropStart] = useState(null);
//   const [cropRect, setCropRect] = useState(null);
//   const [isCroppingMode, setIsCroppingMode] = useState(false);

//   // Use useRef for currentPageNumber and pageRefs
//   const currentPageNumberRef = useRef(null);
//   const pageRefs = useRef({});
//   const pageWrapperRefs = useRef({}); // Refs to the page containers

//   // State variables for the floating box after cropping
//   const [isFloatingBoxVisible, setIsFloatingBoxVisible] = useState(false);
//   const [retrievedText, setRetrievedText] = useState('');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [discrepancyAmountInput, setDiscrepancyAmountInput] = useState('');
//   const [reasonInput, setReasonInput] = useState('');


//   // Retrieve patientId and claimId from location.state or sessionStorage
//   const location = useLocation();
//   // const passedPatientId = location.state?.patientId || sessionStorage.getItem("patientId");
//   // const passedClaimId = location.state?.claimId || sessionStorage.getItem("claimId");
//   const passedPatientId = "ICE-ACCCA7FA"
//   const passedClaimId = "CLAIM-5C09593C"

// useEffect(() => {
//   const fetchResponseText = async () => {
//     // Ensure that passedPatientId and passedClaimId are defined
//     if (!passedPatientId || !passedClaimId) {
//       console.error("Missing patientId or claimId");
//       setError("Missing patientId or claimId");
//       return;
//     }

//     // Skip fetching response text if docId is "nonpayables"
//     if (docId === "nonpayables") {
//       console.log("Skipping response text fetch for docId: nonpayables");
//       setResponseText("Response text not applicable for nonpayables.");
//       return;
//     }

//     // Update the URL to include patientId and claimId as query parameters
//     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
    
//     try {
//       console.log('Fetching response text from:', url);
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Received response text:', data);

//         // Check if the response contains the response_text for the specified docId
//         if (data.response_text && data.response_text[docId]) {
//           setResponseText(data.response_text[docId]);
//         } else {
//           console.warn(`No response text found for docId: ${docId}`);
//           setResponseText('No response text available.');
//         }
//       } else {
//         const errorText = await response.text();
//         console.error('Failed to fetch response text:', errorText);
//         setError('Failed to fetch response text');
//       }
//     } catch (error) {
//       console.error('Error fetching response text:', error);
//       setError('Error fetching response text');
//     }
//   };

//     // const fetchDocuments = async () => {
//     //   try {
//     //     if (docId === 'discrepancy_summary') {
//     //       console.log('Fetching discrepancy summary documents...');

//     //       // Ensure that passedPatientId and passedClaimId are defined
//     //       if (!passedPatientId || !passedClaimId) {
//     //         console.error("Missing patientId or claimId");
//     //         setError("Missing patientId or claimId");
//     //         return;
//     //       }

//     //   // Update the URL to include patientId and claimId as query parameters
//     //       // Add passedPatientId and passedClaimId as query parameters in the URLs
//     //     const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//     //     // const iceSettlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/ice_settlement?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;

//     //       // const [settlementPdfResponse, iceSettlementPdfResponse] = await Promise.all([
//     //       //   fetch(settlementPdfUrl),
//     //       //   fetch(iceSettlementPdfUrl),
//     //       // ]);
//     //       const [settlementPdfResponse] = await Promise.all([
//     //         fetch(settlementPdfUrl),
//     //         // fetch(iceSettlementPdfUrl),
//     //       ]);

//     //       // if (settlementPdfResponse.ok && iceSettlementPdfResponse.ok) {
//     //       if (settlementPdfResponse.ok ) {
//     //         const settlementPdfBlob = await settlementPdfResponse.blob();
//     //         // const iceSettlementPdfBlob = await iceSettlementPdfResponse.blob();

//     //         setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//     //         // setIceSettlementPdfData(URL.createObjectURL(iceSettlementPdfBlob));
//     //       } else {
//     //         throw new Error('Failed to fetch one or both PDFs for discrepancy summary');
//     //       }
//     //     } else if (docId === 'nonpayables') {
//     //       console.log('Fetching nonpayables documents...');
//     //       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/get_documents?patient_id=${passedPatientId}&claim_id=${passedClaimId}`, {
//     //     method: 'GET',
//     //     headers: { 'Content-Type': 'application/json' },
//     //   });

//     //       if (response.ok) {
//     //         const data = await response.json();
//     //         console.log('Received nonpayables document data:', data);

//     //         const baseUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/`;
//     //         // const claimPdfUrl = `${baseUrl}${data.claim_pdf.path.replace(/\\/g, '/')}`;
//     //         const claimPdfUrl = `${baseUrl}${data.nonpayables_claim.path.replace(/\\/g, '/')}`;
//     //         const nonpayablesPdfUrl = `${baseUrl}${data.nonpayables_pdf.path.replace(/\\/g, '/')}`;

//     //         console.log('Constructed Claim PDF URL:', claimPdfUrl);
//     //         console.log('Constructed Nonpayables PDF URL:', nonpayablesPdfUrl);

//     //         const claimBlob = await fetch(claimPdfUrl).then((res) => {
//     //           if (res.ok) return res.blob();
//     //           throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
//     //         });
//     //         const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
//     //           if (res.ok) return res.blob();
//     //           throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
//     //         });

//     //         const claimBlobUrl = URL.createObjectURL(claimBlob);
//     //         const nonpayablesBlobUrl = URL.createObjectURL(nonpayablesBlob);
            
//     //         console.log('Generated Claim PDF Blob URL:', claimBlobUrl);
//     //         console.log('Generated Nonpayables PDF Blob URL:', nonpayablesBlobUrl);

//     //         setClaimPdfData(claimBlobUrl);
//     //         setPdfData(nonpayablesBlobUrl);

//     //         // Fetch settlement PDF for nonpayables
//     //         const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//     //         console.log('Fetching settlement PDF from:', settlementPdfUrl);
//     //         const settlementPdfResponse = await fetch(settlementPdfUrl);
//     //         if (settlementPdfResponse.ok) {
//     //           const settlementPdfBlob = await settlementPdfResponse.blob();
//     //           setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//     //           console.log('Successfully loaded settlement PDF for nonpayables');
//     //         } else {
//     //           console.warn('Settlement PDF not found or failed to load for nonpayables');
//     //           setSettlementPdfData(null);
//     //         }
//     //       } else {
//     //         const errorText = await response.text();
//     //         console.error('Failed to fetch documents:', errorText);
//     //         setError('Failed to fetch documents');
//     //       }
//     //     } else {
//     //       const fixedPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_fixed_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//     //       const otherPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_other_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//     //       const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
      
//     //       try {
//     //           // Fetch fixed PDF
//     //           console.log("Fetching fixed PDF from:", fixedPdfUrl);
//     //           const fixedPdfResponse = await fetch(fixedPdfUrl);
//     //           if (fixedPdfResponse.ok) {
//     //               const fixedPdfData = await fixedPdfResponse.json(); // Assuming the backend returns a JSON response
//     //               const fixedPdfPresignedUrl = fixedPdfData.url; // Extract presigned URL from response
      
//     //               console.log("Fetching fixed PDF from S3 using presigned URL:", fixedPdfPresignedUrl);
//     //               const fixedPdfBlob = await fetch(fixedPdfPresignedUrl).then((res) => {
//     //                   if (res.ok) return res.blob();
//     //                   throw new Error(`Failed to fetch fixed PDF from S3: ${res.statusText}`);
//     //               });
      
//     //               setClaimPdfData(URL.createObjectURL(fixedPdfBlob));
//     //           } else {
//     //               console.error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
//     //           }
      
//     //           // Fetch other PDF
//     //           console.log("Fetching other PDF from:", otherPdfUrl);
//     //           const otherPdfResponse = await fetch(otherPdfUrl);
//     //           if (otherPdfResponse.ok) {
//     //               const otherPdfData = await otherPdfResponse.json(); // Assuming the backend returns a JSON response
//     //               const otherPdfPresignedUrl = otherPdfData.url; // Extract presigned URL from response
      
//     //               console.log("Fetching other PDF from S3 using presigned URL:", otherPdfPresignedUrl);
//     //               const otherPdfBlob = await fetch(otherPdfPresignedUrl).then((res) => {
//     //                   if (res.ok) return res.blob();
//     //                   throw new Error(`Failed to fetch other PDF from S3: ${res.statusText}`);
//     //               });
      
//     //               setPdfData(URL.createObjectURL(otherPdfBlob));
//     //           } else {
//     //               console.error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
//     //           }
      
//     //           // Fetch settlement PDF
//     //           console.log("Fetching settlement PDF from:", settlementPdfUrl);
//     //           const settlementPdfResponse = await fetch(settlementPdfUrl);
//     //           if (settlementPdfResponse.ok) {
//     //               const settlementPdfData = await settlementPdfResponse.json(); // Assuming the backend returns a JSON response
//     //               const settlementPdfPresignedUrl = settlementPdfData.url; // Extract presigned URL from response
      
//     //               console.log("Fetching settlement PDF from S3 using presigned URL:", settlementPdfPresignedUrl);
//     //               const settlementPdfBlob = await fetch(settlementPdfPresignedUrl).then((res) => {
//     //                   if (res.ok) return res.blob();
//     //                   throw new Error(`Failed to fetch settlement PDF from S3: ${res.statusText}`);
//     //               });
      
//     //               setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//     //           } else {
//     //               console.warn("Settlement PDF not found or failed to load");
//     //               setSettlementPdfData(null);
//     //           }
//     //       } catch (error) {
//     //           console.error("Error fetching fixed/other/settlement PDFs:", error);
//     //       } finally {
//     //           setLoading(false);
//     //       }
//     //   };

//     const fetchDocuments = async () => {
//       try {
//         console.log("Fetching documents...");
    
//         if (docId === "discrepancy_summary") {
//           console.log("Fetching discrepancy summary documents...");
    
//           if (!passedPatientId || !passedClaimId) {
//             console.error("Missing patientId or claimId");
//             setError("Missing patientId or claimId");
//             return;
//           }
    
//           // Fetch settlement PDF for discrepancy summary
//           const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//           const settlementPdfResponse = await fetch(settlementPdfUrl);
    
//           if (settlementPdfResponse.ok) {
//             const settlementPdfData = await settlementPdfResponse.json();
//             const settlementPdfPresignedUrl = settlementPdfData.url;
    
//             const settlementPdfBlob = await fetch(settlementPdfPresignedUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch settlement PDF from S3: ${res.statusText}`);
//             });
    
//             setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//           } else {
//             throw new Error("Failed to fetch settlement PDF for discrepancy summary");
//           }
//         } else if (docId === "nonpayables") {
//           console.log("Fetching nonpayables documents...");
    
//           // Fetch nonpayables documents
//           const response = await fetch(
//             `${API_ENDPOINTS.PYTHON_SERVICE}/get_documents?patient_id=${passedPatientId}&claim_id=${passedClaimId}`,
//             {
//               method: "GET",
//               headers: { "Content-Type": "application/json" },
//             }
//           );
    
//           if (response.ok) {
//             const data = await response.json();
//             console.log("Received nonpayables document data:", data);
    
//             // Extract URLs for claim_document and nonpayables
//             const claimPdfUrl = data?.nonpayables_claim?.url;
//             const nonpayablesPdfUrl = data?.nonpayables?.url;
    
//             if (!claimPdfUrl || !nonpayablesPdfUrl) {
//               throw new Error(
//                 "Missing required document URLs in the response. Check the API response."
//               );
//             }
    
//             // Fetch Claim Document PDF
//             const claimBlob = await fetch(claimPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
//             });
    
//             // Fetch Nonpayables Document PDF
//             const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
//             });
    
//             setClaimPdfData(URL.createObjectURL(claimBlob)); // For claim_document
//             setPdfData(URL.createObjectURL(nonpayablesBlob)); // For nonpayables
//           } else {
//             const errorText = await response.text();
//             console.error("Failed to fetch documents:", errorText);
//             setError("Failed to fetch documents");
//           }
//         } else {
//           console.log("Fetching general documents...");
    
//           // Fetch fixed PDF and other PDF
//           const fixedPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_fixed_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//           const otherPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_other_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//           const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
    
//           // Fetch Fixed PDF
//           const fixedPdfResponse = await fetch(fixedPdfUrl);
//           if (fixedPdfResponse.ok) {
//             const fixedPdfData = await fixedPdfResponse.json();
//             const fixedPdfPresignedUrl = fixedPdfData.url;
    
//             const fixedPdfBlob = await fetch(fixedPdfPresignedUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch fixed PDF from S3: ${res.statusText}`);
//             });
    
//             setClaimPdfData(URL.createObjectURL(fixedPdfBlob));
//           } else {
//             throw new Error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
//           }
    
//           // Fetch Other PDF
//           const otherPdfResponse = await fetch(otherPdfUrl);
//           if (otherPdfResponse.ok) {
//             const otherPdfData = await otherPdfResponse.json();
//             const otherPdfPresignedUrl = otherPdfData.url;
    
//             const otherPdfBlob = await fetch(otherPdfPresignedUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch other PDF from S3: ${res.statusText}`);
//             });
    
//             setPdfData(URL.createObjectURL(otherPdfBlob));
//           } else {
//             throw new Error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
//           }
    
//           // Fetch Settlement PDF
//           const settlementPdfResponse = await fetch(settlementPdfUrl);
//           if (settlementPdfResponse.ok) {
//             const settlementPdfData = await settlementPdfResponse.json();
//             const settlementPdfPresignedUrl = settlementPdfData.url;
    
//             const settlementPdfBlob = await fetch(settlementPdfPresignedUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch settlement PDF from S3: ${res.statusText}`);
//             });
    
//             setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//           } else {
//             console.warn("Settlement PDF not found or failed to load");
//             setSettlementPdfData(null);
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching documents:", error);
//         setError("Error fetching documents");
//       } finally {
//         setLoading(false);
//       }
//     };
    
  
//     const fetchPolicyStatus = async () => {
//        // Include patient_id and claim_id as query parameters in the URL
//   const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setIceAuthorizedAmount(data.ice_authorized_amount); // Set ICE Authorized Amount from API response
//           setEstEligibleAmount(data.ice_authorized_amount); // Set initial Est Eligible Amount
//           setTotalPolicyWordingDocumentAmount(data.total_policy_wording_document_amount);
//           setTotalNonpayablesAmount(data.nonpayables.categories.total_nonpayable_amount);
//           setTotalTariffDiscrepancyAmount(data.tariff.total_tariff_discrepancy_amount);
//           setDiscrepancyData(data); // Store the entire discrepancy data
//           console.log('Policy status fetched:', data);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch policy status:', errorText);
//           setError('Failed to fetch policy status');
//         }
//       } catch (error) {
//         console.error('Error fetching policy status:', error);
//         setError('Error fetching policy status');
//       }
//     };

//     fetchResponseText();
//     fetchDocuments();
//     fetchPolicyStatus();
//   }, [docId, passedPatientId, passedClaimId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     console.log('Loaded left document with number of pages:', numPages);
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     console.log('Loaded right document with number of pages:', numPages);
//     setNumPagesRight(numPages);
//   }

//   function onDocumentLoadError(error) {
//     console.error('Error loading PDF document:', error);
//     setPdfError('Failed to load PDF file. Please check the file format or try again later.');
//   }

//   // Function to handle confirming discrepancies
//   const handleConfirmDiscrepancy = async () => {
//     if (actionToConfirm === 'overrule') {
//       if (!overruledDiscrepancies[currentDiscrepancyKey]) {
//         const discrepancyAmount = discrepancyAmounts[currentDiscrepancyKey] || 0;
//         const newIceAuthorizedAmount = iceAuthorizedAmount + discrepancyAmount;
  
//         let requestBody = {
//           ice_authorized_amount: newIceAuthorizedAmount,
//         };

//         // Calculate new totals before updating the state and request body
//         let newTotalPolicyWordingAmount;
//         let newTotalNonpayablesAmount;
//         let newTotalTariffDiscrepancyAmount;

//         // Ensure that passedPatientId and passedClaimId are defined
//         if (!passedPatientId || !passedClaimId) {
//           console.error("Missing patientId or claimId");
//           setError("Missing patientId or claimId");
//           return;
//         }
//         if (docId === 'policy_wording_document') {
//           newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount - discrepancyAmount;
//           requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
//         } else if (docId === 'nonpayables') {
//           newTotalNonpayablesAmount = totalNonpayablesAmount - discrepancyAmount;
//           requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
//         } else if (docId === 'tariff_document') {
//           newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount - discrepancyAmount;
//           requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
//         }

//         const url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//         try {
//            // Update the URL to include patientId and claimId as query parameters
//           // Call the FastAPI endpoint to update the data
//           const response = await fetch(url, {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(requestBody),
//           });

//           if (response.ok) {
//             const data = await response.json();
//             console.log(data.message);

//             // Update state only after successful response from the server
//             setIceAuthorizedAmount(newIceAuthorizedAmount);

//             if (docId === 'policy_wording_document') {
//               setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
//             } else if (docId === 'nonpayables') {
//               setTotalNonpayablesAmount(newTotalNonpayablesAmount);
//             } else if (docId === 'tariff_document') {
//               setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
//             }

//             setOverruledDiscrepancies((prevState) => ({
//               ...prevState,
//               [currentDiscrepancyKey]: true,
//             }));
      
//             setActionHistory((prevHistory) => [
//               ...prevHistory,
//               { action: 'overrule', key: currentDiscrepancyKey, amount: discrepancyAmount },
//             ]);
//           } else {
//             console.error('Failed to update data:', await response.text());
//           }
//         } catch (error) {
//           console.error('Error updating data:', error);
//         }
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null);
//   };

//   // Function to handle overrule discrepancies
//   const handleOverruleDiscrepancy = () => {
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   // Function to handle discrepancy amount changes
//   // const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//   //   console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
//   //   setActionHistory((prevHistory) => [
//   //     ...prevHistory,
//   //     { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 },
//   //   ]);
//   //   setDiscrepancyAmounts((prevState) => ({
//   //     ...prevState,
//   //     [discrepancyIndex]: newDiscrepancyAmount,
//   //   }));
//   //   setCurrentDiscrepancyIndex(discrepancyIndex);
//   // };

//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyKey) => {
//     console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyKey);
//     setActionHistory((prevHistory) => [
//       ...prevHistory,
//       { action: 'update', key: discrepancyKey, amount: discrepancyAmounts[discrepancyKey] || 0 },
//     ]);
//     setDiscrepancyAmounts((prevState) => ({
//       ...prevState,
//       [discrepancyKey]: newDiscrepancyAmount,
//     }));
//     setCurrentDiscrepancyKey(discrepancyKey);
//   };
  
  
//   // Function to revert the last action
//   const revertLastAction = async () => {
//     console.log('revertLastAction called');
//     if (actionHistory.length === 0) {
//       console.log('No actions to revert');
//       return;
//     }

//     const lastAction = actionHistory[actionHistory.length - 1];
//     console.log('Last action:', lastAction);

//     if (lastAction.action === 'overrule') {
//       const revertedAmount = iceAuthorizedAmount - lastAction.amount;
//       let requestBody = {
//         ice_authorized_amount: revertedAmount,
//       };

//       // Declare variables before updating state and request body
//       let newTotalPolicyWordingAmount;
//       let newTotalNonpayablesAmount;
//       let newTotalTariffDiscrepancyAmount;

//       // Revert the specific document amount based on docId
//       if (docId === 'policy_wording_document') {
//         newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount + lastAction.amount;
//         requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
//       } else if (docId === 'nonpayables') {
//         newTotalNonpayablesAmount = totalNonpayablesAmount + lastAction.amount;
//         requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
//       } else if (docId === 'tariff_document') {
//         newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount + lastAction.amount;
//         requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
//       }
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//       try {
//         // Call the FastAPI endpoint to update the data on the server
//         const response = await fetch(url, {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(requestBody),
//         });

//         if (response.ok) {
//           const data = await response.json();
//           console.log(data.message);

//           // Update state after successful response from the server
//           setIceAuthorizedAmount(revertedAmount);

//           if (docId === 'policy_wording_document') {
//             setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
//           } else if (docId === 'nonpayables') {
//             setTotalNonpayablesAmount(newTotalNonpayablesAmount);
//           } else if (docId === 'tariff_document') {
//             setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
//           }

//           // Remove the overruled discrepancy from state
//           setOverruledDiscrepancies((prevState) => {
//             const newState = { ...prevState };
//             delete newState[lastAction.index];
//             return newState;
//           });
//         } else {
//           console.error('Failed to update data:', await response.text());
//         }
//       } catch (error) {
//         console.error('Error updating data:', error);
//       }
//     } else if (lastAction.action === 'update') {
//       const revertedAmount = lastAction.amount;
//       const discrepancyKey = lastAction.key;
//       // const discrepancyKey = docId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

//       const update_discrepancy_url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//     try {
//       const response = await fetch(update_discrepancy_url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           [discrepancyKey]: revertedAmount,
//         }),
//       });

//       if (response.ok) {
//         setDiscrepancyAmounts((prevState) => ({
//           ...prevState,
//           [discrepancyKey]: revertedAmount,
//         }));
//         setCurrentDiscrepancyKey(discrepancyKey);
//       } else {
//           const errorText = await response.text();
//           console.error('Failed to revert discrepancy on the server:', errorText);
//         }
//       } catch (error) {
//         console.error('Error reverting discrepancy:', error);
//       }
//     }

//     // Remove the last action from the history
//     setActionHistory((prevHistory) => prevHistory.slice(0, -1));
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Tariff',
//     nonpayables: 'Non Payables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return (
//       nameMappings[str] ||
//       str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//         if (txt.toLowerCase() === 'stg') {
//           return 'STG';
//         }
//         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//       })
//     );
//   };

//   if (loading) {
//     console.log('Loading data...');
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     console.error('Error encountered:', error);
//     return <div>Error: {error}</div>;
//   }

//   if (docId === 'discrepancy_summary') {
//     if (!settlementPdfData || !iceSettlementPdfData) {
//       console.log(
//         'Missing data for discrepancy_summary - settlementPdfData:',
//         settlementPdfData,
//         'iceSettlementPdfData:',
//         iceSettlementPdfData
//       );
//       return <div>Loading...</div>;
//     }
//   // } else if (!responseText || !pdfData || !claimPdfData || (isDocumentType && !settlementPdfData)) {
//   //   console.log(
//   //     'Missing data - responseText:',
//   //     responseText,
//   //     'pdfData:',
//   //     pdfData,
//   //     'claimPdfData:',
//   //     claimPdfData,
//   //     'settlementPdfData:',
//   //     settlementPdfData
//   //   );
//   //   return <div>Loading...</div>;
//   // }
//   } else if (
//     !pdfData || 
//     !claimPdfData || 
//     (docId !== "nonpayables" && (!responseText || (isDocumentType && !settlementPdfData)))
//   ) {
//     console.log(
//       'Missing data - responseText:',
//       responseText,
//       'pdfData:',
//       pdfData,
//       'claimPdfData:',
//       claimPdfData,
//       'settlementPdfData:',
//       settlementPdfData
//     );
//     return <div>Loading...</div>;
//   }
  

//   const handleMouseDown = (e, pageNumber) => {
//     if (!isCroppingMode) return;
//     setIsCropping(true);
//     currentPageNumberRef.current = pageNumber;
//     const rect = e.target.getBoundingClientRect();
//     setCropStart({ x: e.clientX - rect.left, y: e.clientY - rect.top });
//   };

//   const handleMouseMove = (e) => {
//     if (!isCropping || !isCroppingMode) return;
//     const rect = e.target.getBoundingClientRect();
//     const currentPoint = { x: e.clientX - rect.left, y: e.clientY - rect.top };
//     setCropRect({
//       left: Math.min(cropStart.x, currentPoint.x),
//       top: Math.min(cropStart.y, currentPoint.y),
//       width: Math.abs(currentPoint.x - cropStart.x),
//       height: Math.abs(currentPoint.y - cropStart.y),
//     });
//   };

//   const handleMouseUp = () => {
//     if (!isCroppingMode) return;
//     setIsCropping(false);
//     handleCrop();
//     setIsCroppingMode(false);
//     setCropRect(null);
//   };

//   const handleCrop = () => {
//     const canvas = pageRefs.current[currentPageNumberRef.current];
//     if (!canvas || !cropRect) {
//       console.error('Canvas or crop rectangle not available');
//       return;
//     }

//     // Calculate scaling factors
//     const scaleX = canvas.width / canvas.clientWidth;
//     const scaleY = canvas.height / canvas.clientHeight;

//     const cropX = cropRect.left * scaleX;
//     const cropY = cropRect.top * scaleY;
//     const cropWidth = cropRect.width * scaleX;
//     const cropHeight = cropRect.height * scaleY;

//     const croppedCanvas = document.createElement('canvas');
//     croppedCanvas.width = cropWidth;
//     croppedCanvas.height = cropHeight;
//     const ctx = croppedCanvas.getContext('2d');

//     ctx.drawImage(
//       canvas,
//       cropX,
//       cropY,
//       cropWidth,
//       cropHeight,
//       0,
//       0,
//       cropWidth,
//       cropHeight
//     );

//     const croppedImageData = croppedCanvas.toDataURL('image/png');
//     sendCroppedImageToBackend(croppedImageData);
//   };

//   const sendCroppedImageToBackend = (imageData) => {
//     fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/save-image?patient_id=${passedPatientId}&claim_id=${passedClaimId}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ imageData }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log('Image saved:', data.filename);
//         setRetrievedText(data.generated_text);
//         setIsFloatingBoxVisible(true);
//       })
//       .catch((error) => {
//         console.error('Error saving image:', error);
//       });
//   };

//   const onPageRenderSuccess = (pageNumber) => {
//     const pageWrapperRef = pageWrapperRefs.current[pageNumber];
//     if (pageWrapperRef && pageWrapperRef.current) {
//       const canvas = pageWrapperRef.current.querySelector('canvas');
//       if (canvas) {
//         pageRefs.current[pageNumber] = canvas;
//       } else {
//         console.error(`Canvas not found for page ${pageNumber}`);
//       }
//     } else {
//       console.error(`Page wrapper ref not found for page ${pageNumber}`);
//     }
//   };

//   const toggleCroppingMode = () => {
//     setIsCroppingMode((prev) => !prev);
//     setCropRect(null);
//   };

//   // const handleFloatingBoxSubmit = () => {
//   //   // Handle submission logic here
//   //   console.log('Submitted data:', {
//   //     retrievedText,
//   //     selectedCategory,
//   //     discrepancyAmountInput,
//   //     reasonInput,
//   //   });

//   //   // Reset the floating box state
//   //   setIsFloatingBoxVisible(false);
//   //   setRetrievedText('');
//   //   setSelectedCategory('');
//   //   setDiscrepancyAmountInput('');
//   //   setReasonInput('');

//   //   // Optionally, update the state or send data to the backend
//   // };

//   const handleFloatingBoxSubmit = async () => {
//     if (!discrepancyData) {
//       console.error('Discrepancy data not available');
//       return;
//     }
  
//     let updatedData = { ...discrepancyData };
  
//     if (selectedCategory === 'nonpayables') {
//       if (!updatedData.nonpayables.discrepancy_count) {
//         updatedData.nonpayables.discrepancy_count = 0;
//       }


//       updatedData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.push({
//         item_name: retrievedText,
//         value: parseFloat(discrepancyAmountInput),
//         reason: reasonInput
//       });
//       // updatedData.nonpayables.categories.total_nonpayable_amount += parseFloat(discrepancyAmountInput);
//       // // updatedData.ice_authorised_amount = updatedData.ice_authorised_amount - parseFloat(discrepancyAmountInput)

//       // console.log("Updated ICE Authorized Amount:",updatedData.ice_authorised_amount)
//       updatedData.nonpayables.discrepancy_count += 1;
//     } else if (selectedCategory === 'tariff') {

//       if (!updatedData.tariff.discrepancy_count) {
//         updatedData.tariff.discrepancy_count = 0;
//       }
//       updatedData.tariff.necessary_items.push({
//         item_name: retrievedText,
//         billed_amount: parseFloat(billedAmountInput), // Using billed amount
//         tariff_amount: parseFloat(exactRateInput), // Using exact rate
//         in_tariff: false,
//         amount_compliant: false,
//         justification: reasonInput,
//         price: parseFloat(exactRateInput),
//         price_range: {
//           min: parseFloat(exactRateInput),
//           max: parseFloat(exactRateInput)
//         }
//       });
//       // updatedData.tariff.total_tariff_discrepancy_amount = parseFloat(billedAmountInput)- parseFloat(exactRateInput);
//       // // Subtract the calculated discrepancy from the ice_authorized_amount
//       // let discrepancy = parseFloat(billedAmountInput) - parseFloat(exactRateInput);

//       // updatedData.ice_authorized_amount -= discrepancy;  // Correct logic to update ice_authorized_amount

//       // console.log("Updated ICE Authorized Amount:", updatedData.ice_authorized_amount);

//       // Increment the discrepancy count
//       updatedData.tariff.discrepancy_count += 1;
//     }
//     const add_discrepancy_url = `${API_ENDPOINTS.PYTHON_SERVICE}/add-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//     try {
//       const response = await fetch(add_discrepancy_url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedData),
//       });
  
//       if (response.ok) {
//         console.log('Discrepancy data updated successfully');
//         setDiscrepancyData(updatedData);
//         setTotalNonpayablesAmount(updatedData.nonpayables.categories.total_nonpayable_amount);
//         setTotalTariffDiscrepancyAmount(updatedData.tariff.total_tariff_discrepancy_amount);
//       } else {
//         console.error('Failed to update discrepancy data');
//       }
//     } catch (error) {
//       console.error('Error updating discrepancy data:', error);
//     }
  
//     // Reset the floating box state
//     setIsFloatingBoxVisible(false);
//     setRetrievedText('');
//     setSelectedCategory('');
//     setBilledAmountInput(''); // Reset billed amount
//     setExactRateInput(''); // Reset exact rate
//     setReasonInput('');
//   };
  




//   const handleFloatingBoxCancel = () => {
//     // Reset the floating box state
//     setIsFloatingBoxVisible(false);
//     setRetrievedText('');
//     setSelectedCategory('');
//     setDiscrepancyAmountInput('');
//     setReasonInput('');
//   };

//   const titleCaseDocId = toTitleCase(docId);

//   console.log('Rendering page with titleCaseDocId:', titleCaseDocId, 'responseText:', responseText);
//   console.log('The pdfdata stuff', pdfData)
//   return (
//     <div className="audit-details-page">
//       <Header
//         patientId={passedPatientId} // Pass patientId
//         claimId={passedClaimId}
//         docId={docId}
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyIndex] || 0}
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange}
//         overruledDiscrepancies={overruledDiscrepancies}
//         // currentDiscrepancyIndex={currentDiscrepancyIndex}
//         currentDiscrepancyKey={currentDiscrepancyKey}
//         iceAuthorizedAmount={iceAuthorizedAmount}
//       />
  
//   <div className="button-group">
//   <div className="revert-button-container" style={{ display: 'flex', alignItems: 'center' }}>
//     <button onClick={revertLastAction} className="revert-button">
//       Revert Last Action
//     </button>
//     <img
//       src={InfoIcon} // Replace with the correct path to your info icon
//       alt="Info Icon"
//       className="audit-info-icon"
//       title="Click to undo the last action you performed."
//       style={{ marginLeft: '8px', cursor: 'pointer' }} // Adjust spacing
//     />
//   </div>
//   <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//     Overrule Deductions
//   </button>
//   <div className="confirm-discrepancy-btn">
//     <button onClick={handleConfirmDiscrepancy}>Confirm Deductions</button>
//     {isConfirmBoxVisible && (
//       <div className="floating-confirm-box">
//         <p>Are you sure you want to confirm this discrepancy?</p>
//       </div>
//     )}
//   </div>
// </div>


  
//       <div className="pdf-comparison">
//         <div className="left-container pdf-container">
//           <h3>Claim Document</h3>
//           <div className="audit-details-pdf-viewer">
//             <Document
//               file={claimPdfData}
//               onLoadSuccess={onDocumentLoadSuccessLeft}
//               onLoadError={onDocumentLoadError}
//             >
//               {Array.from(new Array(numPagesLeft), (el, index) => {
//                 const pageNumber = index + 1;
//                 if (!pageWrapperRefs.current[pageNumber]) {
//                   pageWrapperRefs.current[pageNumber] = React.createRef();
//                 }
//                 const pageWrapperRef = pageWrapperRefs.current[pageNumber];
  
//                 return (
//                   <div
//                     key={`fixed_page_container_${pageNumber}`}
//                     style={{ position: 'relative' }}
//                     ref={pageWrapperRef}
//                   >
//                     <Page
//                       key={`fixed_page_${pageNumber}`}
//                       pageNumber={pageNumber}
//                       onRenderSuccess={() => {
//                         onPageRenderSuccess(pageNumber);
//                       }}
//                     />
//                     {isCroppingMode && (
//                       <div
//                         className="crop-overlay"
//                         style={{
//                           position: 'absolute',
//                           top: 0,
//                           left: 0,
//                           width: '100%',
//                           height: '100%',
//                           cursor: 'crosshair',
//                           zIndex: 10,
//                         }}
//                         onMouseDown={(e) => handleMouseDown(e, pageNumber)}
//                         onMouseMove={handleMouseMove}
//                         onMouseUp={handleMouseUp}
//                       >
//                         {cropRect && currentPageNumberRef.current === pageNumber && (
//                           <div
//                             style={{
//                               position: 'absolute',
//                               border: '2px dashed red',
//                               left: cropRect.left,
//                               top: cropRect.top,
//                               width: cropRect.width,
//                               height: cropRect.height,
//                               pointerEvents: 'none',
//                             }}
//                           ></div>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 );
//               })}
//             </Document>
//           </div>
//         </div>
  
//         {/* The button is now positioned between the two containers */}
//         <div className="plus-button-container">
//         <button onClick={toggleCroppingMode} className="plus-button between">
//           {isCroppingMode ? '+' : '+'}
//         </button>
//         <img
//           src={InfoIcon} // Replace with the correct path to your info icon
//           alt="Info Icon"
//           className="audit-info-icon-next-to-plus"
//           title="Click to toggle cropping mode for discrepancies."
//         />
//       </div>


        
//         <div className="right-container pdf-container">
//           <h3>{titleCaseDocId}</h3>
          
//           <div className="audit-details-pdf-viewer">
//             <Document
//               file={pdfData}
//               onLoadSuccess={onDocumentLoadSuccessRight}
//               onLoadError={onDocumentLoadError}
//             >
//               {Array.from(new Array(numPagesRight), (el, index) => (
//                 <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//               ))}
//             </Document>
//           </div>
//         </div>
//       </div>
  
//       {isFloatingBoxVisible && (
//         <div className="floating-box">
//           <h3>Extracted Text</h3>
//           {/* Make the extracted text editable */}
//           <textarea
//             value={retrievedText}
//             onChange={(e) => setRetrievedText(e.target.value)} // Allow editing the text
//             rows="2"
//             style={{ width: '100%' , font:"Manrope" }}
//           />
  
//           <label>Select Category:</label>
//           <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
//             <option value="">Select Category</option>
//             <option value="nonpayables">Non Payables</option>
//             <option value="tariff">Tariff</option>
//             <option value="policy_wording_document">Policy Wording Document</option>
//           </select>
  
//           {selectedCategory === 'tariff' ? (
//             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//               <div style={{ flex: 1, marginRight: '10px' }}>
//                 <label>Billed Amount:</label>
//                 <input
//                   type="number"
//                   value={billedAmountInput}
//                   onChange={(e) => setBilledAmountInput(e.target.value)}
//                   style={{ width: '100%' }}
//                 />
//               </div>

//               <div style={{ flex: 1 }}>
//                 <label>Exact Rate:</label>
//                 <input
//                   type="number"
//                   value={exactRateInput}
//                   onChange={(e) => setExactRateInput(e.target.value)}
//                   style={{ width: '100%'}}
//                 />
//               </div>
//             </div>
//           ) : (
//             <>
//               <label>Discrepancy Amount:</label>
//               <input
//                 type="number"
//                 value={discrepancyAmountInput}
//                 onChange={(e) => setDiscrepancyAmountInput(e.target.value)}
//               />
//             </>
//           )}


  
//           <label>Reason:</label>
//           <textarea value={reasonInput} onChange={(e) => setReasonInput(e.target.value)} rows="4" />
  
//           <button onClick={handleFloatingBoxSubmit}>Submit</button>
//           <button onClick={handleFloatingBoxCancel}>Cancel</button>
//         </div>
//       )}
//     </div>
//   );
  
// }

// export default AuditDetailsPage;






// AuditDetailsPage.js

// import React, { useEffect, useState, useRef } from 'react';
// import { useParams, useLocation } from 'react-router-dom';
// import Header from './Header';
// import './AuditDetailsPage.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
// import InfoIcon from '../icons/information.svg';
// import { API_ENDPOINTS } from '../config';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function AuditDetailsPage() {
//   const { docId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [claimPdfData, setClaimPdfData] = useState(null);
//   const [settlementPdfData, setSettlementPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
//   const [currentDiscrepancyKey, setCurrentDiscrepancyKey] = useState('');
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
//   const [actionToConfirm, setActionToConfirm] = useState(null);
//   const [actionHistory, setActionHistory] = useState([]);
//   const [isDocumentType, setIsDocumentType] = useState(true);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [pdfError, setPdfError] = useState(null);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(0);
//   const [iceSettlementPdfData, setIceSettlementPdfData] = useState(null);
//   const [discrepancyData, setDiscrepancyData] = useState(null);
//   const [billedAmountInput, setBilledAmountInput] = useState('');
//   const [exactRateInput, setExactRateInput] = useState('');

//   // Define the missing state variables
//   const [totalPolicyWordingDocumentAmount, setTotalPolicyWordingDocumentAmount] = useState(0);
//   const [totalNonpayablesAmount, setTotalNonpayablesAmount] = useState(0);
//   const [totalTariffDiscrepancyAmount, setTotalTariffDiscrepancyAmount] = useState(0);

//   // State variables for cropping functionality
//   const [isCropping, setIsCropping] = useState(false);
//   const [cropStart, setCropStart] = useState(null);
//   const [cropRect, setCropRect] = useState(null);
//   const [isCroppingMode, setIsCroppingMode] = useState(false);

//   // Use useRef for currentPageNumber and pageRefs
//   const currentPageNumberRef = useRef(null);
//   const pageRefs = useRef({});
//   const pageWrapperRefs = useRef({}); // Refs to the page containers

//   // State variables for the floating box after cropping
//   const [isFloatingBoxVisible, setIsFloatingBoxVisible] = useState(false);
//   const [retrievedText, setRetrievedText] = useState('');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [discrepancyAmountInput, setDiscrepancyAmountInput] = useState('');
//   const [reasonInput, setReasonInput] = useState('');

//   // Retrieve patientId and claimId from location.state or sessionStorage
//   const location = useLocation();
//   // const passedPatientId = location.state?.patientId || sessionStorage.getItem("patientId");
//   // const passedClaimId = location.state?.claimId || sessionStorage.getItem("claimId");
//   const passedPatientId = "ICE-ACCCA7FA";
//   const passedClaimId = "CLAIM-5C09593C";

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       // Ensure that passedPatientId and passedClaimId are defined
//       if (!passedPatientId || !passedClaimId) {
//         console.error("Missing patientId or claimId");
//         setError("Missing patientId or claimId");
//         return;
//       }

//       // Skip fetching response text if docId is "nonpayables"
//       if (docId === "nonpayables") {
//         console.log("Skipping response text fetch for docId: nonpayables");
//         setResponseText("Response text not applicable for nonpayables.");
//         return;
//       }

//       // Update the URL to include patientId and claimId as query parameters
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
      
//       try {
//         console.log('Fetching response text from:', url);
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           console.log('Received response text:', data);

//           // Check if the response contains the response_text for the specified docId
//           if (data.response_text && data.response_text[docId]) {
//             setResponseText(data.response_text[docId]);
//           } else {
//             console.warn(`No response text found for docId: ${docId}`);
//             setResponseText('No response text available.');
//           }
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch response text:', errorText);
//           setError('Failed to fetch response text');
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//         setError('Error fetching response text');
//       }
//     };

//     const fetchDocuments = async () => {
//       try {
//         console.log("Fetching documents...");

//         if (docId === "discrepancy_summary") {
//           console.log("Fetching discrepancy summary documents...");

//           if (!passedPatientId || !passedClaimId) {
//             console.error("Missing patientId or claimId");
//             setError("Missing patientId or claimId");
//             return;
//           }

//           // Fetch settlement PDF for discrepancy summary
//           const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//           const settlementPdfResponse = await fetch(settlementPdfUrl);

//           if (settlementPdfResponse.ok) {
//             const settlementPdfData = await settlementPdfResponse.json();
//             const settlementPdfPresignedUrl = settlementPdfData.url;

//             const settlementPdfBlob = await fetch(settlementPdfPresignedUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch settlement PDF from S3: ${res.statusText}`);
//             });

//             setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//           } else {
//             throw new Error("Failed to fetch settlement PDF for discrepancy summary");
//           }
//         } else if (docId === "nonpayables") {
//           console.log("Fetching nonpayables documents...");

//           // Fetch nonpayables documents
//           const response = await fetch(
//             `${API_ENDPOINTS.PYTHON_SERVICE}/get_documents?patient_id=${passedPatientId}&claim_id=${passedClaimId}`,
//             {
//               method: "GET",
//               headers: { "Content-Type": "application/json" },
//             }
//           );

//           if (response.ok) {
//             const data = await response.json();
//             console.log("Received nonpayables document data:", data);

//             // Extract URLs for claim_document and nonpayables
//             const claimPdfUrl = data?.nonpayables_claim?.url;
//             const nonpayablesPdfUrl = data?.nonpayables?.url;

//             if (!claimPdfUrl || !nonpayablesPdfUrl) {
//               throw new Error(
//                 "Missing required document URLs in the response. Check the API response."
//               );
//             }

//             // Fetch Claim Document PDF
//             const claimBlob = await fetch(claimPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
//             });

//             // Fetch Nonpayables Document PDF
//             const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
//             });

//             setClaimPdfData(URL.createObjectURL(claimBlob)); // For claim_document
//             setPdfData(URL.createObjectURL(nonpayablesBlob)); // For nonpayables
//           } else {
//             const errorText = await response.text();
//             console.error("Failed to fetch documents:", errorText);
//             setError("Failed to fetch documents");
//           }
//         } else {
//           console.log("Fetching general documents...");

//           // Fetch fixed PDF and other PDF
//           const fixedPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_fixed_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//           const otherPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_other_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
//           const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;

//           // Fetch Fixed PDF
//           const fixedPdfResponse = await fetch(fixedPdfUrl);
//           if (fixedPdfResponse.ok) {
//             const fixedPdfData = await fixedPdfResponse.json();
//             const fixedPdfPresignedUrl = fixedPdfData.url;

//             const fixedPdfBlob = await fetch(fixedPdfPresignedUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch fixed PDF from S3: ${res.statusText}`);
//             });

//             setClaimPdfData(URL.createObjectURL(fixedPdfBlob));
//           } else {
//             throw new Error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
//           }

//           // Fetch Other PDF
//           const otherPdfResponse = await fetch(otherPdfUrl);
//           if (otherPdfResponse.ok) {
//             const otherPdfData = await otherPdfResponse.json();
//             const otherPdfPresignedUrl = otherPdfData.url;

//             const otherPdfBlob = await fetch(otherPdfPresignedUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch other PDF from S3: ${res.statusText}`);
//             });

//             setPdfData(URL.createObjectURL(otherPdfBlob));
//           } else {
//             throw new Error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
//           }

//           // Fetch Settlement PDF
//           const settlementPdfResponse = await fetch(settlementPdfUrl);
//           if (settlementPdfResponse.ok) {
//             const settlementPdfData = await settlementPdfResponse.json();
//             const settlementPdfPresignedUrl = settlementPdfData.url;

//             const settlementPdfBlob = await fetch(settlementPdfPresignedUrl).then((res) => {
//               if (res.ok) return res.blob();
//               throw new Error(`Failed to fetch settlement PDF from S3: ${res.statusText}`);
//             });

//             setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
//           } else {
//             console.warn("Settlement PDF not found or failed to load");
//             setSettlementPdfData(null);
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching documents:", error);
//         setError("Error fetching documents");
//       } finally {
//         setLoading(false);
//       }
//     };

//     const fetchPolicyStatus = async () => {
//       // Include patient_id and claim_id as query parameters in the URL
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setIceAuthorizedAmount(data.ice_authorized_amount); // Set ICE Authorized Amount from API response
//           setEstEligibleAmount(data.ice_authorized_amount); // Set initial Est Eligible Amount
//           setTotalPolicyWordingDocumentAmount(data.total_policy_wording_document_amount);
//           setTotalNonpayablesAmount(data.nonpayables.categories.total_nonpayable_amount);
//           setTotalTariffDiscrepancyAmount(data.tariff.total_tariff_discrepancy_amount);
//           setDiscrepancyData(data); // Store the entire discrepancy data
//           console.log('Policy status fetched:', data);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch policy status:', errorText);
//           setError('Failed to fetch policy status');
//         }
//       } catch (error) {
//         console.error('Error fetching policy status:', error);
//         setError('Error fetching policy status');
//       }
//     };

//     fetchResponseText();
//     fetchDocuments();
//     fetchPolicyStatus();
//   }, [docId, passedPatientId, passedClaimId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     console.log('Loaded left document with number of pages:', numPages);
//     setNumPagesLeft(numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     console.log('Loaded right document with number of pages:', numPages);
//     setNumPagesRight(numPages);
//   }

//   function onDocumentLoadError(error) {
//     console.error('Error loading PDF document:', error);
//     setPdfError('Failed to load PDF file. Please check the file format or try again later.');
//   }

//   // Function to handle confirming discrepancies
//   const handleConfirmDiscrepancy = async () => {
//     if (actionToConfirm === 'overrule') {
//       if (!overruledDiscrepancies[currentDiscrepancyKey]) {
//         const discrepancyAmount = discrepancyAmounts[currentDiscrepancyKey] || 0;
//         const newIceAuthorizedAmount = iceAuthorizedAmount + discrepancyAmount;

//         let requestBody = {
//           ice_authorized_amount: newIceAuthorizedAmount,
//         };

//         // Calculate new totals before updating the state and request body
//         let newTotalPolicyWordingAmount;
//         let newTotalNonpayablesAmount;
//         let newTotalTariffDiscrepancyAmount;

//         // Ensure that passedPatientId and passedClaimId are defined
//         if (!passedPatientId || !passedClaimId) {
//           console.error("Missing patientId or claimId");
//           setError("Missing patientId or claimId");
//           return;
//         }
//         if (docId === 'policy_wording_document') {
//           newTotalPolicyWordingAmount = totalPolicyWordingDocumentAmount - discrepancyAmount;
//           requestBody.total_policy_wording_document_amount = newTotalPolicyWordingAmount;
//         } else if (docId === 'nonpayables') {
//           newTotalNonpayablesAmount = totalNonpayablesAmount - discrepancyAmount;
//           requestBody.total_nonpayables_amount = newTotalNonpayablesAmount;
//         } else if (docId === 'tariff_document') {
//           newTotalTariffDiscrepancyAmount = totalTariffDiscrepancyAmount - discrepancyAmount;
//           requestBody.total_tariff_discrepancy_amount = newTotalTariffDiscrepancyAmount;
//         }

//         const url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//         try {
//           // Call the FastAPI endpoint to update the data
//           const response = await fetch(url, {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(requestBody),
//           });

//           if (response.ok) {
//             const data = await response.json();
//             console.log(data.message);

//             // Update state only after successful response from the server
//             setIceAuthorizedAmount(newIceAuthorizedAmount);

//             if (docId === 'policy_wording_document') {
//               setTotalPolicyWordingDocumentAmount(newTotalPolicyWordingAmount);
//             } else if (docId === 'nonpayables') {
//               setTotalNonpayablesAmount(newTotalNonpayablesAmount);
//             } else if (docId === 'tariff_document') {
//               setTotalTariffDiscrepancyAmount(newTotalTariffDiscrepancyAmount);
//             }

//             setOverruledDiscrepancies((prevState) => ({
//               ...prevState,
//               [currentDiscrepancyKey]: true,
//             }));

//             setActionHistory((prevHistory) => [
//               ...prevHistory,
//               { action: 'overrule', key: currentDiscrepancyKey, amount: discrepancyAmount },
//             ]);
//           } else {
//             console.error('Failed to update data:', await response.text());
//           }
//         } catch (error) {
//           console.error('Error updating data:', error);
//         }
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null);
//   };

//   // Function to handle overrule discrepancies
//   const handleOverruleDiscrepancy = () => {
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   // Function to handle discrepancy amount changes
//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyKey, extraInfo = {}) => {
//     const { docId: actionDocId, category } = extraInfo;
  
//     setActionHistory((prevHistory) => [
//       ...prevHistory,
//       {
//         action: 'update',
//         key: discrepancyKey,
//         amount: discrepancyAmounts[discrepancyKey] || 0,
//         docId: actionDocId,
//         category: category,
//       },
//     ]);
//     setDiscrepancyAmounts((prevState) => ({
//       ...prevState,
//       [discrepancyKey]: newDiscrepancyAmount,
//     }));
//     setCurrentDiscrepancyKey(discrepancyKey);
//   };

//   // Function to revert the last action
//   const revertLastAction = async () => {
//     console.log('revertLastAction called');
//     if (actionHistory.length === 0) {
//       console.log('No actions to revert');
//       return;
//     }
  
//     const lastAction = actionHistory[actionHistory.length - 1];
//     console.log('Last action:', lastAction);
  
//     if (lastAction.action === 'overrule') {
//       // Existing logic for overrule revert...
//     } else if (lastAction.action === 'update') {
//       const revertedAmount = lastAction.amount;
//       const discrepancyKey = lastAction.key;
//       const lastDocId = lastAction.docId;
//       const category = lastAction.category;
  
//       const update_discrepancy_url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//       let payload = {};
  
//       if (lastDocId === 'stg_document') {
//         payload = { alignment_percentage: revertedAmount };
//       } else if (lastDocId === 'nonpayables') {
//         payload = {
//           items_non_payable_but_billed_in_the_bill: [
//             { item_name: discrepancyKey, value: revertedAmount },
//           ],
//         };
//       } else if (lastDocId === 'tariff_document') {
//         if (category === 'necessary_items' || category === 'non_compliant_items') {
//           payload = {
//             [category]: [{ item_name: discrepancyKey, price: revertedAmount }],
//           };
//         } else if (category === 'unnecessary_items') {
//           payload = {
//             [category]: [{ item_name: discrepancyKey, billed_amount: revertedAmount }],
//           };
//         } else {
//           console.error('Unknown category:', category);
//           return; // Exit the function if category is unknown
//         }
//       } else {
//         payload = { [discrepancyKey]: revertedAmount };
//       }
  
//       try {
//         const response = await fetch(update_discrepancy_url, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           // Use the constructed payload here
//           body: JSON.stringify(payload),
//         });
  
//         if (response.ok) {
//           console.log('Discrepancy reverted successfully on the server.');
//           setDiscrepancyAmounts((prevState) => ({
//             ...prevState,
//             [lastAction.key]: revertedAmount,
//           }));
//           setCurrentDiscrepancyKey(lastAction.key);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to revert discrepancy on the server:', errorText);
//         }
//       } catch (error) {
//         console.error('Error reverting discrepancy:', error);
//       }
//     }
  
//     // Remove the last action from the history
//     setActionHistory((prevHistory) => prevHistory.slice(0, -1));
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Tariff',
//     nonpayables: 'Non Payables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return (
//       nameMappings[str] ||
//       str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//         if (txt.toLowerCase() === 'stg') {
//           return 'STG';
//         }
//         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//       })
//     );
//   };

//   if (loading) {
//     console.log('Loading data...');
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     console.error('Error encountered:', error);
//     return <div>Error: {error}</div>;
//   }

//   if (docId === 'discrepancy_summary') {
//     if (!settlementPdfData || !iceSettlementPdfData) {
//       console.log(
//         'Missing data for discrepancy_summary - settlementPdfData:',
//         settlementPdfData,
//         'iceSettlementPdfData:',
//         iceSettlementPdfData
//       );
//       return <div>Loading...</div>;
//     }
//   } else if (
//     !pdfData || 
//     !claimPdfData || 
//     (docId !== "nonpayables" && (!responseText || (isDocumentType && !settlementPdfData)))
//   ) {
//     console.log(
//       'Missing data - responseText:',
//       responseText,
//       'pdfData:',
//       pdfData,
//       'claimPdfData:',
//       claimPdfData,
//       'settlementPdfData:',
//       settlementPdfData
//     );
//     return <div>Loading...</div>;
//   }

//   const handleMouseDown = (e, pageNumber) => {
//     if (!isCroppingMode) return;
//     setIsCropping(true);
//     currentPageNumberRef.current = pageNumber;
//     const rect = e.target.getBoundingClientRect();
//     setCropStart({ x: e.clientX - rect.left, y: e.clientY - rect.top });
//   };

//   const handleMouseMove = (e) => {
//     if (!isCropping || !isCroppingMode) return;
//     const rect = e.target.getBoundingClientRect();
//     const currentPoint = { x: e.clientX - rect.left, y: e.clientY - rect.top };
//     setCropRect({
//       left: Math.min(cropStart.x, currentPoint.x),
//       top: Math.min(cropStart.y, currentPoint.y),
//       width: Math.abs(currentPoint.x - cropStart.x),
//       height: Math.abs(currentPoint.y - cropStart.y),
//     });
//   };

//   const handleMouseUp = () => {
//     if (!isCroppingMode) return;
//     setIsCropping(false);
//     handleCrop();
//     setIsCroppingMode(false);
//     setCropRect(null);
//   };

//   const handleCrop = () => {
//     const canvas = pageRefs.current[currentPageNumberRef.current];
//     if (!canvas || !cropRect) {
//       console.error('Canvas or crop rectangle not available');
//       return;
//     }

//     // Calculate scaling factors
//     const scaleX = canvas.width / canvas.clientWidth;
//     const scaleY = canvas.height / canvas.clientHeight;

//     const cropX = cropRect.left * scaleX;
//     const cropY = cropRect.top * scaleY;
//     const cropWidth = cropRect.width * scaleX;
//     const cropHeight = cropRect.height * scaleY;

//     const croppedCanvas = document.createElement('canvas');
//     croppedCanvas.width = cropWidth;
//     croppedCanvas.height = cropHeight;
//     const ctx = croppedCanvas.getContext('2d');

//     ctx.drawImage(
//       canvas,
//       cropX,
//       cropY,
//       cropWidth,
//       cropHeight,
//       0,
//       0,
//       cropWidth,
//       cropHeight
//     );

//     const croppedImageData = croppedCanvas.toDataURL('image/png');
//     sendCroppedImageToBackend(croppedImageData);
//   };

//   const sendCroppedImageToBackend = (imageData) => {
//     fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/save-image?patient_id=${passedPatientId}&claim_id=${passedClaimId}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ imageData }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log('Image saved:', data.filename);
//         setRetrievedText(data.generated_text);
//         setIsFloatingBoxVisible(true);
//       })
//       .catch((error) => {
//         console.error('Error saving image:', error);
//       });
//   };

//   const onPageRenderSuccess = (pageNumber) => {
//     const pageWrapperRef = pageWrapperRefs.current[pageNumber];
//     if (pageWrapperRef && pageWrapperRef.current) {
//       const canvas = pageWrapperRef.current.querySelector('canvas');
//       if (canvas) {
//         pageRefs.current[pageNumber] = canvas;
//       } else {
//         console.error(`Canvas not found for page ${pageNumber}`);
//       }
//     } else {
//       console.error(`Page wrapper ref not found for page ${pageNumber}`);
//     }
//   };

//   const toggleCroppingMode = () => {
//     setIsCroppingMode((prev) => !prev);
//     setCropRect(null);
//   };

//   const handleFloatingBoxSubmit = async () => {
//     if (!discrepancyData) {
//       console.error('Discrepancy data not available');
//       return;
//     }

//     let updatedData = { ...discrepancyData };

//     if (selectedCategory === 'nonpayables') {
//       if (!updatedData.nonpayables.discrepancy_count) {
//         updatedData.nonpayables.discrepancy_count = 0;
//       }

//       updatedData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.push({
//         item_name: retrievedText,
//         value: parseFloat(discrepancyAmountInput),
//         reason: reasonInput
//       });

//       updatedData.nonpayables.discrepancy_count += 1;
//     } else if (selectedCategory === 'tariff') {

//       if (!updatedData.tariff.discrepancy_count) {
//         updatedData.tariff.discrepancy_count = 0;
//       }
//       updatedData.tariff.necessary_items.push({
//         item_name: retrievedText,
//         billed_amount: parseFloat(billedAmountInput), // Using billed amount
//         tariff_amount: parseFloat(exactRateInput), // Using exact rate
//         in_tariff: false,
//         amount_compliant: false,
//         justification: reasonInput,
//         price: parseFloat(exactRateInput),
//         price_range: {
//           min: parseFloat(exactRateInput),
//           max: parseFloat(exactRateInput)
//         }
//       });

//       updatedData.tariff.discrepancy_count += 1;
//     }
//     const add_discrepancy_url = `${API_ENDPOINTS.PYTHON_SERVICE}/add-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
//     try {
//       const response = await fetch(add_discrepancy_url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(updatedData),
//       });
  
//       if (response.ok) {
//         console.log('Discrepancy data updated successfully');
//         setDiscrepancyData(updatedData);
//         setTotalNonpayablesAmount(updatedData.nonpayables.categories.total_nonpayable_amount);
//         setTotalTariffDiscrepancyAmount(updatedData.tariff.total_tariff_discrepancy_amount);
//       } else {
//         console.error('Failed to update discrepancy data');
//       }
//     } catch (error) {
//       console.error('Error updating discrepancy data:', error);
//     }
  
//     // Reset the floating box state
//     setIsFloatingBoxVisible(false);
//     setRetrievedText('');
//     setSelectedCategory('');
//     setBilledAmountInput(''); // Reset billed amount
//     setExactRateInput(''); // Reset exact rate
//     setReasonInput('');
//   };

//   const handleFloatingBoxCancel = () => {
//     // Reset the floating box state
//     setIsFloatingBoxVisible(false);
//     setRetrievedText('');
//     setSelectedCategory('');
//     setDiscrepancyAmountInput('');
//     setReasonInput('');
//   };

//   const titleCaseDocId = toTitleCase(docId);

//   console.log('Rendering page with titleCaseDocId:', titleCaseDocId, 'responseText:', responseText);
//   console.log('The pdfdata stuff', pdfData)
//   return (
//     <div className="audit-details-page">
//       <Header
//         patientId={passedPatientId} // Pass patientId
//         claimId={passedClaimId}
//         docId={docId}
//         estEligibleAmount={estEligibleAmount}
//         discrepancyAmount={discrepancyAmounts[currentDiscrepancyKey] || 0}
//         onOverruleDiscrepancy={handleOverruleDiscrepancy}
//         onDiscrepancyAmountUpdate={handleDiscrepancyChange}
//         overruledDiscrepancies={overruledDiscrepancies}
//         currentDiscrepancyKey={currentDiscrepancyKey}
//         iceAuthorizedAmount={iceAuthorizedAmount}
//         setCurrentDiscrepancyKey={setCurrentDiscrepancyKey} // Add this line
//       />

//       <div className="button-group">
//         <div className="revert-button-container" style={{ display: 'flex', alignItems: 'center' }}>
//           <button onClick={revertLastAction} className="revert-button">
//             Revert Last Action
//           </button>
//           <img
//             src={InfoIcon} // Replace with the correct path to your info icon
//             alt="Info Icon"
//             className="audit-info-icon"
//             title="Click to undo the last action you performed."
//             style={{ marginLeft: '8px', cursor: 'pointer' }} // Adjust spacing
//           />
//         </div>
//         <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//           Overrule Deductions
//         </button>
//         <div className="confirm-discrepancy-btn">
//           <button onClick={handleConfirmDiscrepancy}>Confirm Deductions</button>
//           {isConfirmBoxVisible && (
//             <div className="floating-confirm-box">
//               <p>Are you sure you want to confirm this discrepancy?</p>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="pdf-comparison">
//         <div className="left-container pdf-container">
//           <h3>Claim Document</h3>
//           <div className="audit-details-pdf-viewer">
//             <Document
//               file={claimPdfData}
//               onLoadSuccess={onDocumentLoadSuccessLeft}
//               onLoadError={onDocumentLoadError}
//             >
//               {Array.from(new Array(numPagesLeft), (el, index) => {
//                 const pageNumber = index + 1;
//                 if (!pageWrapperRefs.current[pageNumber]) {
//                   pageWrapperRefs.current[pageNumber] = React.createRef();
//                 }
//                 const pageWrapperRef = pageWrapperRefs.current[pageNumber];

//                 return (
//                   <div
//                     key={`fixed_page_container_${pageNumber}`}
//                     style={{ position: 'relative' }}
//                     ref={pageWrapperRef}
//                   >
//                     <Page
//                       key={`fixed_page_${pageNumber}`}
//                       pageNumber={pageNumber}
//                       onRenderSuccess={() => {
//                         onPageRenderSuccess(pageNumber);
//                       }}
//                     />
//                     {isCroppingMode && (
//                       <div
//                         className="crop-overlay"
//                         style={{
//                           position: 'absolute',
//                           top: 0,
//                           left: 0,
//                           width: '100%',
//                           height: '100%',
//                           cursor: 'crosshair',
//                           zIndex: 10,
//                         }}
//                         onMouseDown={(e) => handleMouseDown(e, pageNumber)}
//                         onMouseMove={handleMouseMove}
//                         onMouseUp={handleMouseUp}
//                       >
//                         {cropRect && currentPageNumberRef.current === pageNumber && (
//                           <div
//                             style={{
//                               position: 'absolute',
//                               border: '2px dashed red',
//                               left: cropRect.left,
//                               top: cropRect.top,
//                               width: cropRect.width,
//                               height: cropRect.height,
//                               pointerEvents: 'none',
//                             }}
//                           ></div>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 );
//               })}
//             </Document>
//           </div>
//         </div>

//         {/* The button is now positioned between the two containers */}
//         <div className="plus-button-container">
//           <button onClick={toggleCroppingMode} className="plus-button between">
//             {isCroppingMode ? '+' : '+'}
//           </button>
//           <img
//             src={InfoIcon} // Replace with the correct path to your info icon
//             alt="Info Icon"
//             className="audit-info-icon-next-to-plus"
//             title="Click to toggle cropping mode for discrepancies."
//           />
//         </div>

//         <div className="right-container pdf-container">
//           <h3>{titleCaseDocId}</h3>
          
//           <div className="audit-details-pdf-viewer">
//             <Document
//               file={pdfData}
//               onLoadSuccess={onDocumentLoadSuccessRight}
//               onLoadError={onDocumentLoadError}
//             >
//               {Array.from(new Array(numPagesRight), (el, index) => (
//                 <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//               ))}
//             </Document>
//           </div>
//         </div>
//       </div>

//       {isFloatingBoxVisible && (
//         <div className="floating-box">
//           <h3>Extracted Text</h3>
//           {/* Make the extracted text editable */}
//           <textarea
//             value={retrievedText}
//             onChange={(e) => setRetrievedText(e.target.value)} // Allow editing the text
//             rows="2"
//             style={{ width: '100%' , font:"Manrope" }}
//           />

//           <label>Select Category:</label>
//           <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
//             <option value="">Select Category</option>
//             <option value="nonpayables">Non Payables</option>
//             <option value="tariff">Tariff</option>
//             <option value="policy_wording_document">Policy Wording Document</option>
//           </select>

//           {selectedCategory === 'tariff' ? (
//             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//               <div style={{ flex: 1, marginRight: '10px' }}>
//                 <label>Billed Amount:</label>
//                 <input
//                   type="number"
//                   value={billedAmountInput}
//                   onChange={(e) => setBilledAmountInput(e.target.value)}
//                   style={{ width: '100%' }}
//                 />
//               </div>

//               <div style={{ flex: 1 }}>
//                 <label>Exact Rate:</label>
//                 <input
//                   type="number"
//                   value={exactRateInput}
//                   onChange={(e) => setExactRateInput(e.target.value)}
//                   style={{ width: '100%'}}
//                 />
//               </div>
//             </div>
//           ) : (
//             <>
//               <label>Discrepancy Amount:</label>
//               <input
//                 type="number"
//                 value={discrepancyAmountInput}
//                 onChange={(e) => setDiscrepancyAmountInput(e.target.value)}
//               />
//             </>
//           )}

//           <label>Reason:</label>
//           <textarea value={reasonInput} onChange={(e) => setReasonInput(e.target.value)} rows="4" />

//           <button onClick={handleFloatingBoxSubmit}>Submit</button>
//           <button onClick={handleFloatingBoxCancel}>Cancel</button>
//         </div>
//       )}
//     </div>
//   );
// }

// export default AuditDetailsPage;






import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation} from 'react-router-dom';
import Header from './Header';
import './AuditDetailsPage.css';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import InfoIcon from '../icons/information.svg';
import { API_ENDPOINTS } from '../config';

// Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.3.136/pdf.worker.min.mjs';

function AuditDetailsPage() {
  const { docId } = useParams();
  const [responseText, setResponseText] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [claimPdfData, setClaimPdfData] = useState(null);
  const [settlementPdfData, setSettlementPdfData] = useState(null);
  const [numPagesLeft, setNumPagesLeft] = useState(null);
  const [numPagesRight, setNumPagesRight] = useState(null);
  const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
  const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
  const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
  // const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
  const [currentDiscrepancyKey, setCurrentDiscrepancyKey] = useState('');
  const [currentDiscrepancyCategory, setCurrentDiscrepancyCategory] = useState('');


  const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState(null);
  const [actionHistory, setActionHistory] = useState([]);
  const [isDocumentType, setIsDocumentType] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(0);
  const [iceSettlementPdfData, setIceSettlementPdfData] = useState(null);
  const [discrepancyData, setDiscrepancyData] = useState(null);
  const [billedAmountInput, setBilledAmountInput] = useState('');
  const [exactRateInput, setExactRateInput] = useState('');


  // Define the missing state variables
  const [totalPolicyWordingDocumentAmount, setTotalPolicyWordingDocumentAmount] = useState(0);
  const [totalNonpayablesAmount, setTotalNonpayablesAmount] = useState(0);
  const [totalTariffDiscrepancyAmount, setTotalTariffDiscrepancyAmount] = useState(0);

  // State variables for cropping functionality
  const [isCropping, setIsCropping] = useState(false);
  const [cropStart, setCropStart] = useState(null);
  const [cropRect, setCropRect] = useState(null);
  const [isCroppingMode, setIsCroppingMode] = useState(false);

  // Use useRef for currentPageNumber and pageRefs
  const currentPageNumberRef = useRef(null);
  const pageRefs = useRef({});
  const pageWrapperRefs = useRef({}); // Refs to the page containers

  // State variables for the floating box after cropping
  const [isFloatingBoxVisible, setIsFloatingBoxVisible] = useState(false);
  const [retrievedText, setRetrievedText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [discrepancyAmountInput, setDiscrepancyAmountInput] = useState('');
  const [reasonInput, setReasonInput] = useState('');


  // Retrieve patientId and claimId from location.state or sessionStorage
  const location = useLocation();
  // const passedPatientId = location.state?.patientId || sessionStorage.getItem("patientId");
  // const passedClaimId = location.state?.claimId || sessionStorage.getItem("claimId");
  // const passedPatientId = "ICE-A0D9D823"
  // const passedClaimId = "CLAIM-0C654916"


  // 2. Fall back to sessionStorage if not present in the URL:
  // const passedPatientId = sessionStorage.getItem('patientId') || location.state?.patientId || "ICE-A0D9D823";
  // const passedClaimId =sessionStorage.getItem('claimId') || location.state?.claimId || "CLAIM-0C654916";
  const passedPatientId = sessionStorage.getItem('patientId') || location.state?.patientId;
  const passedClaimId =sessionStorage.getItem('claimId') || location.state?.claimId;

  console.log('Passed patientId:', sessionStorage.getItem('patientId'), 'Passed claimId:', sessionStorage.getItem('claimId'));
  const fetchPolicyStatus = async () => {
    // Include patient_id and claim_id as query parameters in the URL
  const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setIceAuthorizedAmount(data.ice_authorized_amount); // Set ICE Authorized Amount from API response
        setEstEligibleAmount(data.ice_authorized_amount); // Set initial Est Eligible Amount
        setTotalPolicyWordingDocumentAmount(data.total_policy_wording_document_amount);
        setTotalNonpayablesAmount(data.nonpayables.categories.total_nonpayable_amount);
        setTotalTariffDiscrepancyAmount(data.tariff.total_tariff_discrepancy_amount);
        setDiscrepancyData(data); // Store the entire discrepancy data
        console.log('Policy status fetched:', data);
      } else {
        const errorText = await response.text();
        console.error('Failed to fetch policy status:', errorText);
        setError('Failed to fetch policy status');
      }
    } catch (error) {
      console.error('Error fetching policy status:', error);
      setError('Error fetching policy status');
    }
 };
useEffect(() => {
  const fetchResponseText = async () => {
    // Ensure that passedPatientId and passedClaimId are defined
    if (!passedPatientId || !passedClaimId) {
      console.error("Missing patientId or claimId");
      setError("Missing patientId or claimId");
      return;
    }

    // Skip fetching response text if docId is "nonpayables"
    if (docId === "nonpayables") {
      console.log("Skipping response text fetch for docId: nonpayables");
      setResponseText("Response text not applicable for nonpayables.");
      return;
    }

    // Update the URL to include patientId and claimId as query parameters
    const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
    
    try {
      console.log('Fetching response text from:', url);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Received response text:', data);

        // Check if the response contains the response_text for the specified docId
        if (data.response_text && data.response_text[docId]) {
          setResponseText(data.response_text[docId]);
        } else {
          console.warn(`No response text found for docId: ${docId}`);
          setResponseText('No response text available.');
        }
      } else {
        const errorText = await response.text();
        console.error('Failed to fetch response text:', errorText);
        setError('Failed to fetch response text');
      }
    } catch (error) {
      console.error('Error fetching response text:', error);
      setError('Error fetching response text');
    }
  };

    // const fetchDocuments = async () => {
    //   try {
    //     if (docId === 'discrepancy_summary') {
    //       console.log('Fetching discrepancy summary documents...');

    //       // Ensure that passedPatientId and passedClaimId are defined
    //       if (!passedPatientId || !passedClaimId) {
    //         console.error("Missing patientId or claimId");
    //         setError("Missing patientId or claimId");
    //         return;
    //       }

    //   // Update the URL to include patientId and claimId as query parameters
    //       // Add passedPatientId and passedClaimId as query parameters in the URLs
    //     const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
    //     // const iceSettlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/ice_settlement?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;

    //       // const [settlementPdfResponse, iceSettlementPdfResponse] = await Promise.all([
    //       //   fetch(settlementPdfUrl),
    //       //   fetch(iceSettlementPdfUrl),
    //       // ]);
    //       const [settlementPdfResponse] = await Promise.all([
    //         fetch(settlementPdfUrl),
    //         // fetch(iceSettlementPdfUrl),
    //       ]);

    //       // if (settlementPdfResponse.ok && iceSettlementPdfResponse.ok) {
    //       if (settlementPdfResponse.ok ) {
    //         const settlementPdfBlob = await settlementPdfResponse.blob();
    //         // const iceSettlementPdfBlob = await iceSettlementPdfResponse.blob();

    //         setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
    //         // setIceSettlementPdfData(URL.createObjectURL(iceSettlementPdfBlob));
    //       } else {
    //         throw new Error('Failed to fetch one or both PDFs for discrepancy summary');
    //       }
    //     } else if (docId === 'nonpayables') {
    //       console.log('Fetching nonpayables documents...');
    //       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/get_documents?patient_id=${passedPatientId}&claim_id=${passedClaimId}`, {
    //     method: 'GET',
    //     headers: { 'Content-Type': 'application/json' },
    //   });

    //       if (response.ok) {
    //         const data = await response.json();
    //         console.log('Received nonpayables document data:', data);

    //         const baseUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/`;
    //         // const claimPdfUrl = `${baseUrl}${data.claim_pdf.path.replace(/\\/g, '/')}`;
    //         const claimPdfUrl = `${baseUrl}${data.nonpayables_claim.path.replace(/\\/g, '/')}`;
    //         const nonpayablesPdfUrl = `${baseUrl}${data.nonpayables_pdf.path.replace(/\\/g, '/')}`;

    //         console.log('Constructed Claim PDF URL:', claimPdfUrl);
    //         console.log('Constructed Nonpayables PDF URL:', nonpayablesPdfUrl);

    //         const claimBlob = await fetch(claimPdfUrl).then((res) => {
    //           if (res.ok) return res.blob();
    //           throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
    //         });
    //         const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
    //           if (res.ok) return res.blob();
    //           throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
    //         });

    //         const claimBlobUrl = URL.createObjectURL(claimBlob);
    //         const nonpayablesBlobUrl = URL.createObjectURL(nonpayablesBlob);
            
    //         console.log('Generated Claim PDF Blob URL:', claimBlobUrl);
    //         console.log('Generated Nonpayables PDF Blob URL:', nonpayablesBlobUrl);

    //         setClaimPdfData(claimBlobUrl);
    //         setPdfData(nonpayablesBlobUrl);

    //         // Fetch settlement PDF for nonpayables
    //         const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
    //         console.log('Fetching settlement PDF from:', settlementPdfUrl);
    //         const settlementPdfResponse = await fetch(settlementPdfUrl);
    //         if (settlementPdfResponse.ok) {
    //           const settlementPdfBlob = await settlementPdfResponse.blob();
    //           setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
    //           console.log('Successfully loaded settlement PDF for nonpayables');
    //         } else {
    //           console.warn('Settlement PDF not found or failed to load for nonpayables');
    //           setSettlementPdfData(null);
    //         }
    //       } else {
    //         const errorText = await response.text();
    //         console.error('Failed to fetch documents:', errorText);
    //         setError('Failed to fetch documents');
    //       }
    //     } else {
    //       const fixedPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_fixed_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
    //       const otherPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_other_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
    //       const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
      
    //       try {
    //           // Fetch fixed PDF
    //           console.log("Fetching fixed PDF from:", fixedPdfUrl);
    //           const fixedPdfResponse = await fetch(fixedPdfUrl);
    //           if (fixedPdfResponse.ok) {
    //               const fixedPdfData = await fixedPdfResponse.json(); // Assuming the backend returns a JSON response
    //               const fixedPdfPresignedUrl = fixedPdfData.url; // Extract presigned URL from response
      
    //               console.log("Fetching fixed PDF from S3 using presigned URL:", fixedPdfPresignedUrl);
    //               const fixedPdfBlob = await fetch(fixedPdfPresignedUrl).then((res) => {
    //                   if (res.ok) return res.blob();
    //                   throw new Error(`Failed to fetch fixed PDF from S3: ${res.statusText}`);
    //               });
      
    //               setClaimPdfData(URL.createObjectURL(fixedPdfBlob));
    //           } else {
    //               console.error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
    //           }
      
    //           // Fetch other PDF
    //           console.log("Fetching other PDF from:", otherPdfUrl);
    //           const otherPdfResponse = await fetch(otherPdfUrl);
    //           if (otherPdfResponse.ok) {
    //               const otherPdfData = await otherPdfResponse.json(); // Assuming the backend returns a JSON response
    //               const otherPdfPresignedUrl = otherPdfData.url; // Extract presigned URL from response
      
    //               console.log("Fetching other PDF from S3 using presigned URL:", otherPdfPresignedUrl);
    //               const otherPdfBlob = await fetch(otherPdfPresignedUrl).then((res) => {
    //                   if (res.ok) return res.blob();
    //                   throw new Error(`Failed to fetch other PDF from S3: ${res.statusText}`);
    //               });
      
    //               setPdfData(URL.createObjectURL(otherPdfBlob));
    //           } else {
    //               console.error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
    //           }
      
    //           // Fetch settlement PDF
    //           console.log("Fetching settlement PDF from:", settlementPdfUrl);
    //           const settlementPdfResponse = await fetch(settlementPdfUrl);
    //           if (settlementPdfResponse.ok) {
    //               const settlementPdfData = await settlementPdfResponse.json(); // Assuming the backend returns a JSON response
    //               const settlementPdfPresignedUrl = settlementPdfData.url; // Extract presigned URL from response
      
    //               console.log("Fetching settlement PDF from S3 using presigned URL:", settlementPdfPresignedUrl);
    //               const settlementPdfBlob = await fetch(settlementPdfPresignedUrl).then((res) => {
    //                   if (res.ok) return res.blob();
    //                   throw new Error(`Failed to fetch settlement PDF from S3: ${res.statusText}`);
    //               });
      
    //               setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
    //           } else {
    //               console.warn("Settlement PDF not found or failed to load");
    //               setSettlementPdfData(null);
    //           }
    //       } catch (error) {
    //           console.error("Error fetching fixed/other/settlement PDFs:", error);
    //       } finally {
    //           setLoading(false);
    //       }
    //   };


    

    const fetchDocuments = async () => {
      try {
        console.log("Fetching documents...");
    
        if (docId === "discrepancy_summary") {
          console.log("Fetching discrepancy summary documents...");
    
          if (!passedPatientId || !passedClaimId) {
            console.error("Missing patientId or claimId");
            setError("Missing patientId or claimId");
            return;
          }
    
          // Fetch settlement PDF for discrepancy summary
          const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
          const settlementPdfResponse = await fetch(settlementPdfUrl);
    
          if (settlementPdfResponse.ok) {
            const settlementPdfData = await settlementPdfResponse.json();
            const settlementPdfPresignedUrl = settlementPdfData.url;
    
            const settlementPdfBlob = await fetch(settlementPdfPresignedUrl).then((res) => {
              if (res.ok) return res.blob();
              throw new Error(`Failed to fetch settlement PDF from S3: ${res.statusText}`);
            });
    
            setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
          } else {
            throw new Error("Failed to fetch settlement PDF for discrepancy summary");
          }
        } else if (docId === "nonpayables") {
          console.log("Fetching nonpayables documents...");
    
          // Fetch nonpayables documents
          const response = await fetch(
            `${API_ENDPOINTS.PYTHON_SERVICE}/get_documents?patient_id=${passedPatientId}&claim_id=${passedClaimId}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            }
          );
    
          if (response.ok) {
            const data = await response.json();
            console.log("Received nonpayables document data:", data);
    
            // Extract URLs for claim_document and nonpayables
            const claimPdfUrl = data?.nonpayables_claim?.url;
            const nonpayablesPdfUrl = data?.nonpayables?.url;
    
            if (!claimPdfUrl || !nonpayablesPdfUrl) {
              throw new Error(
                "Missing required document URLs in the response. Check the API response."
              );
            }
    
            // Fetch Claim Document PDF
            const claimBlob = await fetch(claimPdfUrl).then((res) => {
              if (res.ok) return res.blob();
              throw new Error(`Failed to fetch claim PDF: ${res.statusText}`);
            });
    
            // Fetch Nonpayables Document PDF
            const nonpayablesBlob = await fetch(nonpayablesPdfUrl).then((res) => {
              if (res.ok) return res.blob();
              throw new Error(`Failed to fetch nonpayables PDF: ${res.statusText}`);
            });
    
            setClaimPdfData(URL.createObjectURL(claimBlob)); // For claim_document
            setPdfData(URL.createObjectURL(nonpayablesBlob)); // For nonpayables
          } else {
            const errorText = await response.text();
            console.error("Failed to fetch documents:", errorText);
            setError("Failed to fetch documents");
          }
        } else {
          console.log("Fetching general documents...");
    
          // Fetch fixed PDF and other PDF
          const fixedPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_fixed_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
          const otherPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_other_pdf?docId=${docId}&patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
          const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
    
          // Fetch Fixed PDF
          const fixedPdfResponse = await fetch(fixedPdfUrl);
          if (fixedPdfResponse.ok) {
            const fixedPdfData = await fixedPdfResponse.json();
            const fixedPdfPresignedUrl = fixedPdfData.url;
    
            const fixedPdfBlob = await fetch(fixedPdfPresignedUrl).then((res) => {
              if (res.ok) return res.blob();
              throw new Error(`Failed to fetch fixed PDF from S3: ${res.statusText}`);
            });
    
            setClaimPdfData(URL.createObjectURL(fixedPdfBlob));
          } else {
            throw new Error(`Failed to fetch fixed PDF: ${fixedPdfResponse.statusText}`);
          }
    
          // Fetch Other PDF
          const otherPdfResponse = await fetch(otherPdfUrl);
          if (otherPdfResponse.ok) {
            const otherPdfData = await otherPdfResponse.json();
            const otherPdfPresignedUrl = otherPdfData.url;
    
            const otherPdfBlob = await fetch(otherPdfPresignedUrl).then((res) => {
              if (res.ok) return res.blob();
              throw new Error(`Failed to fetch other PDF from S3: ${res.statusText}`);
            });
    
            setPdfData(URL.createObjectURL(otherPdfBlob));
          } else {
            throw new Error(`Failed to fetch other PDF: ${otherPdfResponse.statusText}`);
          }
    
          // Fetch Settlement PDF
          const settlementPdfResponse = await fetch(settlementPdfUrl);
          if (settlementPdfResponse.ok) {
            const settlementPdfData = await settlementPdfResponse.json();
            const settlementPdfPresignedUrl = settlementPdfData.url;
    
            const settlementPdfBlob = await fetch(settlementPdfPresignedUrl).then((res) => {
              if (res.ok) return res.blob();
              throw new Error(`Failed to fetch settlement PDF from S3: ${res.statusText}`);
            });
    
            setSettlementPdfData(URL.createObjectURL(settlementPdfBlob));
          } else {
            console.warn("Settlement PDF not found or failed to load");
            setSettlementPdfData(null);
          }
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
        setError("Error fetching documents");
      } finally {
        setLoading(false);
      }
    };
    
  
    const fetchPolicyStatus = async () => {
       // Include patient_id and claim_id as query parameters in the URL
  const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setIceAuthorizedAmount(data.ice_authorized_amount); // Set ICE Authorized Amount from API response
          setEstEligibleAmount(data.ice_authorized_amount); // Set initial Est Eligible Amount
          setTotalPolicyWordingDocumentAmount(data.total_policy_wording_document_amount);
          setTotalNonpayablesAmount(data.nonpayables.categories.total_nonpayable_amount);
          setTotalTariffDiscrepancyAmount(data.tariff.total_tariff_discrepancy_amount);
          setDiscrepancyData(data); // Store the entire discrepancy data
          console.log('Policy status fetched:', data);
        } else {
          const errorText = await response.text();
          console.error('Failed to fetch policy status:', errorText);
          setError('Failed to fetch policy status');
        }
      } catch (error) {
        console.error('Error fetching policy status:', error);
        setError('Error fetching policy status');
      }
    };

    fetchResponseText();
    fetchDocuments();
    fetchPolicyStatus();
  }, [docId, passedPatientId, passedClaimId]);

  const updatePolicyStatus = () => {
    fetchPolicyStatus();
  };

  function onDocumentLoadSuccessLeft({ numPages }) {
    console.log('Loaded left document with number of pages:', numPages);
    setNumPagesLeft(numPages);
  }

  function onDocumentLoadSuccessRight({ numPages }) {
    console.log('Loaded right document with number of pages:', numPages);
    setNumPagesRight(numPages);
  }

  function onDocumentLoadError(error) {
    console.error('Error loading PDF document:', error);
    setPdfError('Failed to load PDF file. Please check the file format or try again later.');
  }

  // Function to handle confirming discrepancies
  // Function to handle confirming discrepancies
  const handleConfirmDiscrepancy = async () => {
    if (actionToConfirm === 'overrule') {
      if (!overruledDiscrepancies[currentDiscrepancyKey]) {
        const discrepancyAmount = discrepancyAmounts[currentDiscrepancyKey] || 0;
        const newIceAuthorizedAmount = iceAuthorizedAmount + discrepancyAmount;
  
        let requestBody = {};
  
        // Update discrepancy amount to zero
        if (docId === 'policy_wording_document') {
          requestBody[currentDiscrepancyKey] = 0;
        } else if (docId === 'nonpayables') {
          requestBody = {
            items_non_payable_but_billed_in_the_bill: [
              { item_name: currentDiscrepancyKey, value: 0 },
            ],
          };
        } else if (docId === 'tariff_document') {
          if (
            currentDiscrepancyCategory === 'necessary_items' ||
            currentDiscrepancyCategory === 'non_compliant_items'
          ) {
            // For necessary_items and non_compliant_items, set 'price' to zero
            requestBody = {
              [currentDiscrepancyCategory]: [
                // { item_name: currentDiscrepancyKey, price: 0 },
                { item_name: currentDiscrepancyKey, billed_amount: 0 },
              ],
            };
          } else if (currentDiscrepancyCategory === 'unnecessary_items') {
            // For unnecessary_items, set 'billed_amount' to zero
            requestBody = {
              [currentDiscrepancyCategory]: [
                { item_name: currentDiscrepancyKey, billed_amount: 0 },
              ],
            };
          } else {
            console.warn(`Unhandled category in tariff_document: ${currentDiscrepancyCategory}`);
          }
        } else if (docId === 'stg_document') {
          requestBody[currentDiscrepancyKey] = 0;
        } else {
          // Handle other document types if necessary
          console.warn(`Unhandled docId: ${docId}`);
        }
  
        const url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
        try {
          const response = await fetch(url, {
            method: 'POST', // Use POST for /update-discrepancy
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });
  
          if (response.ok) {
            const data = await response.json();
            console.log(data.message);
  
            // Update state after successful response
            fetchPolicyStatus();
            setIceAuthorizedAmount(newIceAuthorizedAmount);
  
            setOverruledDiscrepancies((prevState) => ({
              ...prevState,
              [currentDiscrepancyKey]: true,
            }));
  
            setActionHistory((prevHistory) => [
              ...prevHistory,
              { action: 'overrule', key: currentDiscrepancyKey, amount: discrepancyAmount },
            ]);
          } else {
            console.error('Failed to update discrepancy:', await response.text());
          }
        } catch (error) {
          console.error('Error updating discrepancy:', error);
        }
      }
    }
    // setIsConfirmBoxVisible(false);
    setIsConfirmBoxVisible(true);
    // setActionToConfirm(null);
    setActionToConfirm('overrule');
  };
  
  


  // Function to handle overrule discrepancies
  const handleOverruleDiscrepancy = () => {
    setActionToConfirm('overrule');
    setIsConfirmBoxVisible(true);
  };

  // Function to handle discrepancy amount changes
  const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyKey, extraInfo = {}) => {
    const { docId: actionDocId, category } = extraInfo;
  
    setActionHistory((prevHistory) => [
      ...prevHistory,
      {
        action: 'update',
        key: discrepancyKey,
        amount: discrepancyAmounts[discrepancyKey] || 0,
        docId: actionDocId,
        category: category,
      },
    ]);
    setDiscrepancyAmounts((prevState) => ({
      ...prevState,
      [discrepancyKey]: newDiscrepancyAmount,
    }));
    setCurrentDiscrepancyKey(discrepancyKey);
    setCurrentDiscrepancyCategory(category); // Add this line
  };
  

  // Function to handle discrepancy amount changes
  // const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
  //   console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
  //   setActionHistory((prevHistory) => [
  //     ...prevHistory,
  //     { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 },
  //   ]);
  //   setDiscrepancyAmounts((prevState) => ({
  //     ...prevState,
  //     [discrepancyIndex]: newDiscrepancyAmount,
  //   }));
  //   setCurrentDiscrepancyIndex(discrepancyIndex);
  // };

  // const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyKey) => {
  //   console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyKey);
  //   setActionHistory((prevHistory) => [
  //     ...prevHistory,
  //     { action: 'update', key: discrepancyKey, amount: discrepancyAmounts[discrepancyKey] || 0 },
  //   ]);
  //   setDiscrepancyAmounts((prevState) => ({
  //     ...prevState,
  //     [discrepancyKey]: newDiscrepancyAmount,
  //   }));
  //   setCurrentDiscrepancyKey(discrepancyKey);
  // };
  
  
  // Function to revert the last action
  const revertLastAction = async () => {
    console.log('revertLastAction called');
    if (actionHistory.length === 0) {
      console.log('No actions to revert');
      return;
    }
  
    const lastAction = actionHistory[actionHistory.length - 1];
    console.log('Last action:', lastAction);
  
    if (lastAction.action === 'overrule') {
      const discrepancyAmount = lastAction.amount;
      const discrepancyKey = lastAction.key;
  
      let requestBody = {};
  
      // Restore the original discrepancy amount
      if (docId === 'policy_wording_document') {
        requestBody[discrepancyKey] = discrepancyAmount;
      } else if (docId === 'nonpayables') {
        requestBody = {
          items_non_payable_but_billed_in_the_bill: [
            { item_name: discrepancyKey, value: discrepancyAmount },
          ],
        };
      } else if (docId === 'tariff_document') {
        requestBody = {
          [currentDiscrepancyCategory]: [
            // { item_name: discrepancyKey, price: discrepancyAmount },
            { item_name: discrepancyKey, billed_amount: discrepancyAmount },
          ],
        };
      } else if (docId === 'stg_document') {
        requestBody[discrepancyKey] = discrepancyAmount;
      } else {
        console.warn(`Unhandled docId: ${docId}`);
      }
  
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
      try {
        const response = await fetch(url, {
          method: 'POST', // Use POST for /update-discrepancy
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log(data.message);
  
          // Update state after successful response
          fetchPolicyStatus();
  
          // Remove the discrepancy from overruledDiscrepancies
          setOverruledDiscrepancies(prevState => {
            const newState = { ...prevState };
            delete newState[discrepancyKey];
            return newState;
          });
        } else {
          console.error('Failed to revert discrepancy:', await response.text());
        }
      } catch (error) {
        console.error('Error reverting discrepancy:', error);
      }
    }  else if (lastAction.action === 'update') {
      const revertedAmount = lastAction.amount;
      const discrepancyKey = lastAction.key;
      const lastDocId = lastAction.docId;
      const category = lastAction.category;
  
      const update_discrepancy_url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
      let payload = {};
  
      if (lastDocId === 'stg_document') {
        payload = { alignment_percentage: revertedAmount };
      } else if (lastDocId === 'nonpayables') {
        payload = {
          items_non_payable_but_billed_in_the_bill: [
            { item_name: discrepancyKey, value: revertedAmount },
          ],
        };
      } else if (lastDocId === 'tariff_document') {
        if (category === 'necessary_items' || category === 'non_compliant_items') {
          payload = {
            // [category]: [{ item_name: discrepancyKey, price: revertedAmount }],
            [category]: [{ item_name: discrepancyKey, billed_amount: revertedAmount }],
          };
        } else if (category === 'unnecessary_items') {
          payload = {
            [category]: [{ item_name: discrepancyKey, billed_amount: revertedAmount }],
          };
        } else {
          console.error('Unknown category:', category);
          return; // Exit the function if category is unknown
        }
      } else {
        payload = { [discrepancyKey]: revertedAmount };
      }
  
      try {
        const response = await fetch(update_discrepancy_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          // Use the constructed payload here
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          console.log('Discrepancy reverted successfully on the server.');
          setDiscrepancyAmounts((prevState) => ({
            ...prevState,
            [lastAction.key]: revertedAmount,
          }));
          setCurrentDiscrepancyKey(lastAction.key);
          // Update policy status to update iceAuthorizedAmount
          fetchPolicyStatus(); // Add this line
        } else {
          const errorText = await response.text();
          console.error('Failed to revert discrepancy on the server:', errorText);
        }
      } catch (error) {
        console.error('Error reverting discrepancy:', error);
      }
    }
  
    // Remove the last action from the history
    setActionHistory((prevHistory) => prevHistory.slice(0, -1));
  };

  const nameMappings = {
    policy_document: 'Enrollment',
    policy_wording_document: 'Policy Wording',
    stg_document: 'Standard Treatment Guidelines',
    tariff_document: 'Tariff',
    nonpayables: 'Non Payables',
    discrepancy_summary: 'Discrepancy Summary',
  };

  const toTitleCase = (str) => {
    if (!str) return '';
    return (
      nameMappings[str] ||
      str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
        if (txt.toLowerCase() === 'stg') {
          return 'STG';
        }
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    );
  };

  if (loading) {
    console.log('Loading data...');
    return <div>Loading...</div>;
  }

  if (error) {
    console.error('Error encountered:', error);
    return <div>Error: {error}</div>;
  }

  if (docId === 'discrepancy_summary') {
    if (!settlementPdfData || !iceSettlementPdfData) {
      console.log(
        'Missing data for discrepancy_summary - settlementPdfData:',
        settlementPdfData,
        'iceSettlementPdfData:',
        iceSettlementPdfData
      );
      return <div>Loading...</div>;
    }
  // } else if (!responseText || !pdfData || !claimPdfData || (isDocumentType && !settlementPdfData)) {
  //   console.log(
  //     'Missing data - responseText:',
  //     responseText,
  //     'pdfData:',
  //     pdfData,
  //     'claimPdfData:',
  //     claimPdfData,
  //     'settlementPdfData:',
  //     settlementPdfData
  //   );
  //   return <div>Loading...</div>;
  // }
  } else if (
    !pdfData || 
    !claimPdfData || 
    (docId !== "nonpayables" && (!responseText || (isDocumentType && !settlementPdfData)))
  ) {
    console.log(
      'Missing data - responseText:',
      responseText,
      'pdfData:',
      pdfData,
      'claimPdfData:',
      claimPdfData,
      'settlementPdfData:',
      settlementPdfData
    );
    return <div>Loading...</div>;
  }
  

  const handleMouseDown = (e, pageNumber) => {
    if (!isCroppingMode) return;
    setIsCropping(true);
    currentPageNumberRef.current = pageNumber;
    const rect = e.target.getBoundingClientRect();
    setCropStart({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleMouseMove = (e) => {
    if (!isCropping || !isCroppingMode) return;
    const rect = e.target.getBoundingClientRect();
    const currentPoint = { x: e.clientX - rect.left, y: e.clientY - rect.top };
    setCropRect({
      left: Math.min(cropStart.x, currentPoint.x),
      top: Math.min(cropStart.y, currentPoint.y),
      width: Math.abs(currentPoint.x - cropStart.x),
      height: Math.abs(currentPoint.y - cropStart.y),
    });
  };

  const handleMouseUp = () => {
    if (!isCroppingMode) return;
    setIsCropping(false);
    handleCrop();
    setIsCroppingMode(false);
    setCropRect(null);
  };

  const handleCrop = () => {
    const canvas = pageRefs.current[currentPageNumberRef.current];
    if (!canvas || !cropRect) {
      console.error('Canvas or crop rectangle not available');
      return;
    }

    // Calculate scaling factors
    const scaleX = canvas.width / canvas.clientWidth;
    const scaleY = canvas.height / canvas.clientHeight;

    const cropX = cropRect.left * scaleX;
    const cropY = cropRect.top * scaleY;
    const cropWidth = cropRect.width * scaleX;
    const cropHeight = cropRect.height * scaleY;

    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;
    const ctx = croppedCanvas.getContext('2d');

    ctx.drawImage(
      canvas,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    const croppedImageData = croppedCanvas.toDataURL('image/png');
    sendCroppedImageToBackend(croppedImageData);
  };

  const sendCroppedImageToBackend = (imageData) => {
    fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/save-image?patient_id=${passedPatientId}&claim_id=${passedClaimId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ imageData }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Image saved:', data.filename);
        setRetrievedText(data.generated_text);
        setIsFloatingBoxVisible(true);
      })
      .catch((error) => {
        console.error('Error saving image:', error);
      });
  };

  const onPageRenderSuccess = (pageNumber) => {
    const pageWrapperRef = pageWrapperRefs.current[pageNumber];
    if (pageWrapperRef && pageWrapperRef.current) {
      const canvas = pageWrapperRef.current.querySelector('canvas');
      if (canvas) {
        pageRefs.current[pageNumber] = canvas;
      } else {
        console.error(`Canvas not found for page ${pageNumber}`);
      }
    } else {
      console.error(`Page wrapper ref not found for page ${pageNumber}`);
    }
  };

  const toggleCroppingMode = () => {
    setIsCroppingMode((prev) => !prev);
    setCropRect(null);
  };

  // const handleFloatingBoxSubmit = () => {
  //   // Handle submission logic here
  //   console.log('Submitted data:', {
  //     retrievedText,
  //     selectedCategory,
  //     discrepancyAmountInput,
  //     reasonInput,
  //   });

  //   // Reset the floating box state
  //   setIsFloatingBoxVisible(false);
  //   setRetrievedText('');
  //   setSelectedCategory('');
  //   setDiscrepancyAmountInput('');
  //   setReasonInput('');

  //   // Optionally, update the state or send data to the backend
  // };

  const handleFloatingBoxSubmit = async () => {
    if (!discrepancyData) {
      console.error('Discrepancy data not available');
      return;
    }
  
    let updatedData = { ...discrepancyData };
  
    if (selectedCategory === 'nonpayables') {
      if (!updatedData.nonpayables.discrepancy_count) {
        updatedData.nonpayables.discrepancy_count = 0;
      }


      updatedData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.push({
        item_name: retrievedText,
        value: parseFloat(discrepancyAmountInput),
        reason: reasonInput
      });
      // updatedData.nonpayables.categories.total_nonpayable_amount += parseFloat(discrepancyAmountInput);
      // // updatedData.ice_authorised_amount = updatedData.ice_authorised_amount - parseFloat(discrepancyAmountInput)

      // console.log("Updated ICE Authorized Amount:",updatedData.ice_authorised_amount)
      updatedData.nonpayables.discrepancy_count += 1;
    } else if (selectedCategory === 'tariff') {

      if (!updatedData.tariff.discrepancy_count) {
        updatedData.tariff.discrepancy_count = 0;
      }
      updatedData.tariff.necessary_items.push({
        item_name: retrievedText,
        billed_amount: parseFloat(billedAmountInput), // Using billed amount
        tariff_amount: parseFloat(exactRateInput), // Using exact rate
        in_tariff: false,
        amount_compliant: false,
        justification: reasonInput,
        price: parseFloat(exactRateInput),
        price_range: {
          min: parseFloat(exactRateInput),
          max: parseFloat(exactRateInput)
        }
      });
      // updatedData.tariff.total_tariff_discrepancy_amount = parseFloat(billedAmountInput)- parseFloat(exactRateInput);
      // // Subtract the calculated discrepancy from the ice_authorized_amount
      // let discrepancy = parseFloat(billedAmountInput) - parseFloat(exactRateInput);

      // updatedData.ice_authorized_amount -= discrepancy;  // Correct logic to update ice_authorized_amount

      // console.log("Updated ICE Authorized Amount:", updatedData.ice_authorized_amount);

      // Increment the discrepancy count
      updatedData.tariff.discrepancy_count += 1;
    }
    const add_discrepancy_url = `${API_ENDPOINTS.PYTHON_SERVICE}/add-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
    try {
      const response = await fetch(add_discrepancy_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
  
      if (response.ok) {
        console.log('Discrepancy data updated successfully');
        setDiscrepancyData(updatedData);
        setTotalNonpayablesAmount(updatedData.nonpayables.categories.total_nonpayable_amount);
        setTotalTariffDiscrepancyAmount(updatedData.tariff.total_tariff_discrepancy_amount);
      } else {
        console.error('Failed to update discrepancy data');
      }
    } catch (error) {
      console.error('Error updating discrepancy data:', error);
    }
  
    // Reset the floating box state
    setIsFloatingBoxVisible(false);
    setRetrievedText('');
    setSelectedCategory('');
    setBilledAmountInput(''); // Reset billed amount
    setExactRateInput(''); // Reset exact rate
    setReasonInput('');
  };
  




  const handleFloatingBoxCancel = () => {
    // Reset the floating box state
    setIsFloatingBoxVisible(false);
    setRetrievedText('');
    setSelectedCategory('');
    setDiscrepancyAmountInput('');
    setReasonInput('');
  };

  const titleCaseDocId = toTitleCase(docId);

  console.log('Rendering page with titleCaseDocId:', titleCaseDocId, 'responseText:', responseText);
  console.log('The pdfdata stuff', pdfData)
  return (
    <div className="audit-details-page">
      <Header
        patientId={passedPatientId} // Pass patientId
        claimId={passedClaimId}
        docId={docId}
        estEligibleAmount={estEligibleAmount}
        discrepancyAmount={discrepancyAmounts[currentDiscrepancyKey] || 0}
        onOverruleDiscrepancy={handleOverruleDiscrepancy}
        onDiscrepancyAmountUpdate={handleDiscrepancyChange}
        overruledDiscrepancies={overruledDiscrepancies}
        currentDiscrepancyKey={currentDiscrepancyKey}
        iceAuthorizedAmount={iceAuthorizedAmount}
        setCurrentDiscrepancyKey={setCurrentDiscrepancyKey} // Add this line
        updatePolicyStatus={updatePolicyStatus} // Add this line
      />
  
  <div className="button-group">
  <div className="revert-button-container" style={{ display: 'flex', alignItems: 'center' }}>
    <button onClick={revertLastAction} className="revert-button">
      Revert Last Action
    </button>
    <img
      src={InfoIcon} // Replace with the correct path to your info icon
      alt="Info Icon"
      className="audit-info-icon"
      title="Click to undo the last action you performed."
      style={{ marginLeft: '8px', cursor: 'pointer' }} // Adjust spacing
    />
  </div>

  {/* <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
    Overrule Deductions
  </button> */}

  <div className="confirm-discrepancy-btn">
    <button onClick={handleConfirmDiscrepancy}>Overrule Deductions</button>
    {isConfirmBoxVisible && (
      <div className="floating-confirm-box">
        <p>Are you sure you want to confirm this discrepancy?</p>
      </div>
    )}
  </div>
</div>

      <div className="pdf-comparison">
        <div className="left-container pdf-container">
          <h3>Claim Document</h3>
          <div className="audit-details-pdf-viewer">
            <Document
              file={claimPdfData}
              onLoadSuccess={onDocumentLoadSuccessLeft}
              onLoadError={onDocumentLoadError}
            >
              {Array.from(new Array(numPagesLeft), (el, index) => {
                const pageNumber = index + 1;
                if (!pageWrapperRefs.current[pageNumber]) {
                  pageWrapperRefs.current[pageNumber] = React.createRef();
                }
                const pageWrapperRef = pageWrapperRefs.current[pageNumber];
  
                return (
                  <div
                    key={`fixed_page_container_${pageNumber}`}
                    style={{ position: 'relative' }}
                    ref={pageWrapperRef}
                  >
                    <Page
                      key={`fixed_page_${pageNumber}`}
                      pageNumber={pageNumber}
                      onRenderSuccess={() => {
                        onPageRenderSuccess(pageNumber);
                      }}
                    />
                    {isCroppingMode && (
                      <div
                        className="crop-overlay"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          cursor: 'crosshair',
                          zIndex: 10,
                        }}
                        onMouseDown={(e) => handleMouseDown(e, pageNumber)}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                      >
                        {cropRect && currentPageNumberRef.current === pageNumber && (
                          <div
                            style={{
                              position: 'absolute',
                              border: '2px dashed red',
                              left: cropRect.left,
                              top: cropRect.top,
                              width: cropRect.width,
                              height: cropRect.height,
                              pointerEvents: 'none',
                            }}
                          ></div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </Document>
          </div>
        </div>
  
        {/* The button is now positioned between the two containers */}
        <div className="plus-button-container">
        <button onClick={toggleCroppingMode} className="plus-button between">
          {isCroppingMode ? '+' : '+'}
        </button>
        <img
          src={InfoIcon} // Replace with the correct path to your info icon
          alt="Info Icon"
          className="audit-info-icon-next-to-plus"
          title="Click to toggle cropping mode for discrepancies."
        />
      </div>


        
        <div className="right-container pdf-container">
          <h3>{titleCaseDocId}</h3>
          
          <div className="audit-details-pdf-viewer">
            <Document
              file={pdfData}
              onLoadSuccess={onDocumentLoadSuccessRight}
              onLoadError={onDocumentLoadError}
            >
              {Array.from(new Array(numPagesRight), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </div>
      </div>
  
      {isFloatingBoxVisible && (
        <div className="floating-box">
          <h3>Extracted Text</h3>
          {/* Make the extracted text editable */}
          <textarea
            value={retrievedText}
            onChange={(e) => setRetrievedText(e.target.value)} // Allow editing the text
            rows="2"
            style={{ width: '100%' , font:"Manrope" }}
          />
  
          <label>Select Category:</label>
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">Select Category</option>
            <option value="nonpayables">Non Payables</option>
            <option value="tariff">Tariff</option>
            <option value="policy_wording_document">Policy Wording Document</option>
          </select>
  
          {selectedCategory === 'tariff' ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flex: 1, marginRight: '10px' }}>
                <label>Billed Amount:</label>
                <input
                  type="number"
                  value={billedAmountInput}
                  onChange={(e) => setBilledAmountInput(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>

              <div style={{ flex: 1 }}>
                <label>Exact Rate:</label>
                <input
                  type="number"
                  value={exactRateInput}
                  onChange={(e) => setExactRateInput(e.target.value)}
                  style={{ width: '100%'}}
                />
              </div>
            </div>
          ) : (
            <>
              <label>Discrepancy Amount:</label>
              <input
                type="number"
                value={discrepancyAmountInput}
                onChange={(e) => setDiscrepancyAmountInput(e.target.value)}
              />
            </>
          )}


  
          <label>Reason:</label>
          <textarea value={reasonInput} onChange={(e) => setReasonInput(e.target.value)} rows="4" />
  
          <button onClick={handleFloatingBoxSubmit}>Submit</button>
          <button onClick={handleFloatingBoxCancel}>Cancel</button>
        </div>
      )}
    </div>
  );
  
}

export default AuditDetailsPage;
