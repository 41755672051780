// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import logo from "../icons/logonew.svg"; // Update the path to your logo file
// import "./LoginPage.css";

// const LoginPage = () => {
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form submitted:", formData);
//   };

//   const navigate = useNavigate();

//   return (
//     <div className="login-page">
//       {/* Logo Section */}
//       <div className="login-logo-container">
//         <img src={logo} alt="ICE Logo" className="login-logo-image" />
//         <span className="login-logo-text">ICE</span>
//       </div>

//       {/* Form Section */}
//       <div className="form-container">
//         <form className="login-form" onSubmit={handleSubmit}>
//           {/* Email Input */}
//           <div className="login-form-group">
//             <label htmlFor="email">Email</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               placeholder="Enter your email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           {/* Password Input */}
//           <div className="login-form-group">
//             <label htmlFor="password">Password</label>
//             <input
//               type="password"
//               id="password"
//               name="password"
//               placeholder="Enter your password"
//               value={formData.password}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           {/* Remember Me Checkbox */}
//           <div className="remember-me-row">
//             <input type="checkbox" id="rememberMe" />
//             <label htmlFor="rememberMe">Remember Me</label>
//           </div>

//           {/* Sign In Button */}
//           <button type="submit" className="login-button">
//             Sign In
//           </button>

//           {/* Centered Elements */}
//           <div className="centered-links">
//             <p className="forgot-password">
//               <a href="/forgot-password">Lost your password?</a>
//             </p>

//             <div className="login-divider">
//               <span>Or schedule a demo</span>
//             </div>

//             <button type="button" className="login-access-button" onClick={() => navigate("/access")}>
//               Apply for Access
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default LoginPage;




// login page testing

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../icons/logonew.svg"; // Update the path to your logo file
import "./LoginPage.css";
import { API_ENDPOINTS } from '../config';
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons from react-icons

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(""); // State to handle login errors
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to the login endpoint
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email_or_patient_id: formData.email, // Use email_or_patient_id as expected by the backend
          password: formData.password,
        }),
      });

      // Check if the response is successful
      if (response.ok) {
        const data = await response.json();
        console.log("Login successful:", data);

        // Redirect the user to app.icehealth.in
        window.location.href = "https://app.icehealth.in";
      } else {
        // Handle login errors
        const errorData = await response.json();
        setError(errorData.detail || "Login failed. Please try again.");
      }
    } catch (err) {
      console.error("Error during login:", err);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-page">
      {/* Logo Section */}
      <div className="login-logo-container">
        <img src={logo} alt="ICE Logo" className="login-logo-image" />
        <span className="login-logo-text">ICE</span>
      </div>

      {/* Form Section */}
      <div className="form-container">
        <form className="login-form" onSubmit={handleSubmit}>
          {/* Email Input */}
          <div className="login-form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          {/* Password Input */}
          <div className="login-form-group password-input-container">
            <label htmlFor="password">Password</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"} // Toggle input type
                id="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Toggle eye icon */}
              </span>
            </div>
          </div>

          {/* Error Message */}
          {error && <p className="error-message">{error}</p>}

          {/* Remember Me Checkbox */}
          <div className="remember-me-row">
            <input type="checkbox" id="rememberMe" />
            <label htmlFor="rememberMe">Remember Me</label>
          </div>

          {/* Sign In Button */}
          <button type="submit" className="login-button">
            Sign In
          </button>

          <p className="forgot-password">
              <a href="/forgot-password">Lost your password?</a>
            </p>

            <div className="login-divider">
              <span>Or schedule a demo</span>
            </div>

            <button type="button" className="login-access-button" onClick={() => navigate("/access")}>
              Apply for Access
            </button>
          {/* Centered Elements */}
          <div className="centered-links">
            

            

            
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;