// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import AlertIcon from '../icons/alerticon.svg';
// import { marked } from 'marked';

// function Header({
//   docId,
//   estEligibleAmount,
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
//   currentDiscrepancyIndex,
//   showDisclaimer = true,
//   fetchResponseText = true
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(currentDiscrepancyIndex);

//   const location = useLocation();

//   useEffect(() => {
//     fetchClaimData();
//     fetchClaimAmounts();
//     if (fetchResponseText) {
//       fetchResponseTextData();
//     }
//     fetchPolicyStatus();
//   }, [docId, fetchResponseText]);

//   const fetchClaimData = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:5000/claim-data');
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();

//       const hospitalDetails = data.hospital_details;
//       const patientDetails = data.patient_details;
//       const diagnosisDetails = data.admission_details;

//       if (hospitalDetails && hospitalDetails.name) {
//         setHospitalName(hospitalDetails.name);
//       }
//       if (patientDetails && patientDetails.name) {
//         setPatientName(patientDetails.name);
//       }
//       if (diagnosisDetails && diagnosisDetails.primary_diagnosis) {
//         setPrimaryDiagnosis(diagnosisDetails.primary_diagnosis);
//       }

//       setIsHospitalBlacklisted(data.blacklisted_hospital === "True");
//     } catch (error) {
//       console.error('Error fetching the claim data', error);
//     }
//   };

//   const fetchClaimAmounts = async () => {
//     const url = 'http://127.0.0.1:5000/claim-amounts';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setClaimAmounts({
//           initial_total_net_amount: data.initial_total_net_amount,
//           total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//           total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//           total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//           total_exclusion_amount: data.total_exclusion_amount,
//           amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//           total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//           total_copayment: data.total_copayment
//         });
//       } else {
//         console.error('Failed to fetch claim amounts', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async () => {
//     const url = 'http://localhost:5000/get_processed_data';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();

//         if (data.response_text) {
//           setResponseText(data.response_text);
//         }

//         if (data.validation_results) {
//           setValidationResults(data.validation_results);
//         }
//       } else {
//         console.error('Failed to fetch response text', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching response text:', error);
//     }
//   };

//   const fetchPolicyStatus = async () => {
//     const url = 'http://127.0.0.1:5000/policy-status';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();

//         // Handle dynamic discrepancy for policy_wording_document
//         if (docId === 'policy_wording_document' && data.policy_wording_document) {
//           setPolicyDiscrepancyAmounts(data.policy_wording_document);
//           setPolicyDiscrepancyInfo(data.policy_wording_document.information);
//           const initialDiscrepancyAmount = data.policy_wording_document[Object.keys(data.policy_wording_document).filter(key => key !== 'information')[discrepancyIndex]];
//           onDiscrepancyAmountUpdate(initialDiscrepancyAmount, discrepancyIndex);
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//       .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);
  
//     if (nonZeroDiscrepancyKeys.length === 0) return; // No valid discrepancies to navigate through
  
//     setDiscrepancyIndex((prevIndex) => {
//       const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//       const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//       onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//       return newIndex;
//     });
//   };
  
//   const handlePrevDiscrepancy = () => {
//     const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//       .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);
  
//     if (nonZeroDiscrepancyKeys.length === 0) return; // No valid discrepancies to navigate through
  
//     setDiscrepancyIndex((prevIndex) => {
//       const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//       const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//       onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//       return newIndex;
//     });
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//     .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex];

//   const discrepancyAmount = policyDiscrepancyAmounts[filteredDiscrepancyKey] || 0;
//   const discrepancyReason = policyDiscrepancyInfo[filteredDiscrepancyKey]?.reason || '';
//   const discrepancyDetails = policyDiscrepancyInfo[filteredDiscrepancyKey]?.details || '';

//   // For non-policy_wording_document, use the static responseText and validationResults
//   const filteredValidationResults = validationResults[docId];
//   const filteredResponseText = responseText[docId];

//   if (!hospitalName || !patientName || !primaryDiagnosis || !claimAmounts.initial_total_net_amount) {
//     return <p>Loading...</p>;
//   }

//   let claimedAmount = claimAmounts.initial_total_net_amount;
//   let estEligibleAmountDisplay = estEligibleAmount !== null && estEligibleAmount !== undefined ? estEligibleAmount : claimAmounts.total_net_amount_after_copayment;
//   let estRecovery = (claimedAmount !== null && claimedAmount !== undefined && estEligibleAmountDisplay !== 'Loading...')
//     ? claimedAmount - estEligibleAmountDisplay
//     : 'Calculating...';

//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {docId.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               {/* <img src={AlertIcon} alt="Alert Icon" /> */}
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>
        
//         {!isClaimDetailsPage && (
//           <div className="input-group">
//             <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>Prev Discrepancy</button>
//             <button className="secondary-button" onClick={handleNextDiscrepancy}>Next Discrepancy</button>
//           </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//           <div className="claim-item">
//             <div className="icon user-icon"></div>
//             <div>
//               <p className="claimant-name">{patientName}</p>
//               <p className="fraud-risk">Fraud Risk: <span className="low-risk">Low</span></p>
//             </div>
//           </div>
//           <div className="claim-item">
//             <div className="icon hospital-icon"></div>
//             <div>
//               <p className="hospital-name">{hospitalName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk: <span className={isHospitalBlacklisted ? "blacklisted-risk" : "low-risk"}>
//                   {isHospitalBlacklisted ? "Blacklisted" : "Low"}
//                 </span>
//               </p>
//             </div>
//           </div>

//           <div className="claim-item">
//             <div className="icon diagnosis-icon"></div>
//             <div>
//               <p className="primary-diagnosis">{primaryDiagnosis}</p>
//             </div>
//           </div>
//         </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>Claimed</p>
//             <p className="amount">₹ {claimedAmount !== null && claimedAmount !== undefined ? claimedAmount.toLocaleString() : 'Loading...'}</p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Eligible</p>
//             <p className="amount">₹ {estEligibleAmountDisplay !== null && estEligibleAmountDisplay !== undefined ? estEligibleAmountDisplay.toLocaleString() : 'Loading...'}</p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Recovery</p>
//             <p className="amount">₹ {estRecovery !== 'Calculating...' ? estRecovery.toLocaleString() : estRecovery}</p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 {docId === 'policy_wording_document' ? (
//                   <>
//                     <p><strong>Discrepancy :</strong> {discrepancyReason}</p>
//                     <p>
//                       <strong>Details:</strong>
//                       <span dangerouslySetInnerHTML={{
//                         __html: marked(isExpanded ? discrepancyDetails : truncateText(discrepancyDetails, 200)),
//                       }} />
//                       {!isExpanded && discrepancyDetails.length > 200 && (
//                         <span className="read-more" onClick={handleToggleExpand}> read more</span>
//                       )}
//                     </p>
//                   </>
//                 ) : (
//                   <>
//                     <p><strong>Discrepancy :</strong> {filteredValidationResults?.reason?.[0]}</p>
//                     <p>
//                       <strong>Details:</strong>
//                       <span dangerouslySetInnerHTML={{
//                         __html: marked(isExpanded ? filteredResponseText?.[0] || '' : truncateText(filteredResponseText?.[0] || '', 200)),
//                       }} />
//                       {!isExpanded && filteredResponseText?.[0]?.length > 200 && (
//                         <span className="read-more" onClick={handleToggleExpand}> read more</span>
//                       )}
//                     </p>
//                   </>
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//             <p>Discrepancy Amount</p>
//             <p className="amount">₹ {typeof discrepancyAmount === 'number' ? discrepancyAmount.toLocaleString() : 'Loading...'}</p>
            
//             {/* Tag display and overruled text */}
//             {docId === 'policy_wording_document' && isPolicyValid && (
//               <>
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px' }}>
//                   {filteredDiscrepancyKey}
//                 </p>
//                 {overruledDiscrepancies[discrepancyIndex] && (
//                   <p className="overruled-text" style={{ color: 'red', fontSize: '20px', fontWeight: "bold",marginTop: '-5px' }}>
//                     (OVERRULED)
//                   </p>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;









// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import PencilIcon from '../icons/pencilicon.svg'; // Import the pencil icon
// import { marked } from 'marked';

// function Header({
//   docId,
//   estEligibleAmount,
//   discrepancyAmount, // Receiving discrepancyAmount from props
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
//   currentDiscrepancyIndex,
//   showDisclaimer = true,
//   fetchResponseText = true
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(currentDiscrepancyIndex);
//   const [isEditing, setIsEditing] = useState(false); // State to track if the amount is being edited
//   const [editedAmount, setEditedAmount] = useState(''); // State to store the edited amount

//   const location = useLocation();

//   useEffect(() => {
//     fetchClaimData();
//     fetchClaimAmounts();
//     if (fetchResponseText) {
//       fetchResponseTextData();
//     }
//     fetchPolicyStatus();
//   }, [docId, fetchResponseText]);

//   useEffect(() => {
//     // Set the initial edited amount based on the passed discrepancyAmount prop
//     setEditedAmount(discrepancyAmount || '');
//   }, [discrepancyAmount]);

//   const fetchClaimData = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:5000/claim-data');
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();

//       const hospitalDetails = data.hospital_details;
//       const patientDetails = data.patient_details;
//       const diagnosisDetails = data.admission_details;

//       if (hospitalDetails && hospitalDetails.name) {
//         setHospitalName(hospitalDetails.name);
//       }
//       if (patientDetails && patientDetails.name) {
//         setPatientName(patientDetails.name);
//       }
//       if (diagnosisDetails && diagnosisDetails.primary_diagnosis) {
//         setPrimaryDiagnosis(diagnosisDetails.primary_diagnosis);
//       }

//       setIsHospitalBlacklisted(data.blacklisted_hospital === "True");
//     } catch (error) {
//       console.error('Error fetching the claim data', error);
//     }
//   };

//   const fetchClaimAmounts = async () => {
//     const url = 'http://127.0.0.1:5000/claim-amounts';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setClaimAmounts({
//           initial_total_net_amount: data.initial_total_net_amount,
//           total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//           total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//           total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//           total_exclusion_amount: data.total_exclusion_amount,
//           amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//           total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//           total_copayment: data.total_copayment
//         });
//       } else {
//         console.error('Failed to fetch claim amounts', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async () => {
//     const url = 'http://localhost:5000/get_processed_data';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();

//         if (data.response_text) {
//           setResponseText(data.response_text);
//         }

//         if (data.validation_results) {
//           setValidationResults(data.validation_results);
//         }
//       } else {
//         console.error('Failed to fetch response text', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching response text:', error);
//     }
//   };

//   const fetchPolicyStatus = async () => {
//     const url = 'http://127.0.0.1:5000/policy-status';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();

//         if (docId === 'policy_wording_document' && data.policy_wording_document) {
//           setPolicyDiscrepancyAmounts(data.policy_wording_document);
//           setPolicyDiscrepancyInfo(data.policy_wording_document.information);
//           const initialDiscrepancyAmount = data.policy_wording_document[Object.keys(data.policy_wording_document).filter(key => key !== 'information')[discrepancyIndex]];
//           onDiscrepancyAmountUpdate(initialDiscrepancyAmount, discrepancyIndex);
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//       .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);

//     if (nonZeroDiscrepancyKeys.length === 0) return;

//     setDiscrepancyIndex((prevIndex) => {
//       const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//       const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//       onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//       return newIndex;
//     });
//   };

//   const handlePrevDiscrepancy = () => {
//     const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//       .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);

//     if (nonZeroDiscrepancyKeys.length === 0) return;

//     setDiscrepancyIndex((prevIndex) => {
//       const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//       const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//       onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//       return newIndex;
//     });
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAmountBlur(); // Save the amount and exit editing mode
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true); // Enable editing mode
//   };

//   const handleAmountChange = (e) => {
//     setEditedAmount(e.target.value); // Update the edited amount state
//   };

//   const handleAmountBlur = async () => {
//     setIsEditing(false); // Disable editing mode
//     const updatedAmount = Number(editedAmount);
  
//     if (!isNaN(updatedAmount)) {
//       try {
//         const response = await fetch('http://localhost:5000/update-discrepancy', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [filteredDiscrepancyKey]: updatedAmount
//           })
//         });
  
//         if (response.ok) {
//           console.log("Discrepancy updated successfully on the server.");
  
//           // Refetch the updated data from the server
//           await fetchPolicyStatus(); 
  
//           // Optionally, you can directly update the local state here as well to ensure immediate UI update:
//           setPolicyDiscrepancyAmounts(prev => ({
//             ...prev,
//             [filteredDiscrepancyKey]: updatedAmount,
//           }));
  
//           onDiscrepancyAmountUpdate(updatedAmount, discrepancyIndex); // Update the parent component with new data
  
//         } else {
//           console.error("Failed to update discrepancy on the server.");
//         }
//       } catch (error) {
//         console.error("Error updating discrepancy:", error);
//       }
//     }
//   };
  
//   // Function to format the document ID into a human-readable form
//   const toTitleCase = (str) => {
//     if (!str) return '';  // Null/undefined check
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   const formattedDocId = toTitleCase(docId);

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//     .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex];

//   const discrepancyReason = policyDiscrepancyInfo[filteredDiscrepancyKey]?.reason || '';
//   const discrepancyDetails = policyDiscrepancyInfo[filteredDiscrepancyKey]?.details || '';

//   const filteredValidationResults = validationResults[docId];
//   const filteredResponseText = responseText[docId];

//   if (!hospitalName || !patientName || !primaryDiagnosis || !claimAmounts.initial_total_net_amount) {
//     return <p>Loading...</p>;
//   }

//   let claimedAmount = claimAmounts.initial_total_net_amount;
//   let estEligibleAmountDisplay = estEligibleAmount !== null && estEligibleAmount !== undefined ? estEligibleAmount : claimAmounts.total_net_amount_after_copayment;
//   let estRecovery = (claimedAmount !== null && claimedAmount !== undefined && estEligibleAmountDisplay !== 'Loading...')
//     ? claimedAmount - estEligibleAmountDisplay
//     : 'Calculating...';

//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {formattedDocId}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>
        
//         {!isClaimDetailsPage && (
//           <div className="input-group">
//             <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>Prev Discrepancy</button>
//             <button className="secondary-button" onClick={handleNextDiscrepancy}>Next Discrepancy</button>
//           </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//           <div className="claim-item">
//             <div className="icon user-icon"></div>
//             <div>
//               <p className="claimant-name">{patientName}</p>
//               <p className="fraud-risk">Fraud Risk: <span className="low-risk">Low</span></p>
//             </div>
//           </div>
//           <div className="claim-item">
//             <div className="icon hospital-icon"></div>
//             <div>
//               <p className="hospital-name">{hospitalName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk: <span className={isHospitalBlacklisted ? "blacklisted-risk" : "low-risk"}>
//                   {isHospitalBlacklisted ? "Blacklisted" : "Low"}
//                 </span>
//               </p>
//             </div>
//           </div>
  
//           <div className="claim-item">
//             <div className="icon diagnosis-icon"></div>
//             <div>
//               <p className="primary-diagnosis">{primaryDiagnosis}</p>
//             </div>
//           </div>
//         </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>Claimed</p>
//             <p className="amount">₹ {claimedAmount !== null && claimedAmount !== undefined ? claimedAmount.toLocaleString() : 'Loading...'}</p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Eligible</p>
//             <p className="amount">₹ {estEligibleAmountDisplay !== null && estEligibleAmountDisplay !== undefined ? estEligibleAmountDisplay.toLocaleString() : 'Loading...'}</p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Recovery</p>
//             <p className="amount">₹ {estRecovery !== 'Calculating...' ? estRecovery.toLocaleString() : estRecovery}</p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 {docId === 'policy_wording_document' ? (
//                   <>
//                     <p><strong>Discrepancy :</strong> {discrepancyReason}</p>
//                     <p>
//                       <strong>Details:</strong>
//                       <span dangerouslySetInnerHTML={{
//                         __html: marked(isExpanded ? discrepancyDetails : truncateText(discrepancyDetails, 200)),
//                       }} />
//                       {!isExpanded && discrepancyDetails.length > 200 && (
//                         <span className="read-more" onClick={handleToggleExpand}> read more</span>
//                       )}
//                     </p>
//                   </>
//                 ) : (
//                   <>
//                     <p><strong>Discrepancy :</strong> {filteredValidationResults?.reason?.[0]}</p>
//                     <p>
//                       <strong>Details:</strong>
//                       <span dangerouslySetInnerHTML={{
//                         __html: marked(isExpanded ? filteredResponseText?.[0] || '' : truncateText(filteredResponseText?.[0] || '', 200)),
//                       }} />
//                       {!isExpanded && filteredResponseText?.[0]?.length > 200 && (
//                         <span className="read-more" onClick={handleToggleExpand}> read more</span>
//                       )}
//                     </p>
//                   </>
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//             <div className="discrepancy-header">
//               <p>Discrepancy Amount</p>
//               <img
//                 src={PencilIcon}
//                 alt="Edit Icon"
//                 className="edit-icon"
//                 onClick={handleEditClick} // Enable editing on click
//               />
//             </div>
//             <div className="editable-amount">
//               {isEditing ? (
//                 <input
//                   type="text"
//                   value={editedAmount}
//                   onChange={handleAmountChange}
//                   onBlur={handleAmountBlur} // Save the amount when input loses focus
//                   onKeyDown={handleKeyDown} // Listen for Enter key press
//                   autoFocus
//                 />
//               ) : (
//                 <p className="amount">
//                   ₹ {typeof discrepancyAmount === 'number' ? discrepancyAmount.toLocaleString() : 'Loading...'}
//                 </p>
//               )}
//               <p className="tag" style={{ fontSize: 'small', marginTop: '-5px' ,marginLeft: '6px'}}>
//                 {filteredDiscrepancyKey}
//               </p>
//             </div>

//             {/* Tag display and overruled text */}
//             {docId === 'policy_wording_document' && isPolicyValid && (
//               <>
//                 {overruledDiscrepancies[discrepancyIndex] && (
//                   <p className="overruled-text" style={{ color: 'red', fontSize: '20px', fontWeight: "bold", marginTop: '-5px' }}>
//                     (OVERRULED)
//                   </p>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;








// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import PencilIcon from '../icons/pencilicon.svg'; // Import the pencil icon
// import { marked } from 'marked';

// function Header({
//   docId,
//   estEligibleAmount,
//   discrepancyAmount, // Receiving discrepancyAmount from props
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
//   currentDiscrepancyIndex,
//   showDisclaimer = true,
//   fetchResponseText = true
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(currentDiscrepancyIndex);
//   const [isEditing, setIsEditing] = useState(false); // State to track if the amount is being edited
//   const [editedAmount, setEditedAmount] = useState(''); // State to store the edited amount

//   const location = useLocation();

//   useEffect(() => {
//     fetchClaimData();
//     fetchClaimAmounts();
//     if (fetchResponseText) {
//       fetchResponseTextData();
//     }
//     fetchPolicyStatus();
//   }, [docId, fetchResponseText]);

//   useEffect(() => {
//     // Set the initial edited amount based on the passed discrepancyAmount prop
//     setEditedAmount(discrepancyAmount || '');
//   }, [discrepancyAmount]);

//   const fetchClaimData = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:5000/claim-data');
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();

//       const hospitalDetails = data.hospital_details;
//       const patientDetails = data.patient_details;
//       const diagnosisDetails = data.admission_details;

//       if (hospitalDetails && hospitalDetails.name) {
//         setHospitalName(hospitalDetails.name);
//       }
//       if (patientDetails && patientDetails.name) {
//         setPatientName(patientDetails.name);
//       }
//       if (diagnosisDetails && diagnosisDetails.primary_diagnosis) {
//         setPrimaryDiagnosis(diagnosisDetails.primary_diagnosis);
//       }

//       setIsHospitalBlacklisted(data.blacklisted_hospital === "True");
//     } catch (error) {
//       console.error('Error fetching the claim data', error);
//     }
//   };

//   const fetchClaimAmounts = async () => {
//     const url = 'http://127.0.0.1:5000/claim-amounts';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setClaimAmounts({
//           initial_total_net_amount: data.initial_total_net_amount,
//           total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//           total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//           total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//           total_exclusion_amount: data.total_exclusion_amount,
//           amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//           total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//           total_copayment: data.total_copayment
//         });
//       } else {
//         console.error('Failed to fetch claim amounts', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async () => {
//     const url = 'http://localhost:5000/get_processed_data';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();

//         if (data.response_text) {
//           setResponseText(data.response_text);
//         }

//         if (data.validation_results) {
//           setValidationResults(data.validation_results);
//         }
//       } else {
//         console.error('Failed to fetch response text', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching response text:', error);
//     }
//   };

//   const fetchPolicyStatus = async () => {
//     const url = 'http://127.0.0.1:5000/policy-status';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();

//         if (docId === 'policy_wording_document' && data.policy_wording_document) {
//           setPolicyDiscrepancyAmounts(data.policy_wording_document);
//           setPolicyDiscrepancyInfo(data.policy_wording_document.information);
//           const initialDiscrepancyAmount = data.policy_wording_document[Object.keys(data.policy_wording_document).filter(key => key !== 'information')[discrepancyIndex]];
//           onDiscrepancyAmountUpdate(initialDiscrepancyAmount, discrepancyIndex);
//         } else if (docId === 'stg_document' && data.stg_document) {
//           const stgDiscrepancyAmount = data.stg_document.alignment_percentage;
//           onDiscrepancyAmountUpdate(stgDiscrepancyAmount, 0); // Use index 0 as it's a single value
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//       .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);

//     if (nonZeroDiscrepancyKeys.length === 0) return;

//     setDiscrepancyIndex((prevIndex) => {
//       const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//       const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//       onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//       return newIndex;
//     });
//   };

//   const handlePrevDiscrepancy = () => {
//     const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//       .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);

//     if (nonZeroDiscrepancyKeys.length === 0) return;

//     setDiscrepancyIndex((prevIndex) => {
//       const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//       const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//       onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//       return newIndex;
//     });
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAmountBlur(); // Save the amount and exit editing mode
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true); // Enable editing mode
//   };

//   const handleAmountChange = (e) => {
//     setEditedAmount(e.target.value); // Update the edited amount state
//   };

//   const handleAmountBlur = async () => {
//     setIsEditing(false); // Disable editing mode
//     const updatedAmount = Number(editedAmount);
  
//     if (!isNaN(updatedAmount)) {
//       try {
//         const response = await fetch('http://localhost:5000/update-discrepancy', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [docId === 'stg_document' ? 'alignment_percentage' : filteredDiscrepancyKey]: updatedAmount
//           })
//         });
  
//         if (response.ok) {
//           console.log("Discrepancy updated successfully on the server.");
  
//           // Refetch the updated data from the server
//           await fetchPolicyStatus(); 
  
//           // Optionally, you can directly update the local state here as well to ensure immediate UI update:
//           setPolicyDiscrepancyAmounts(prev => ({
//             ...prev,
//             [filteredDiscrepancyKey]: updatedAmount,
//           }));
  
//           onDiscrepancyAmountUpdate(updatedAmount, discrepancyIndex); // Update the parent component with new data
  
//         } else {
//           console.error("Failed to update discrepancy on the server.");
//         }
//       } catch (error) {
//         console.error("Error updating discrepancy:", error);
//       }
//     }
//   };
  
//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Billing',
//     nonpayables: 'Nonpayables',
//   };
  
//   const toTitleCase = (str) => {
//     if (!str) return '';  // Null/undefined check
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   const formattedDocId = toTitleCase(docId);

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts)
//     .filter(key => key !== 'information' && policyDiscrepancyAmounts[key] !== 0);
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex];

//   const discrepancyReason = policyDiscrepancyInfo[filteredDiscrepancyKey]?.reason || '';
//   const discrepancyDetails = policyDiscrepancyInfo[filteredDiscrepancyKey]?.details || '';

//   const filteredValidationResults = validationResults[docId];
//   const filteredResponseText = responseText[docId];

//   if (!hospitalName || !patientName || !primaryDiagnosis || !claimAmounts.initial_total_net_amount) {
//     return <p>Loading...</p>;
//   }

//   let claimedAmount = claimAmounts.initial_total_net_amount;
//   let estEligibleAmountDisplay = estEligibleAmount !== null && estEligibleAmount !== undefined ? estEligibleAmount : claimAmounts.total_net_amount_after_copayment;
//   let estRecovery = (claimedAmount !== null && claimedAmount !== undefined && estEligibleAmountDisplay !== 'Loading...')
//     ? claimedAmount - estEligibleAmountDisplay
//     : 'Calculating...';

//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {formattedDocId}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>
        
//         {!isClaimDetailsPage && (
//           <div className="input-group">
//             <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>Prev Discrepancy</button>
//             <button className="secondary-button" onClick={handleNextDiscrepancy}>Next Discrepancy</button>
//           </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//           <div className="claim-item">
//             <div className="icon user-icon"></div>
//             <div>
//               <p className="claimant-name">{patientName}</p>
//               <p className="fraud-risk">Fraud Risk: <span className="low-risk">Low</span></p>
//             </div>
//           </div>
//           <div className="claim-item">
//             <div className="icon hospital-icon"></div>
//             <div>
//               <p className="hospital-name">{hospitalName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk: <span className={isHospitalBlacklisted ? "blacklisted-risk" : "low-risk"}>
//                   {isHospitalBlacklisted ? "Blacklisted" : "Low"}
//                 </span>
//               </p>
//             </div>
//           </div>
  
//           <div className="claim-item">
//             <div className="icon diagnosis-icon"></div>
//             <div>
//               <p className="primary-diagnosis">{primaryDiagnosis}</p>
//             </div>
//           </div>
//         </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>TPA Authorized</p>
//             <p className="amount">₹ {claimedAmount !== null && claimedAmount !== undefined ? claimedAmount.toLocaleString() : 'Loading...'}</p>
//           </div>
//           <div className="summary-item">
//             <p>ICE Authorized</p>
//             <p className="amount">₹ {estEligibleAmountDisplay !== null && estEligibleAmountDisplay !== undefined ? estEligibleAmountDisplay.toLocaleString() : 'Loading...'}</p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Recovery</p>
//             <p className="amount">₹ {estRecovery !== 'Calculating...' ? estRecovery.toLocaleString() : estRecovery}</p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 {docId === 'policy_wording_document' ? (
//                   <>
//                     <p><strong>Discrepancy :</strong> {discrepancyReason}</p>
//                     <p>
//                       <strong>Details:</strong>
//                       <span dangerouslySetInnerHTML={{
//                         __html: marked(isExpanded ? discrepancyDetails : truncateText(discrepancyDetails, 200)),
//                       }} />
//                       {!isExpanded && discrepancyDetails.length > 200 && (
//                         <span className="read-more" onClick={handleToggleExpand}> read more</span>
//                       )}
//                     </p>
//                   </>
//                 ) : (
//                   <>
//                     <p><strong>Discrepancy :</strong> {filteredValidationResults?.reason?.[0]}</p>
//                     <p>
//                       <strong>Details:</strong>
//                       <span dangerouslySetInnerHTML={{
//                         __html: marked(isExpanded ? filteredResponseText?.[0] || '' : truncateText(filteredResponseText?.[0] || '', 200)),
//                       }} />
//                       {!isExpanded && filteredResponseText?.[0]?.length > 200 && (
//                         <span className="read-more" onClick={handleToggleExpand}> read more</span>
//                       )}
//                     </p>
//                   </>
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//             <div className="discrepancy-header">
//               <p>{docId === 'stg_document' ? 'Similarity Score' : 'Discrepancy Amount'}</p>
//               <img
//                 src={PencilIcon}
//                 alt="Edit Icon"
//                 className="edit-icon"
//                 onClick={handleEditClick} // Enable editing on click
//               />
//             </div>
//             <div className="editable-amount">
//               {isEditing ? (
//                 <input
//                   type="text"
//                   value={editedAmount}
//                   onChange={handleAmountChange}
//                   onBlur={handleAmountBlur} // Save the amount when input loses focus
//                   onKeyDown={handleKeyDown} // Listen for Enter key press
//                   autoFocus
//                 />
//               ) : (
//                 <p className="amount">
//                   {docId === 'stg_document'
//                     ? `${discrepancyAmount}%`
//                     : `₹ ${typeof discrepancyAmount === 'number' ? discrepancyAmount.toLocaleString() : 'Loading...'}`}
//                 </p>
//               )}
//               {docId !== 'stg_document' && (
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px' ,marginLeft: '6px'}}>
//                   {filteredDiscrepancyKey}
//                 </p>
//               )}
//             </div>

//             {/* Tag display and overruled text */}
//             {docId === 'policy_wording_document' && isPolicyValid && (
//               <>
//                 {overruledDiscrepancies[discrepancyIndex] && (
//                   <p className="overruled-text" style={{ color: 'red', fontSize: '20px', fontWeight: "bold", marginTop: '-5px' }}>
//                     (OVERRULED)
//                   </p>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;





// getting the nonpayables logic

// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import PencilIcon from '../icons/pencilicon.svg'; // Import the pencil icon
// import { marked } from 'marked';

// function Header({
//   docId,
//   estEligibleAmount,
//   discrepancyAmount,
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
//   currentDiscrepancyIndex,
//   showDisclaimer = true,
//   fetchResponseText = true,
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null,
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [nonpayablesData, setNonpayablesData] = useState([]); // Store nonpayables data
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(currentDiscrepancyIndex);
//   const [isEditing, setIsEditing] = useState(false); // State to track if the amount is being edited
//   const [editedAmount, setEditedAmount] = useState(''); // State to store the edited amount

//   const location = useLocation();

//   useEffect(() => {
//     fetchClaimData();
//     fetchClaimAmounts();
//     if (fetchResponseText) {
//       fetchResponseTextData();
//     }
//     fetchPolicyStatus();
//   }, [docId, fetchResponseText]);

//   useEffect(() => {
//     // Set the initial edited amount based on the passed discrepancyAmount prop
//     if (discrepancyAmount !== undefined && discrepancyAmount !== null) {
//       setEditedAmount(discrepancyAmount);
//     }
//   }, [discrepancyAmount]);

//   const fetchClaimData = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:5000/claim-data');
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();

//       const hospitalDetails = data.hospital_details;
//       const patientDetails = data.patient_details;
//       const diagnosisDetails = data.admission_details;

//       if (hospitalDetails && hospitalDetails.name) {
//         setHospitalName(hospitalDetails.name);
//       }
//       if (patientDetails && patientDetails.name) {
//         setPatientName(patientDetails.name);
//       }
//       if (diagnosisDetails && diagnosisDetails.primary_diagnosis) {
//         setPrimaryDiagnosis(diagnosisDetails.primary_diagnosis);
//       }

//       setIsHospitalBlacklisted(data.blacklisted_hospital === "True");
//     } catch (error) {
//       console.error('Error fetching the claim data', error);
//     }
//   };

//   const fetchClaimAmounts = async () => {
//     const url = 'http://127.0.0.1:5000/claim-amounts';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setClaimAmounts({
//           initial_total_net_amount: data.initial_total_net_amount,
//           total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//           total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//           total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//           total_exclusion_amount: data.total_exclusion_amount,
//           amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//           total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//           total_copayment: data.total_copayment,
//         });
//       } else {
//         console.error('Failed to fetch claim amounts', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async () => {
//     const url = 'http://localhost:5000/get_processed_data';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();

//         if (data.response_text) {
//           setResponseText(data.response_text);
//         }

//         if (data.validation_results) {
//           setValidationResults(data.validation_results);
//         }
//       } else {
//         console.error('Failed to fetch response text', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching response text:', error);
//     }
//   };

//   const fetchPolicyStatus = async () => {
//     const url = 'http://127.0.0.1:5000/policy-status';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8'
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();

//         if (docId === 'policy_wording_document' && data.policy_wording_document) {
//           setPolicyDiscrepancyAmounts(data.policy_wording_document);
//           setPolicyDiscrepancyInfo(data.policy_wording_document.information);
//           const initialDiscrepancyAmount = data.policy_wording_document[
//             Object.keys(data.policy_wording_document).filter((key) => key !== 'information')[discrepancyIndex]
//           ];
//           onDiscrepancyAmountUpdate(initialDiscrepancyAmount, discrepancyIndex);
//         } else if (docId === 'stg_document' && data.stg_document) {
//           const stgDiscrepancyAmount = data.stg_document.alignment_percentage;
//           onDiscrepancyAmountUpdate(stgDiscrepancyAmount, 0); // Use index 0 as it's a single value
//         } else if (docId === 'nonpayables' && data.nonpayables) {
//           const nonpayables = data.nonpayables.categories.items_non_payable_but_billed_in_the_bill || [];
//           setNonpayablesData(nonpayables);

//           if (nonpayables.length > 0) {
//             const initialDiscrepancy = nonpayables[currentDiscrepancyIndex];
//             onDiscrepancyAmountUpdate(initialDiscrepancy.value, currentDiscrepancyIndex);
//           }
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const handlePrevDiscrepancy = () => {
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonpayablesData.length) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAmountBlur(); // Save the amount and exit editing mode
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true); // Enable editing mode
//   };

//   const handleAmountChange = (e) => {
//     setEditedAmount(e.target.value); // Update the edited amount state
//   };

//   const handleAmountBlur = async () => {
//     setIsEditing(false); // Disable editing mode
//     const updatedAmount = Number(editedAmount);

//     if (!isNaN(updatedAmount)) {
//       try {
//         const response = await fetch('http://localhost:5000/update-discrepancy', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [docId === 'stg_document' ? 'alignment_percentage' : filteredDiscrepancyKey]: updatedAmount,
//           }),
//         });

//         if (response.ok) {
//           console.log('Discrepancy updated successfully on the server.');

//           // Refetch the updated data from the server
//           await fetchPolicyStatus();

//           // Optionally, you can directly update the local state here as well to ensure immediate UI update:
//           setPolicyDiscrepancyAmounts((prev) => ({
//             ...prev,
//             [filteredDiscrepancyKey]: updatedAmount,
//           }));

//           onDiscrepancyAmountUpdate(updatedAmount, discrepancyIndex); // Update the parent component with new data
//         } else {
//           console.error('Failed to update discrepancy on the server.');
//         }
//       } catch (error) {
//         console.error('Error updating discrepancy:', error);
//       }
//     }
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Billing',
//     nonpayables: 'Nonpayables',
//     discrepancy_summary: 'Discrepancy Summary'
//   };

//   const toTitleCase = (str) => {
//     if (!str) return ''; // Null/undefined check
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   const formattedDocId = toTitleCase(docId);

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//     (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//   );
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex] || '';

//   // For nonpayables, use item_name and reason directly
//   const discrepancyText =
//     docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.item_name || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.reason || '';
//   const detailsText =
//     docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.reason || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.details || '';

//   const filteredValidationResults = validationResults[docId] || {};
//   const filteredResponseText = responseText[docId] || '';

//   if (
//     !hospitalName ||
//     !patientName ||
//     !primaryDiagnosis ||
//     !claimAmounts.initial_total_net_amount
//   ) {
//     return <p>Loading...</p>;
//   }

//   let claimedAmount = claimAmounts.initial_total_net_amount;
//   let estEligibleAmountDisplay =
//     estEligibleAmount !== null && estEligibleAmount !== undefined
//       ? estEligibleAmount
//       : claimAmounts.total_net_amount_after_copayment;
//   let estRecovery =
//     claimedAmount !== null &&
//     claimedAmount !== undefined &&
//     estEligibleAmountDisplay !== 'Loading...'
//       ? claimedAmount - estEligibleAmountDisplay
//       : 'Calculating...';

//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {formattedDocId}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>

//         {!isClaimDetailsPage && (
//           <div className="input-group">
//             <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>
//               Prev Discrepancy
//             </button>
//             <button className="secondary-button" onClick={handleNextDiscrepancy}>
//               Next Discrepancy
//             </button>
//           </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//           <div className="claim-item">
//             <div className="icon user-icon"></div>
//             <div>
//               <p className="claimant-name">{patientName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk: <span className="low-risk">Low</span>
//               </p>
//             </div>
//           </div>
//           <div className="claim-item">
//             <div className="icon hospital-icon"></div>
//             <div>
//               <p className="hospital-name">{hospitalName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk:{' '}
//                 <span className={isHospitalBlacklisted ? 'blacklisted-risk' : 'low-risk'}>
//                   {isHospitalBlacklisted ? 'Blacklisted' : 'Low'}
//                 </span>
//               </p>
//             </div>
//           </div>

//           <div className="claim-item">
//             <div className="icon diagnosis-icon"></div>
//             <div>
//               <p className="primary-diagnosis">{primaryDiagnosis}</p>
//             </div>
//           </div>
//         </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>TPA Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {claimedAmount !== null && claimedAmount !== undefined
//                 ? claimedAmount.toLocaleString()
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>ICE Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {estEligibleAmountDisplay !== null &&
//               estEligibleAmountDisplay !== undefined
//                 ? estEligibleAmountDisplay.toLocaleString()
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Recovery</p>
//             <p className="amount">
//               ₹{' '}
//               {estRecovery !== 'Calculating...'
//                 ? estRecovery.toLocaleString()
//                 : estRecovery}
//             </p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 <p><strong>Discrepancy :</strong> {discrepancyText}</p>
//                 <p>
//                   <strong>Details:</strong>
//                   <span
//                     dangerouslySetInnerHTML={{
//                       __html: marked(
//                         isExpanded
//                           ? detailsText
//                           : truncateText(detailsText, 200)
//                       ),
//                     }}
//                   />
//                   {!isExpanded && detailsText.length > 200 && (
//                     <span className="read-more" onClick={handleToggleExpand}>
//                       {' '}
//                       read more
//                     </span>
//                   )}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//             <div className="discrepancy-header">
//               <p>{docId === 'stg_document' ? 'Similarity Score' : 'Discrepancy Amount'}</p>
//               <img
//                 src={PencilIcon}
//                 alt="Edit Icon"
//                 className="edit-icon"
//                 onClick={handleEditClick} // Enable editing on click
//               />
//             </div>
//             <div className="editable-amount">
//               {isEditing ? (
//                 <input
//                   type="text"
//                   value={editedAmount}
//                   onChange={handleAmountChange}
//                   onBlur={handleAmountBlur} // Save the amount when input loses focus
//                   onKeyDown={handleKeyDown} // Listen for Enter key press
//                   autoFocus
//                 />
//               ) : (
//                 <p className="amount">
//                   {docId === 'stg_document'
//                     ? `${discrepancyAmount}%`
//                     : `₹ ${
//                         typeof discrepancyAmount === 'number'
//                           ? discrepancyAmount.toLocaleString()
//                           : 'Loading...'
//                       }`}
//                 </p>
//               )}
//               {docId !== 'stg_document' && (
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px' ,marginLeft: '6px'}}>
//                   {filteredDiscrepancyKey}
//                 </p>
//               )}
//             </div>

//             {/* Tag display and overruled text */}
//             {isPolicyValid && (
//               <>
//                 {overruledDiscrepancies[discrepancyIndex] && (
//                   <p
//                     className="overruled-text"
//                     style={{
//                       color: 'red',
//                       fontSize: '20px',
//                       fontWeight: 'bold',
//                       marginTop: '-5px',
//                     }}
//                   >
//                     (OVERRULED)
//                   </p>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;












// getting the tariff logic

// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import PencilIcon from '../icons/pencilicon.svg';
// import { marked } from 'marked';

// function Header({
//   docId,
//   estEligibleAmount,
//   discrepancyAmount,
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
//   currentDiscrepancyIndex,
//   showDisclaimer = true,
//   fetchResponseText = true,
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null,
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [nonpayablesData, setNonpayablesData] = useState([]);
//   const [tariffDiscrepancies, setTariffDiscrepancies] = useState([]); 
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(currentDiscrepancyIndex);
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedAmount, setEditedAmount] = useState('');

//   const location = useLocation();

//   useEffect(() => {
//     console.log('Fetching initial data...');
//     fetchClaimData();
//     fetchClaimAmounts();
//     if (fetchResponseText) {
//       fetchResponseTextData();
//     }
//     fetchPolicyStatus();
//   }, [docId, fetchResponseText]);

//   useEffect(() => {
//     console.log('Setting initial edited amount:', discrepancyAmount);
//     if (discrepancyAmount !== undefined && discrepancyAmount !== null) {
//       setEditedAmount(discrepancyAmount);
//     }
//   }, [discrepancyAmount]);

//   const fetchClaimData = async () => {
//     try {
//       console.log('Fetching claim data...');
//       const response = await fetch('http://127.0.0.1:5000/claim-data');
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       console.log('Claim data fetched:', data);

//       const hospitalDetails = data.hospital_details;
//       const patientDetails = data.patient_details;
//       const diagnosisDetails = data.admission_details;

//       if (hospitalDetails && hospitalDetails.name) {
//         setHospitalName(hospitalDetails.name);
//       }
//       if (patientDetails && patientDetails.name) {
//         setPatientName(patientDetails.name);
//       }
//       if (diagnosisDetails && diagnosisDetails.primary_diagnosis) {
//         setPrimaryDiagnosis(diagnosisDetails.primary_diagnosis);
//       }

//       setIsHospitalBlacklisted(data.blacklisted_hospital === 'True');
//     } catch (error) {
//       console.error('Error fetching the claim data', error);
//     }
//   };

//   const fetchClaimAmounts = async () => {
//     const url = 'http://127.0.0.1:5000/claim-amounts';
//     try {
//       console.log('Fetching claim amounts...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Claim amounts fetched:', data);
//         setClaimAmounts({
//           initial_total_net_amount: data.initial_total_net_amount,
//           total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//           total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//           total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//           total_exclusion_amount: data.total_exclusion_amount,
//           amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//           total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//           total_copayment: data.total_copayment,
//         });
//       } else {
//         console.error('Failed to fetch claim amounts', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async () => {
//     const url = 'http://localhost:5000/get_processed_data';
//     try {
//       console.log('Fetching response text data...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Response text data fetched:', data);

//         if (data.response_text) {
//           setResponseText(data.response_text);
//         }

//         if (data.validation_results) {
//           setValidationResults(data.validation_results);
//         }
//       } else {
//         console.error('Failed to fetch response text', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching response text:', error);
//     }
//   };

//   const fetchPolicyStatus = async () => {
//     const url = 'http://127.0.0.1:5000/policy-status';
//     try {
//       console.log('Fetching policy status...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Policy status fetched:', data);

//         if (docId === 'policy_wording_document' && data.policy_wording_document) {
//           setPolicyDiscrepancyAmounts(data.policy_wording_document);
//           setPolicyDiscrepancyInfo(data.policy_wording_document.information);
//           const initialDiscrepancyAmount = data.policy_wording_document[
//             Object.keys(data.policy_wording_document).filter((key) => key !== 'information')[discrepancyIndex]
//           ];
//           onDiscrepancyAmountUpdate(initialDiscrepancyAmount, discrepancyIndex);
//         } else if (docId === 'stg_document' && data.stg_document) {
//           const stgDiscrepancyAmount = data.stg_document.alignment_percentage;
//           onDiscrepancyAmountUpdate(stgDiscrepancyAmount, 0);
//         } else if (docId === 'policy_document' && data.policy_document) {
//           const policyDiscrepancyAmount = data.policy_document.some_property; // Make sure to replace with actual property
//           setPolicyDiscrepancyAmounts(data.policy_document);
//           setPolicyDiscrepancyInfo(data.policy_document.information);
//           onDiscrepancyAmountUpdate(policyDiscrepancyAmount, 0);
//         } else if (docId === 'nonpayables' && data.nonpayables) {
//           const nonpayables = data.nonpayables.categories.items_non_payable_but_billed_in_the_bill || [];
//           setNonpayablesData(nonpayables);

//           if (nonpayables.length > 0) {
//             const initialDiscrepancy = nonpayables[currentDiscrepancyIndex];
//             onDiscrepancyAmountUpdate(initialDiscrepancy.value, currentDiscrepancyIndex);
//           }
//         } else if (docId === 'tariff_document' && data.tariff) {
//           console.log('Handling tariff discrepancies...');
//           handleTariffDiscrepancies(data.tariff);
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleTariffDiscrepancies = (tariffData) => {
//     const discrepancies = [];

//     const processItem = (item, type) => {
//       const { billed_amount, price_range, justification, item_name } = item;
//       let discrepancyAmount = null;

//       console.log(`Processing item: ${item_name} | Type: ${type}`);

//       if (type === 'necessary' || type === 'non_compliant') {
//         if (
//           price_range &&
//           price_range.min !== null && price_range.max !== null &&
//           (billed_amount < price_range.min || billed_amount > price_range.max)
//         ) {
//           discrepancyAmount = Math.round((price_range.min + price_range.max) / 2);
//           console.log(`Discrepancy found for ${item_name}: ${discrepancyAmount}`);
//         }
//       } else if (type === 'unnecessary') {
//         discrepancyAmount = billed_amount;
//         console.log(`Unnecessary item found for ${item_name}: ${discrepancyAmount}`);
//       }

//       if (discrepancyAmount !== null) {
//         discrepancies.push({
//           item_name,
//           discrepancyAmount,
//           justification,
//           type,
//           billedAmount: billed_amount,
//         });
//       }
//     };

//     (tariffData.necessary_items || []).forEach((item) => processItem(item, 'necessary'));
//     (tariffData.non_compliant_items || []).forEach((item) => processItem(item, 'non_compliant'));
//     (tariffData.unnecessary_items || []).forEach((item) => processItem(item, 'unnecessary'));

//     console.log('Final tariff discrepancies:', discrepancies);
//     setTariffDiscrepancies(discrepancies);

//     if (discrepancies.length > 0) {
//       const initialDiscrepancy = discrepancies[currentDiscrepancyIndex];
//       onDiscrepancyAmountUpdate(initialDiscrepancy.discrepancyAmount, currentDiscrepancyIndex);
//     } else {
//       console.log('No discrepancies found in tariff document.');
//     }
//   };

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     console.log('Navigating to next discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const handlePrevDiscrepancy = () => {
//     console.log('Navigating to previous discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonpayablesData.length) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + tariffDiscrepancies.length) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAmountBlur();
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

//   const handleAmountChange = (e) => {
//     setEditedAmount(e.target.value);
//   };

//   const handleAmountBlur = async () => {
//     setIsEditing(false);
//     const updatedAmount = Number(editedAmount);

//     if (!isNaN(updatedAmount)) {
//       try {
//         const response = await fetch('http://localhost:5000/update-discrepancy', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [docId === 'stg_document' ? 'alignment_percentage' : filteredDiscrepancyKey]: updatedAmount,
//           }),
//         });

//         if (response.ok) {
//           console.log('Discrepancy updated successfully on the server.');
//           await fetchPolicyStatus();
//           setPolicyDiscrepancyAmounts((prev) => ({
//             ...prev,
//             [filteredDiscrepancyKey]: updatedAmount,
//           }));
//           onDiscrepancyAmountUpdate(updatedAmount, discrepancyIndex);
//         } else {
//           console.error('Failed to update discrepancy on the server.');
//         }
//       } catch (error) {
//         console.error('Error updating discrepancy:', error);
//       }
//     }
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Billing',
//     nonpayables: 'Nonpayables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   const formattedDocId = toTitleCase(docId);

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//     (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//   );
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex] || '';

//   const discrepancyText =
//     docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.item_name || ''
//       : docId === 'tariff_document'
//       ? tariffDiscrepancies[discrepancyIndex]?.item_name || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.reason || validationResults[docId]?.reason?.[0] || '';
//   const detailsText =
//     docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.reason || ''
//       : docId === 'tariff_document'
//       ? tariffDiscrepancies[discrepancyIndex]?.justification || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.details || responseText[docId]?.[0] || '';

//   const billedAmount = docId === 'tariff_document' && tariffDiscrepancies[discrepancyIndex] 
//     ? tariffDiscrepancies[discrepancyIndex].billedAmount 
//     : null;

//   if (
//     !hospitalName ||
//     !patientName ||
//     !primaryDiagnosis ||
//     !claimAmounts.initial_total_net_amount
//   ) {
//     return <p>Loading...</p>;
//   }

//   let claimedAmount = claimAmounts.initial_total_net_amount;
//   let estEligibleAmountDisplay =
//     estEligibleAmount !== null && estEligibleAmount !== undefined
//       ? estEligibleAmount
//       : claimAmounts.total_net_amount_after_copayment;
//   let estRecovery =
//     claimedAmount !== null &&
//     claimedAmount !== undefined &&
//     estEligibleAmountDisplay !== 'Loading...'
//       ? claimedAmount - estEligibleAmountDisplay
//       : 'Calculating...';

//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {formattedDocId}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>

//         {!isClaimDetailsPage && (
//           <div className="input-group">
//             <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>
//               Prev Discrepancy
//             </button>
//             <button className="secondary-button" onClick={handleNextDiscrepancy}>
//               Next Discrepancy
//             </button>
//           </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//           <div className="claim-item">
//             <div className="icon user-icon"></div>
//             <div>
//               <p className="claimant-name">{patientName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk: <span className="low-risk">Low</span>
//               </p>
//             </div>
//           </div>
//           <div className="claim-item">
//             <div className="icon hospital-icon"></div>
//             <div>
//               <p className="hospital-name">{hospitalName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk:{' '}
//                 <span className={isHospitalBlacklisted ? 'blacklisted-risk' : 'low-risk'}>
//                   {isHospitalBlacklisted ? 'Blacklisted' : 'Low'}
//                 </span>
//               </p>
//             </div>
//           </div>

//           <div className="claim-item">
//             <div className="icon diagnosis-icon"></div>
//             <div>
//               <p className="primary-diagnosis">{primaryDiagnosis}</p>
//             </div>
//           </div>
//         </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>TPA Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {claimedAmount !== null && claimedAmount !== undefined
//                 ? claimedAmount.toLocaleString()
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>ICE Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {estEligibleAmountDisplay !== null &&
//               estEligibleAmountDisplay !== undefined
//                 ? estEligibleAmountDisplay.toLocaleString()
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Recovery</p>
//             <p className="amount">
//               ₹{' '}
//               {estRecovery !== 'Calculating...'
//                 ? estRecovery.toLocaleString()
//                 : estRecovery}
//             </p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 <p><strong>Discrepancy :</strong> {discrepancyText}</p>
//                 <p>
//                   <strong>Details:</strong>
//                   <span
//                     dangerouslySetInnerHTML={{
//                       __html: marked(
//                         isExpanded
//                           ? detailsText
//                           : truncateText(detailsText, 200)
//                       ),
//                     }}
//                   />
//                   {!isExpanded && detailsText.length > 200 && (
//                     <span className="read-more" onClick={handleToggleExpand}>
//                       {' '}
//                       read more
//                     </span>
//                   )}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//             <div className="discrepancy-header">
//               <p>{docId === 'stg_document' ? 'Similarity Score' : 'Discrepancy Amount'}</p>
//               <img
//                 src={PencilIcon}
//                 alt="Edit Icon"
//                 className="edit-icon"
//                 onClick={handleEditClick}
//               />
//             </div>
//             <div className="editable-amount">
//               {isEditing ? (
//                 <input
//                   type="text"
//                   value={editedAmount}
//                   onChange={handleAmountChange}
//                   onBlur={handleAmountBlur}
//                   onKeyDown={handleKeyDown}
//                   autoFocus
//                 />
//               ) : (
//                 <p className="amount">
//                   {docId === 'stg_document'
//                     ? `${discrepancyAmount}%`
//                     : `₹ ${
//                         typeof discrepancyAmount === 'number'
//                           ? discrepancyAmount.toLocaleString()
//                           : 'Loading...'
//                       }`}
//                 </p>
//               )}
//               {docId === 'tariff_document' && billedAmount !== null && tariffDiscrepancies[discrepancyIndex].type !== 'unnecessary' && (
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//                   Billed Amount: ₹{billedAmount.toLocaleString()}
//                 </p>
//               )}
//               {docId !== 'stg_document' && (
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//                   {filteredDiscrepancyKey}
//                 </p>
//               )}
//             </div>

//             {isPolicyValid && (
//               <>
//                 {overruledDiscrepancies[discrepancyIndex] && (
//                   <p
//                     className="overruled-text"
//                     style={{
//                       color: 'red',
//                       fontSize: '20px',
//                       fontWeight: 'bold',
//                       marginTop: '-5px',
//                     }}
//                   >
//                     (OVERRULED)
//                   </p>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;







// Getting the final part of the header



// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import PencilIcon from '../icons/pencilicon.svg';
// import { marked } from 'marked';

// function Header({
//   docId,
//   estEligibleAmount,
//   discrepancyAmount,
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
//   currentDiscrepancyIndex,
//   showDisclaimer = true,
//   fetchResponseText = true,
//   iceAuthorizedAmount, // New prop for the ICE authorized amount
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null,
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [nonpayablesData, setNonpayablesData] = useState([]);
//   const [tariffDiscrepancies, setTariffDiscrepancies] = useState([]);
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(currentDiscrepancyIndex);
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedAmount, setEditedAmount] = useState('');

//   const location = useLocation();

//   useEffect(() => {
//     console.log('Fetching initial data...');
//     fetchClaimData();
//     fetchClaimAmounts();
//     if (fetchResponseText) {
//       fetchResponseTextData();
//     }
//     fetchPolicyStatus();
//   }, [docId, fetchResponseText]);

//   useEffect(() => {
//     console.log('Setting initial edited amount:', discrepancyAmount);
//     if (discrepancyAmount !== undefined && discrepancyAmount !== null) {
//       setEditedAmount(discrepancyAmount);
//     }
//   }, [discrepancyAmount]);

//   const fetchClaimData = async () => {
//     try {
//       console.log('Fetching claim data...');
//       const response = await fetch('http://127.0.0.1:5000/claim-data');
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       console.log('Claim data fetched:', data);

//       const hospitalDetails = data.hospital_details;
//       const patientDetails = data.patient_details;
//       const diagnosisDetails = data.admission_details;

//       if (hospitalDetails && hospitalDetails.name) {
//         setHospitalName(hospitalDetails.name);
//       }
//       if (patientDetails && patientDetails.name) {
//         setPatientName(patientDetails.name);
//       }
//       if (diagnosisDetails && diagnosisDetails.primary_diagnosis) {
//         setPrimaryDiagnosis(diagnosisDetails.primary_diagnosis);
//       }

//       setIsHospitalBlacklisted(data.blacklisted_hospital === 'True');
//     } catch (error) {
//       console.error('Error fetching the claim data', error);
//     }
//   };

//   const fetchClaimAmounts = async () => {
//     const url = 'http://127.0.0.1:5000/claim-amounts';
//     try {
//       console.log('Fetching claim amounts...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Claim amounts fetched:', data);
//         setClaimAmounts({
//           initial_total_net_amount: data.initial_total_net_amount,
//           total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//           total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//           total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//           total_exclusion_amount: data.total_exclusion_amount,
//           amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//           total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//           total_copayment: data.total_copayment,
//         });
//       } else {
//         console.error('Failed to fetch claim amounts', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async () => {
//     const url = 'http://localhost:5000/get_processed_data';
//     try {
//       console.log('Fetching response text data...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Response text data fetched:', data);

//         if (data.response_text) {
//           setResponseText(data.response_text);
//         }

//         if (data.validation_results) {
//           setValidationResults(data.validation_results);
//         }
//       } else {
//         console.error('Failed to fetch response text', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching response text:', error);
//     }
//   };

//   const fetchPolicyStatus = async () => {
//     const url = 'http://127.0.0.1:5000/policy-status';
//     try {
//       console.log('Fetching policy status...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Policy status fetched:', data);

//         if (docId === 'policy_wording_document' && data.policy_wording_document) {
//           setPolicyDiscrepancyAmounts(data.policy_wording_document);
//           setPolicyDiscrepancyInfo(data.policy_wording_document.information);
//           const initialDiscrepancyAmount = data.policy_wording_document[
//             Object.keys(data.policy_wording_document).filter((key) => key !== 'information')[discrepancyIndex]
//           ];
//           onDiscrepancyAmountUpdate(initialDiscrepancyAmount, discrepancyIndex);
//         } else if (docId === 'stg_document' && data.stg_document) {
//           const stgDiscrepancyAmount = data.stg_document.alignment_percentage;
//           onDiscrepancyAmountUpdate(stgDiscrepancyAmount, 0);
//         } else if (docId === 'policy_document' && data.policy_document) {
//           const policyDiscrepancyAmount = data.policy_document.some_property; // Make sure to replace with actual property
//           setPolicyDiscrepancyAmounts(data.policy_document);
//           setPolicyDiscrepancyInfo(data.policy_document.information);
//           onDiscrepancyAmountUpdate(policyDiscrepancyAmount, 0);
//         } else if (docId === 'nonpayables' && data.nonpayables) {
//           const nonpayables = data.nonpayables.categories.items_non_payable_but_billed_in_the_bill || [];
//           setNonpayablesData(nonpayables);

//           if (nonpayables.length > 0) {
//             const initialDiscrepancy = nonpayables[currentDiscrepancyIndex];
//             onDiscrepancyAmountUpdate(initialDiscrepancy.value, currentDiscrepancyIndex);
//           }
//         } else if (docId === 'tariff_document' && data.tariff) {
//           console.log('Handling tariff discrepancies...');
//           handleTariffDiscrepancies(data.tariff);
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleTariffDiscrepancies = (tariffData) => {
//     const discrepancies = [];

//     const processItem = (item, type) => {
//       const { billed_amount, price_range, justification, item_name } = item;
//       let discrepancyAmount = null;

//       console.log(`Processing item: ${item_name} | Type: ${type}`);

//       if (type === 'necessary' || type === 'non_compliant') {
//         if (
//           price_range &&
//           price_range.min !== null && price_range.max !== null &&
//           (billed_amount < price_range.min || billed_amount > price_range.max)
//         ) {
//           // discrepancyAmount = Math.round((price_range.min + price_range.max) / 2);
//           discrepancyAmount = billed_amount - Math.round((price_range.min + price_range.max) / 2);

//           console.log(`Discrepancy found for ${item_name}: ${discrepancyAmount}`);
//         }
//       } else if (type === 'unnecessary') {
//         discrepancyAmount = billed_amount;
//         console.log(`Unnecessary item found for ${item_name}: ${discrepancyAmount}`);
//       }

//       if (discrepancyAmount !== null) {
//         discrepancies.push({
//           item_name,
//           discrepancyAmount,
//           justification,
//           type,
//           billedAmount: billed_amount,
//         });
//       }
//     };

//     (tariffData.necessary_items || []).forEach((item) => processItem(item, 'necessary'));
//     (tariffData.non_compliant_items || []).forEach((item) => processItem(item, 'non_compliant'));
//     (tariffData.unnecessary_items || []).forEach((item) => processItem(item, 'unnecessary'));

//     console.log('Final tariff discrepancies:', discrepancies);
//     setTariffDiscrepancies(discrepancies);

//     if (discrepancies.length > 0) {
//       const initialDiscrepancy = discrepancies[currentDiscrepancyIndex];
//       onDiscrepancyAmountUpdate(initialDiscrepancy.discrepancyAmount, currentDiscrepancyIndex);
//     } else {
//       console.log('No discrepancies found in tariff document.');
//     }
//   };

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     console.log('Navigating to next discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const handlePrevDiscrepancy = () => {
//     console.log('Navigating to previous discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonpayablesData.length) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + tariffDiscrepancies.length) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAmountBlur();
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

//   const handleAmountChange = (e) => {
//     setEditedAmount(e.target.value);
//   };

//   const handleAmountBlur = async () => {
//     setIsEditing(false);
//     const updatedAmount = Number(editedAmount);

//     if (!isNaN(updatedAmount)) {
//         let payload = {};

//         if (docId === 'stg_document') {
//             payload = { alignment_percentage: updatedAmount };
//         } else if (docId === 'nonpayables') {
//             const itemName = nonpayablesData[discrepancyIndex]?.item_name;
//             if (itemName) {
//                 payload = {
//                     items_non_payable_but_billed_in_the_bill: [
//                         { item_name: itemName, value: updatedAmount }
//                     ]
//                 };
//             }
//         } else if (docId === 'tariff_document') {
//             const itemName = tariffDiscrepancies[discrepancyIndex]?.item_name;
//             if (itemName) {
//                 payload = {
//                     necessary_items: [
//                         { item_name: itemName, price: updatedAmount }
//                     ]
//                 };
//             }
//         } else {
//             payload = { [filteredDiscrepancyKey]: updatedAmount };
//         }

//         try {
//             const response = await fetch('http://localhost:5000/update-discrepancy', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(payload),
//             });

//             if (response.ok) {
//                 console.log('Discrepancy updated successfully on the server.');
//                 await fetchPolicyStatus(); // Refresh the policy status to reflect the updated data
//                 setPolicyDiscrepancyAmounts((prev) => ({
//                     ...prev,
//                     [filteredDiscrepancyKey]: updatedAmount,
//                 }));
//                 onDiscrepancyAmountUpdate(updatedAmount, discrepancyIndex);
//             } else {
//                 console.error('Failed to update discrepancy on the server.');
//                 const errorText = await response.text();
//                 console.error('Server error message:', errorText);
//             }
//         } catch (error) {
//             console.error('Error updating discrepancy:', error);
//         }
//     }
// };


//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Tariff',
//     nonpayables: 'Nonpayables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   const formattedDocId = toTitleCase(docId);

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//     (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//   );
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex] || '';

//   const discrepancyText =
//     docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.item_name || ''
//       : docId === 'tariff_document'
//       ? tariffDiscrepancies[discrepancyIndex]?.item_name || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.reason || validationResults[docId]?.reason?.[0] || '';
//   const detailsText =
//     docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.reason || ''
//       : docId === 'tariff_document'
//       ? tariffDiscrepancies[discrepancyIndex]?.justification || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.details || responseText[docId]?.[0] || '';

//   const billedAmount = docId === 'tariff_document' && tariffDiscrepancies[discrepancyIndex]
//     ? tariffDiscrepancies[discrepancyIndex].billedAmount
//     : null;

//   if (
//     !hospitalName ||
//     !patientName ||
//     !primaryDiagnosis ||
//     !claimAmounts.initial_total_net_amount
//   ) {
//     return <p>Loading...</p>;
//   }

//   let claimedAmount = claimAmounts.initial_total_net_amount;
//   let estEligibleAmountDisplay = iceAuthorizedAmount || 'Loading...';
//   let estRecovery =
//     claimedAmount !== null &&
//     claimedAmount !== undefined &&
//     estEligibleAmountDisplay !== 'Loading...'
//       ? claimedAmount - estEligibleAmountDisplay
//       : 'Calculating...';

//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {formattedDocId}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>

//         {!isClaimDetailsPage && (
//           <div className="input-group">
//             <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>
//               Prev Discrepancy
//             </button>
//             <button className="secondary-button" onClick={handleNextDiscrepancy}>
//               Next Discrepancy
//             </button>
//           </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//           <div className="claim-item">
//             <div className="icon user-icon"></div>
//             <div>
//               <p className="claimant-name">{patientName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk: <span className="low-risk">Low</span>
//               </p>
//             </div>
//           </div>
//           <div className="claim-item">
//             <div className="icon hospital-icon"></div>
//             <div>
//               <p className="hospital-name">{hospitalName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk:{' '}
//                 <span className={isHospitalBlacklisted ? 'blacklisted-risk' : 'low-risk'}>
//                   {isHospitalBlacklisted ? 'Blacklisted' : 'Low'}
//                 </span>
//               </p>
//             </div>
//           </div>

//           <div className="claim-item">
//             <div className="icon diagnosis-icon"></div>
//             <div>
//               <p className="primary-diagnosis">{primaryDiagnosis}</p>
//             </div>
//           </div>
//         </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>TPA Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {claimedAmount !== null && claimedAmount !== undefined
//                 ? claimedAmount.toLocaleString()
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>ICE Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {estEligibleAmountDisplay !== null &&
//                 estEligibleAmountDisplay !== undefined
//                 ? estEligibleAmountDisplay.toLocaleString()
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Recovery</p>
//             <p className="amount">
//               ₹{' '}
//               {estRecovery !== 'Calculating...'
//                 ? estRecovery.toLocaleString()
//                 : estRecovery}
//             </p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 <p><strong>Discrepancy :</strong> {discrepancyText}</p>
//                 <p>
//                   <strong>Details:</strong>
//                   <span
//                     dangerouslySetInnerHTML={{
//                       __html: marked(
//                         isExpanded
//                           ? detailsText
//                           : truncateText(detailsText, 200)
//                       ),
//                     }}
//                   />
//                   {!isExpanded && detailsText.length > 200 && (
//                     <span className="read-more" onClick={handleToggleExpand}>
//                       {' '}
//                       read more
//                     </span>
//                   )}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//             <div className="discrepancy-header">
//               <p>{docId === 'stg_document' ? 'Similarity Score' : 'Discrepancy Amount'}</p>
//               <img
//                 src={PencilIcon}
//                 alt="Edit Icon"
//                 className="edit-icon"
//                 onClick={handleEditClick}
//               />
//             </div>
//             <div className="editable-amount">
//               {isEditing ? (
//                 <input
//                   type="text"
//                   value={editedAmount}
//                   onChange={handleAmountChange}
//                   onBlur={handleAmountBlur}
//                   onKeyDown={handleKeyDown}
//                   autoFocus
//                 />
//               ) : (
//                 <p className="amount">
//                   {docId === 'stg_document'
//                     ? `${discrepancyAmount}%`
//                     : `₹ ${
//                         typeof discrepancyAmount === 'number'
//                           ? discrepancyAmount.toLocaleString()
//                           : 'Loading...'
//                       }`}
//                 </p>
//               )}
//               {docId === 'tariff_document' && billedAmount !== null && tariffDiscrepancies[discrepancyIndex].type !== 'unnecessary' && (
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//                   Billed Amount: ₹{billedAmount.toLocaleString()}
//                 </p>
//               )}
//               {docId !== 'stg_document' && (
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//                   {filteredDiscrepancyKey}
//                 </p>
//               )}
//             </div>

//             {isPolicyValid && (
//               <>
//                 {overruledDiscrepancies[discrepancyIndex] && (
//                   <p
//                     className="overruled-text"
//                     style={{
//                       color: 'red',
//                       fontSize: '20px',
//                       fontWeight: 'bold',
//                       marginTop: '-5px',
//                     }}
//                   >
//                     (OVERRULED)
//                   </p>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;






//testing


// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import PencilIcon from '../icons/pencilicon.svg';
// import { marked } from 'marked';
// import { API_ENDPOINTS } from '../config';
// function Header({
//   docId,
//   estEligibleAmount,
//   discrepancyAmount,
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
//   currentDiscrepancyIndex,
//   showDisclaimer = true,
//   fetchResponseText = true,
//   iceAuthorizedAmount,
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null,
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [nonpayablesData, setNonpayablesData] = useState([]);
//   const [tariffDiscrepancies, setTariffDiscrepancies] = useState([]);
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(currentDiscrepancyIndex);
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedAmount, setEditedAmount] = useState('');
//   const [tpa, setTpa] = useState(null);  // State to store the fetched TPA amount
//   const location = useLocation();

//   useEffect(() => {
//     console.log('Fetching initial data...');
//     fetchClaimData();
//     fetchClaimAmounts();
//     if (fetchResponseText) {
//       fetchResponseTextData();
//     }
//     fetchPolicyStatus();
//   }, [docId, fetchResponseText]);

//   //fetch tpa amount
//   useEffect(() => {
//     // Function to fetch the TPA amount from the API
//     const fetchTpaAmount = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/fetch_tpa_amount`);
//         const data = await response.json();

//         // Set the TPA amount with the value from the response
//         setTpa(data.final_settlement_amount);
//       } catch (error) {
//         console.error('Error fetching the TPA amount:', error);
//       }
//     };

//     // Call the fetch function when the component mounts
//     fetchTpaAmount();
//   }, []); // Empty dependency array means this useEffect runs once when the component mounts


//   useEffect(() => {
//     console.log('Setting initial edited amount:', discrepancyAmount);
//     if (discrepancyAmount !== undefined && discrepancyAmount !== null) {
//       setEditedAmount(discrepancyAmount);
//     }
//   }, [discrepancyAmount]);

//   const fetchClaimData = async () => {
//     try {
//       console.log('Fetching claim data...');
//       const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/claim-data`);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       console.log('Claim data fetched:', data);

//       const hospitalDetails = data.hospital_details;
//       const patientDetails = data.patient_details;
//       const diagnosisDetails = data.admission_details;

//       if (hospitalDetails && hospitalDetails.name) {
//         setHospitalName(hospitalDetails.name);
//       }
//       if (patientDetails && patientDetails.name) {
//         setPatientName(patientDetails.name);
//       }
//       if (diagnosisDetails && diagnosisDetails.primary_diagnosis) {
//         setPrimaryDiagnosis(diagnosisDetails.primary_diagnosis);
//       }

//       setIsHospitalBlacklisted(data.blacklisted_hospital === 'True');
//     } catch (error) {
//       console.error('Error fetching the claim data', error);
//     }
//   };

//   const fetchClaimAmounts = async () => {
//     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/claim-amounts`;
//     try {
//       console.log('Fetching claim amounts...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Claim amounts fetched:', data);
//         setClaimAmounts({
//           initial_total_net_amount: data.initial_total_net_amount,
//           total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//           total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//           total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//           total_exclusion_amount: data.total_exclusion_amount,
//           amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//           total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//           total_copayment: data.total_copayment,
//         });
//       } else {
//         console.error('Failed to fetch claim amounts', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async () => {
//     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
//     try {
//       console.log('Fetching response text data...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Response text data fetched:', data);

//         if (data.response_text) {
//           setResponseText(data.response_text);
//         }

//         if (data.validation_results) {
//           setValidationResults(data.validation_results);
//         }
//       } else {
//         console.error('Failed to fetch response text', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching response text:', error);
//     }
//   };

//   const fetchPolicyStatus = async () => {
//     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
//     try {
//       console.log('Fetching policy status...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Policy status fetched:', data);

//         if (docId === 'discrepancy_summary') {
//           // For discrepancy_summary page
//           setPolicyDiscrepancyAmounts({
//             policy_wording_document: data.total_policy_wording_document_amount,
//             nonpayables: data.nonpayables.categories.total_nonpayable_amount,
//             tariff: data.tariff.total_tariff_discrepancy_amount,
//           });

//           setPolicyDiscrepancyInfo({
//             policy_wording_document: data.policy_wording_document.information,
//             nonpayables: data.nonpayables.categories.items_non_payable_but_billed_in_the_bill,
//             tariff: {
//               necessary_items: data.tariff.necessary_items,
//               non_compliant_items: data.tariff.non_compliant_items,
//               unnecessary_items: data.tariff.unnecessary_items,
//             },
//           });

//           const initialDiscrepancyAmount = data.total_policy_wording_document_amount;
//           onDiscrepancyAmountUpdate(initialDiscrepancyAmount, 0);
//         } else {
//           // Original logic for other docIds
//           if (docId === 'policy_wording_document' && data.policy_wording_document) {
//             setPolicyDiscrepancyAmounts(data.policy_wording_document);
//             setPolicyDiscrepancyInfo(data.policy_wording_document.information);
//             const initialDiscrepancyAmount = data.policy_wording_document[
//               Object.keys(data.policy_wording_document).filter((key) => key !== 'information')[discrepancyIndex]
//             ];
//             onDiscrepancyAmountUpdate(initialDiscrepancyAmount, discrepancyIndex);
//           } else if (docId === 'stg_document' && data.stg_document) {
//             const stgDiscrepancyAmount = data.stg_document.alignment_percentage;
//             onDiscrepancyAmountUpdate(stgDiscrepancyAmount, 0);
//           } else if (docId === 'policy_document' && data.policy_document) {
//             const policyDiscrepancyAmount = data.policy_document.some_property; // Make sure to replace with actual property
//             setPolicyDiscrepancyAmounts(data.policy_document);
//             setPolicyDiscrepancyInfo(data.policy_document.information);
//             onDiscrepancyAmountUpdate(policyDiscrepancyAmount, 0);
//           }else if (docId === 'nonpayables' && data.nonpayables) {
//             const nonpayables = data.nonpayables.categories.items_non_payable_but_billed_in_the_bill || [];
          
//             // Filter out items with a value of 0.0
//             const filteredNonpayables = nonpayables.filter((item) => item.value !== 0);
          
//             setNonpayablesData(filteredNonpayables);
          
//             if (filteredNonpayables.length > 0) {
//               const initialDiscrepancy = filteredNonpayables[currentDiscrepancyIndex];
//               onDiscrepancyAmountUpdate(initialDiscrepancy.value, currentDiscrepancyIndex);
//             }
//           }
//            else if (docId === 'tariff_document' && data.tariff) {
//             console.log('Handling tariff discrepancies...');
//             handleTariffDiscrepancies(data.tariff);
//           }
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleTariffDiscrepancies = (tariffData) => {
//     const discrepancies = [];

//     const processItem = (item, type) => {
//       const { billed_amount, price_range, justification, item_name } = item;
//       let discrepancyAmount = null;

//       console.log(`Processing item: ${item_name} | Type: ${type}`);

//       if (type === 'necessary' || type === 'non_compliant') {
//         if (
//           price_range &&
//           price_range.min !== null && price_range.max !== null &&
//           (billed_amount < price_range.min || billed_amount > price_range.max)
//         ) {
//           discrepancyAmount = billed_amount - Math.round((price_range.min + price_range.max) / 2);

//           console.log(`Discrepancy found for ${item_name}: ${discrepancyAmount}`);
//         }
//       } else if (type === 'unnecessary') {
//         discrepancyAmount = billed_amount;
//         console.log(`Unnecessary item found for ${item_name}: ${discrepancyAmount}`);
//       }

//       if (discrepancyAmount !== null) {
//         discrepancies.push({
//           item_name,
//           discrepancyAmount,
//           justification,
//           type,
//           billedAmount: billed_amount,
//         });
//       }
//     };

//     (tariffData.necessary_items || []).forEach((item) => processItem(item, 'necessary'));
//     (tariffData.non_compliant_items || []).forEach((item) => processItem(item, 'non_compliant'));
//     (tariffData.unnecessary_items || []).forEach((item) => processItem(item, 'unnecessary'));

//     console.log('Final tariff discrepancies:', discrepancies);
//     setTariffDiscrepancies(discrepancies);

//     if (discrepancies.length > 0) {
//       const initialDiscrepancy = discrepancies[currentDiscrepancyIndex];
//       onDiscrepancyAmountUpdate(initialDiscrepancy.discrepancyAmount, currentDiscrepancyIndex);
//     } else {
//       console.log('No discrepancies found in tariff document.');
//     }
//   };

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     console.log('Navigating to next discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const handlePrevDiscrepancy = () => {
//     console.log('Navigating to previous discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonpayablesData.length) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + tariffDiscrepancies.length) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAmountBlur();
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

//   const handleAmountChange = (e) => {
//     setEditedAmount(e.target.value);
//   };

//   const handleAmountBlur = async () => {
//     setIsEditing(false);
//     const updatedAmount = Number(editedAmount);

//     if (!isNaN(updatedAmount)) {
//         let payload = {};

//         if (docId === 'stg_document') {
//             payload = { alignment_percentage: updatedAmount };
//         } else if (docId === 'nonpayables') {
//             const itemName = nonpayablesData[discrepancyIndex]?.item_name;
//             if (itemName) {
//                 payload = {
//                     items_non_payable_but_billed_in_the_bill: [
//                         { item_name: itemName, value: updatedAmount }
//                     ]
//                 };
//             }
//         } else if (docId === 'tariff_document') {
//             const itemName = tariffDiscrepancies[discrepancyIndex]?.item_name;
//             if (itemName) {
//                 payload = {
//                     necessary_items: [
//                         { item_name: itemName, price: updatedAmount }
//                     ]
//                 };
//             }
//         } else {
//             payload = { [filteredDiscrepancyKey]: updatedAmount };
//         }

//         try {
//             const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(payload),
//             });

//             if (response.ok) {
//                 console.log('Discrepancy updated successfully on the server.');
//                 await fetchPolicyStatus(); // Refresh the policy status to reflect the updated data
//                 setPolicyDiscrepancyAmounts((prev) => ({
//                     ...prev,
//                     [filteredDiscrepancyKey]: updatedAmount,
//                 }));
//                 onDiscrepancyAmountUpdate(updatedAmount, discrepancyIndex);
//             } else {
//                 console.error('Failed to update discrepancy on the server.');
//                 const errorText = await response.text();
//                 console.error('Server error message:', errorText);
//             }
//         } catch (error) {
//             console.error('Error updating discrepancy:', error);
//         }
//     }
// };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Tariff',
//     nonpayables: 'Non Payables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   const formattedDocId = toTitleCase(docId);

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//     (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//   );
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex] || '';

//   const discrepancyText =
//   docId === 'discrepancy_summary'
//     ? filteredDiscrepancyKey
//     : docId === 'nonpayables'
//     ? nonpayablesData[discrepancyIndex]?.item_name || ''
//     : docId === 'tariff_document'
//     ? tariffDiscrepancies[discrepancyIndex]?.item_name || ''
//     : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.reason || validationResults[docId]?.reason?.[0] || '';

//     const detailsText =
//     docId === 'discrepancy_summary'
//       ? policyDiscrepancyInfo[filteredDiscrepancyKey]
//         ? filteredDiscrepancyKey === 'policy_wording_document'
//           ? Object.entries(policyDiscrepancyInfo[filteredDiscrepancyKey] || {})
//               .map(([key, value]) => `<strong>${toTitleCase(key)}:</strong> ${value.details}`)
//               .join('<br><br>')
//           : filteredDiscrepancyKey === 'nonpayables'
//           ? (policyDiscrepancyInfo[filteredDiscrepancyKey] || [])
//               .map((item) => `<strong>${item.item_name}:</strong> ${item.reason}`)
//               .join('<br><br>')
//           : filteredDiscrepancyKey === 'tariff'
//           ? [
//               ...(policyDiscrepancyInfo[filteredDiscrepancyKey]?.necessary_items || []),
//               ...(policyDiscrepancyInfo[filteredDiscrepancyKey]?.non_compliant_items || []),
//               ...(policyDiscrepancyInfo[filteredDiscrepancyKey]?.unnecessary_items || []),
//             ]
//               .map((item) => `<strong>${item.item_name}:</strong> ${item.justification}`)
//               .join('<br><br>')
//           : ''
//         : ''
//       : docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.reason || ''
//       : docId === 'tariff_document'
//       ? tariffDiscrepancies[discrepancyIndex]?.justification || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.details || responseText[docId]?.[0] || '';
  
//   const billedAmount = docId === 'tariff_document' && tariffDiscrepancies[discrepancyIndex]
//     ? tariffDiscrepancies[discrepancyIndex].billedAmount
//     : null;

//   if (
//     !hospitalName ||
//     !patientName ||
//     !primaryDiagnosis ||
//     !claimAmounts.initial_total_net_amount
//   ) {
//     return <p>Loading...</p>;
//   }

//     // let claimedAmount = claimAmounts.initial_total_net_amount;

// let claimedAmount = tpa ;
//   let estEligibleAmountDisplay = iceAuthorizedAmount || 'Loading...';
//   let estRecovery =
//     claimedAmount !== null &&
//     claimedAmount !== undefined &&
//     estEligibleAmountDisplay !== 'Loading...'
//       ? claimedAmount - estEligibleAmountDisplay
//       : 'Calculating...';

//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {formattedDocId}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>

//         {!isClaimDetailsPage && (
//           <div className="input-group">
//             <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>
//               Prev Discrepancy
//             </button>
//             <button className="secondary-button" onClick={handleNextDiscrepancy}>
//               Next Discrepancy
//             </button>
//           </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//           <div className="claim-item">
//             <div className="icon user-icon"></div>
//             <div>
//               <p className="claimant-name">{patientName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk: <span className="low-risk">Low</span>
//               </p>
//             </div>
//           </div>
//           <div className="claim-item">
//             <div className="icon hospital-icon"></div>
//             <div>
//               <p className="hospital-name">{hospitalName}</p>
//               <p className="fraud-risk">
//                 Fraud Risk:{' '}
//                 <span className={isHospitalBlacklisted ? 'blacklisted-risk' : 'low-risk'}>
//                   {isHospitalBlacklisted ? 'Blacklisted' : 'Low'}
//                 </span>
//               </p>
//             </div>
//           </div>

//           <div className="claim-item">
//             <div className="icon diagnosis-icon"></div>
//             <div>
//               <p className="primary-diagnosis">{primaryDiagnosis}</p>
//             </div>
//           </div>
//         </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>TPA Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {tpa !== null && tpa !== undefined
//                 ? Number(tpa).toLocaleString('en-IN')
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>ICE Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {estEligibleAmountDisplay !== null && estEligibleAmountDisplay !== undefined && !isNaN(estEligibleAmountDisplay)
//                 ? Number(estEligibleAmountDisplay).toLocaleString('en-IN')
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Recovery</p>
//             <p className="amount">
//               ₹{' '}
//               {estRecovery !== 'Calculating...' && estRecovery !== null && estRecovery !== undefined && !isNaN(estRecovery)
//                 ? Number(estRecovery).toLocaleString('en-IN')
//                 : estRecovery}
//             </p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 <p><strong>Discrepancy :</strong> {discrepancyText}</p>
//                 <p>
//                   <strong>Details:  </strong>
//                   <span
//                     dangerouslySetInnerHTML={{
//                       __html: marked(
//                         isExpanded
//                           ? detailsText
//                           : truncateText(detailsText, 200)
//                       ),
//                     }}
//                   />
//                   {!isExpanded && detailsText.length > 200 && (
//                     <span className="read-more" onClick={handleToggleExpand}>
//                       {' '}
//                       read more
//                     </span>
//                   )}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//   <div className="discrepancy-header">
//     <p>{docId === 'stg_document' ? 'Similarity Score' : 'Discrepancy Amount'}</p>
//   </div>
//   <div className="editable-amount">
//     {isEditing ? (
//       <input
//         type="text"
//         value={editedAmount}
//         onChange={handleAmountChange}
//         onBlur={handleAmountBlur}
//         onKeyDown={handleKeyDown}
//         autoFocus
//       />
//     ) : (
//       <div className="amount-container">
//         <p className="amount">
//           {docId === 'stg_document'
//             ? `${discrepancyAmount}%`
//             : `₹ ${
//                 typeof discrepancyAmount === 'number'
//                   ? discrepancyAmount.toLocaleString()
//                   : 'Loading...'
//               }`}
//         </p>
//         <img
//           src={PencilIcon}
//           alt="Edit Icon"
//           className="edit-icon-inline"
//           onClick={handleEditClick}
//         />
//       </div>
//     )}
//       {docId === 'tariff_document' && billedAmount !== null && billedAmount !== undefined && !isNaN(billedAmount) && tariffDiscrepancies[discrepancyIndex].type !== 'unnecessary' && (
//         <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//           Billed Amount: ₹{Number(billedAmount).toLocaleString('en-IN')}
//         </p>
//       )}
//       {docId !== 'stg_document' && (
//         <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//           {filteredDiscrepancyKey}
//         </p>
//       )}

//   </div>

//   {isPolicyValid && (
//     <>
//       {overruledDiscrepancies[discrepancyIndex] && (
//         <p
//           className="overruled-text"
//           style={{
//             color: 'red',
//             fontSize: '20px',
//             fontWeight: 'bold',
//             marginTop: '-5px',
//           }}
//         >
//           (OVERRULED)
//         </p>
//       )}
//     </>
//   )}
// </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;

















// *************************** */


// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import PencilIcon from '../icons/pencilicon.svg';
// import { marked } from 'marked';
// import { API_ENDPOINTS } from '../config';
// import InfoDiscrepancyIcon from '../icons/information.svg';
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Import the icons
// function Header({
//   docId,
//   estEligibleAmount,
//   discrepancyAmount,
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
  // currentDiscrepancyIndex,
//   currentDiscrepancyKey,
//   showDisclaimer = true,
//   fetchResponseText = true,
//   iceAuthorizedAmount,
//   patientId, // Add patientId prop
//   claimId,   // Add claimId prop
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null,
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [nonpayablesData, setNonpayablesData] = useState([]);
//   const [tariffDiscrepancies, setTariffDiscrepancies] = useState([]);
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(currentDiscrepancyIndex);
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedAmount, setEditedAmount] = useState('');
//   const [tpa, setTpa] = useState(null);  // State to store the fetched TPA amount
//   const location = useLocation();
//   // const [estRecovery, setEstRecovery] = useState('Calculating...');



//   // const calculateEstRecovery = async () => {
//   //   await fetchAndCalculateEstRecovery(); // Fetch and calculate the recovery value
//   // };
  
// // const fetchAndCalculateEstRecovery = async () => {
// //   const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
// //   try {
// //     console.log('Fetching totals for estRecovery...');
// //     const response = await fetch(url, {
// //       method: 'GET',
// //       headers: {
// //         'Content-Type': 'application/json; charset=UTF-8',
// //       },
// //     });

// //     if (response.ok) {
// //       const data = await response.json();

// //       // Extract totals for missed and mispriced deductions
// //       const missedDeductions = data.Totals.deductions_missed_by_tpa || 0;
// //       const mispricedDeductions = data.Totals.deductions_mispriced_by_tpa || 0;

// //       // Calculate and store estRecovery in state
// //       const recovery = missedDeductions + mispricedDeductions;
// //       console.log('Estimated Recovery:', recovery);

// //       setEstRecovery(recovery); // Store the recovery value in state
// //     } else {
// //       console.error('Failed to fetch totals for estRecovery', await response.text());
// //       setEstRecovery('Error calculating recovery');
// //     }
// //   } catch (error) {
// //     console.error('Error fetching totals for estRecovery:', error);
// //     setEstRecovery('Error calculating recovery');
// //   }
// // };

    

//   // useEffect(() => {
//   //   console.log('Fetching initial data...');
//   //   fetchClaimData();
//   //   fetchClaimAmounts();
//   //   // calculateEstRecovery();
//   //   if (fetchResponseText) {
//   //     fetchResponseTextData();
//   //   }
//   //   fetchPolicyStatus();
//   // }, [docId, fetchResponseText]);

//   useEffect(() => {

//     // console.log('fetched claim and patientid' ,{patientId})
//     if (!patientId || !claimId) {
//         console.error('Missing patientId or claimId');
//         return;
//     }

//     // Fetch all necessary data for the header
//     console.log('Fetching initial data...');
//     fetchClaimData(patientId, claimId);
//     fetchClaimAmounts(patientId, claimId);
//     fetchTpaAmount(patientId, claimId);

//     if (fetchResponseText) {
//       fetchResponseTextData(patientId, claimId);
//     }
//     fetchPolicyStatus(patientId, claimId);
//   }, [docId, fetchResponseText, patientId, claimId]);
//   //fetch tpa amount
//   useEffect(() => {
//     // Function to fetch the TPA amount from the API
//     const fetchTpaAmount = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/fetch_tpa_amount`);
//         const data = await response.json();

//         // Set the TPA amount with the value from the response
//         setTpa(data.final_settlement_amount);
//       } catch (error) {
//         console.error('Error fetching the TPA amount:', error);
//       }
//     };

//     // Call the fetch function when the component mounts
//     fetchTpaAmount();
//   }, []); // Empty dependency array means this useEffect runs once when the component mounts


//   useEffect(() => {
//     console.log('Setting initial edited amount:', discrepancyAmount);
//     if (discrepancyAmount !== undefined && discrepancyAmount !== null) {
//       setEditedAmount(discrepancyAmount);
//     }
//   }, [discrepancyAmount]);

//   const fetchClaimData = async (patientId, claimId) => {
//     try {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/claim-data?patient_id=${patientId}&claim_id=${claimId}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error('Failed to fetch claim data');
//       }

//       const data = await response.json();
//       const { hospital_details, patient_details, admission_details, blacklisted_hospital } = data;

//       setHospitalName(hospital_details?.name || null);
//       setPatientName(patient_details?.name || null);
//       setPrimaryDiagnosis(admission_details?.primary_diagnosis || null);
//       setIsHospitalBlacklisted(blacklisted_hospital === 'True');
//     } catch (error) {
//       console.error('Error fetching claim data:', error);
//     }
//   };

//   const fetchClaimAmounts = async (patientId, claimId) => {
//     try {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/claim-amounts?patient_id=${patientId}&claim_id=${claimId}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error('Failed to fetch claim amounts');
//       }

//       const data = await response.json();
//       setClaimAmounts({
//         initial_total_net_amount: data.initial_total_net_amount,
//         total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//         total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//         total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//         total_exclusion_amount: data.total_exclusion_amount,
//         amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//         total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//         total_copayment: data.total_copayment,
//       });
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async (patientId, claimId) => {
//     try {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data?patient_id=${patientId}&claim_id=${claimId}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error('Failed to fetch response text data');
//       }

//       const data = await response.json();
//       setResponseText(data.response_text || {});
//       setValidationResults(data.validation_results || {});
//     } catch (error) {
//       console.error('Error fetching response text data:', error);
//     }
//   };

//   const fetchTpaAmount = async (patientId, claimId) => {
//     try {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/fetch_tpa_amount?patient_id=${patientId}&claim_id=${claimId}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error('Failed to fetch TPA amount');
//       }

//       const data = await response.json();
//       setTpa(data.final_settlement_amount);
//     } catch (error) {
//       console.error('Error fetching TPA amount:', error);
//     }
//   };

//   const fetchPolicyStatus = async (patientId, claimId) => {
//     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${patientId}&claim_id=${claimId}`;
//     try {
//       console.log('Fetching policy status...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Policy status fetching data:', data);
//         // console.log('Policy status data fetched:', data);

//         if (docId === 'discrepancy_summary') {
//           // Extract missed and mispriced deductions
//           const missedDeductions = data.Missed_Deductions || [];
//           const mispricedDeductions = data.Mispriced_Deductions || [];
          
//           // Prepare the discrepancy amounts based on totals
//           setPolicyDiscrepancyAmounts({
//               missed_deductions: data.Totals.deductions_missed_by_tpa || 0,
//               mispriced_deductions: data.Totals.deductions_mispriced_by_tpa || 0,
//           });
      
//           // Prepare the discrepancy information
//           setPolicyDiscrepancyInfo({
//               missed_deductions: missedDeductions.map((deduction) => ({
//                   item_name: deduction.item_name,
//                   claimed_amount: deduction.claimed_amount,
//                   payable_amount: deduction.payable_amount,
//                   reason: deduction.ice_analysis,
//               })),
//               mispriced_deductions: mispricedDeductions.map((deduction) => ({
//                   item_name: deduction.item_name,
//                   claimed_amount: deduction.claimed_amount,
//                   payable_amount: deduction.payable_amount,
//                   reason: deduction.ice_analysis,
//               })),
//           });
      
//           // Initialize with the first discrepancy amount
//           onDiscrepancyAmountUpdate(data.Totals.deductions_missed_by_tpa, 0);
//       }
//        else {
//           // Original logic for other docIds
//           if (docId === 'policy_wording_document' && data.policy_wording_document) {
//             setPolicyDiscrepancyAmounts(data.policy_wording_document);
//             setPolicyDiscrepancyInfo(data.policy_wording_document.information);
//             const initialDiscrepancyAmount = data.policy_wording_document[
//               Object.keys(data.policy_wording_document).filter((key) => key !== 'information')[discrepancyIndex]
//             ];
//             onDiscrepancyAmountUpdate(initialDiscrepancyAmount, discrepancyIndex);
//           } else if (docId === 'stg_document' && data.stg_document) {
//             const stgDiscrepancyAmount = data.stg_document.alignment_percentage;
//             onDiscrepancyAmountUpdate(stgDiscrepancyAmount, 0);
//           } else if (docId === 'policy_document' && data.policy_document) {
//             const policyDiscrepancyAmount = data.policy_document.some_property; // Make sure to replace with actual property
//             setPolicyDiscrepancyAmounts(data.policy_document);
//             setPolicyDiscrepancyInfo(data.policy_document.information);
//             onDiscrepancyAmountUpdate(policyDiscrepancyAmount, 0);
//           }else if (docId === 'nonpayables' && data.nonpayables) {
//             const nonpayables = data.nonpayables.categories.items_non_payable_but_billed_in_the_bill || [];
          
//             // Filter out items with a value of 0.0
//             const filteredNonpayables = nonpayables.filter((item) => item.value !== 0);
          
//             setNonpayablesData(filteredNonpayables);
          
//             if (filteredNonpayables.length > 0) {
//               const initialDiscrepancy = filteredNonpayables[currentDiscrepancyIndex];
//               onDiscrepancyAmountUpdate(initialDiscrepancy.value, currentDiscrepancyIndex);
//             }
//           }
//            else if (docId === 'tariff_document' && data.tariff) {
//             console.log('Handling tariff discrepancies...');
//             handleTariffDiscrepancies(data.tariff);
//           }
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleTariffDiscrepancies = (tariffData) => {
//     const discrepancies = [];
  
//     const processItem = (item, type, categoryKey) => {
//       const { billed_amount, price_range, justification, item_name, price } = item;
//       let discrepancyAmount = null;
  
//       console.log(`Processing item: ${item_name} | Type: ${type}`);
  
//       if (type === 'necessary' || type === 'non_compliant') {
//         if (
//           price_range &&
//           price_range.min !== null &&
//           price_range.max !== null &&
//           (billed_amount < price_range.min || billed_amount > price_range.max)
//         ) {
//           discrepancyAmount = billed_amount - price;
  
//           console.log(`Discrepancy found for ${item_name}: ${discrepancyAmount}`);
//         }
//       } else if (type === 'unnecessary') {
//         discrepancyAmount = billed_amount;
//         console.log(`Unnecessary item found for ${item_name}: ${discrepancyAmount}`);
//       }
  
//       if (discrepancyAmount !== null) {
//         discrepancies.push({
//           item_name,
//           discrepancyAmount,
//           justification,
//           type,
//           billedAmount: billed_amount,
//           category: categoryKey, // Assign categoryKey here
//         });
//       }
//     };
  
//     // Pass the category key when calling processItem
//     (tariffData.necessary_items || []).forEach((item) => processItem(item, 'necessary', 'necessary_items'));
//     (tariffData.non_compliant_items || []).forEach((item) => processItem(item, 'non_compliant', 'non_compliant_items'));
//     (tariffData.unnecessary_items || []).forEach((item) => processItem(item, 'unnecessary', 'unnecessary_items'));
  
//     console.log('Final tariff discrepancies:', discrepancies);
//     setTariffDiscrepancies(discrepancies);
  
//     if (discrepancies.length > 0) {
//       const initialDiscrepancy = discrepancies[currentDiscrepancyIndex];
//       onDiscrepancyAmountUpdate(initialDiscrepancy.discrepancyAmount, currentDiscrepancyIndex);
//     } else {
//       console.log('No discrepancies found in tariff document.');
//     }
//   };
  

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     console.log('Navigating to next discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const handlePrevDiscrepancy = () => {
//     console.log('Navigating to previous discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonpayablesData.length) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newIndex);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + tariffDiscrepancies.length) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newIndex);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newIndex);
//         return newIndex;
//       });
//     }
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAmountBlur();
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

//   const handleAmountChange = (e) => {
//     setEditedAmount(e.target.value);
//   };

//   const handleAmountBlur = async () => {
//     setIsEditing(false);
//     const updatedAmount = Number(editedAmount);
  
//     if (!isNaN(updatedAmount)) {
//       let payload = {};
  
//       if (docId === 'stg_document') {
//         payload = { alignment_percentage: updatedAmount };
//       } else if (docId === 'nonpayables') {
//         const itemName = nonpayablesData[discrepancyIndex]?.item_name;
//         if (itemName) {
//           payload = {
//             items_non_payable_but_billed_in_the_bill: [
//               { item_name: itemName, value: updatedAmount },
//             ],
//           };
//         }
//       } else if (docId === 'tariff_document') {
//         const currentDiscrepancy = tariffDiscrepancies[discrepancyIndex];
//         const itemName = currentDiscrepancy?.item_name;
//         const categoryKey = currentDiscrepancy?.category;
//         if (itemName && categoryKey) {
//           // Prepare the payload with the correct field based on category
//           if (categoryKey === 'necessary_items' || categoryKey === 'non_compliant_items') {
//             payload = {
//               [categoryKey]: [{ item_name: itemName, price: updatedAmount }],
//             };
//           } else if (categoryKey === 'unnecessary_items') {
//             payload = {
//               [categoryKey]: [{ item_name: itemName, billed_amount: updatedAmount }],
//             };
//           } else {
//             console.error('Unknown categoryKey:', categoryKey);
//             return; // Exit the function if categoryKey is unknown
//           }
//         } else {
//           console.error('Failed to construct payload. itemName or categoryKey is missing.');
//           return; // Exit the function if payload cannot be constructed
//         }
//       }
  
//       if (Object.keys(payload).length === 0) {
//         console.error('Payload is empty. Aborting fetch request.');
//         return;
//       }
  
//       try {
//         const url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${patientId}&claim_id=${claimId}`;
//         const response = await fetch(url, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         });
  
//         if (response.ok) {
//           console.log('Discrepancy updated successfully on the server.');
          
//           // Re-fetch updated data from the backend
//           await fetchPolicyStatus(patientId, claimId);
  
//           onDiscrepancyAmountUpdate(updatedAmount, discrepancyIndex);
//         } else {
//           console.error('Failed to update discrepancy on the server.');
//         }
//       } catch (error) {
//         console.error('Error updating discrepancy:', error);
//       }
//     }
//   };
  

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Tariff',
//     nonpayables: 'Non Payables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   const formattedDocId = toTitleCase(docId);

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//     (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//   );
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex] || '';

//   const formatDiscrepancyText = (text) => {
//     return text
//       .split('_')  // Split by underscores
//       .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize first letter of each word
//       .join(' ');  // Join words with space
//   };
  
//   const discrepancyText =
//     docId === 'discrepancy_summary'
//       ? formatDiscrepancyText(filteredDiscrepancyKey)
//     : docId === 'nonpayables'
//     ? nonpayablesData[discrepancyIndex]?.item_name || ''
//     : docId === 'tariff_document'
//     ? tariffDiscrepancies[discrepancyIndex]?.item_name || ''
//     : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.reason || validationResults[docId]?.reason?.[0] || '';

//     const detailsText =
//     docId === 'discrepancy_summary'
//       ? filteredDiscrepancyKey === 'missed_deductions'
//         ? policyDiscrepancyInfo.missed_deductions
//           .map(
//             (item) =>
//               `<strong>${item.item_name}</strong>: Claimed ₹${item.claimed_amount.toLocaleString()} <br>Reason: ${item.reason}`
//           )
//           .join('<br><br>')
//         : filteredDiscrepancyKey === 'mispriced_deductions'
//         ? policyDiscrepancyInfo.mispriced_deductions
//           .map(
//             (item) =>
//               `<strong>${item.item_name}</strong>: Claimed ₹${item.claimed_amount.toLocaleString()} <br>Reason: ${item.reason}`
//           )
//           .join('<br><br>')
//         : ''
//       : docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.reason || ''
//       : docId === 'tariff_document'
//       ? tariffDiscrepancies[discrepancyIndex]?.justification || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.details ||
//         responseText[docId]?.[0] ||
//         '';
  
  
//   const billedAmount = docId === 'tariff_document' && tariffDiscrepancies[discrepancyIndex]
//     ? tariffDiscrepancies[discrepancyIndex].billedAmount
//     : null;

//   // if (
//   //   !hospitalName ||
//   //   !patientName ||
//   //   !primaryDiagnosis ||
//   //   !claimAmounts.initial_total_net_amount
//   // ) {
//   //   return <p>Loading...</p>;
//   // }

//     let claimedAmount = claimAmounts.initial_total_net_amount;

// // let claimedAmount = tpa ;
// let estEligibleAmountDisplay = iceAuthorizedAmount || 'Loading...';
// let estRecovery =
//   claimedAmount !== null &&
//   claimedAmount !== undefined &&
//   estEligibleAmountDisplay !== 'Loading...'
//     ? claimedAmount - estEligibleAmountDisplay
//     : 'Calculating...';




    
//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {formattedDocId}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>

//         {!isClaimDetailsPage && (
//           <div className="input-group">
//           <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>
//             <FaArrowLeft style={{ marginRight: '5px' }} /> Prev Deductions
//           </button>
//           <button className="secondary-button next-button" onClick={handleNextDiscrepancy}>
//             Next Deductions <FaArrowRight style={{ marginLeft: '5px' }} />
//           </button>
//         </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//         {patientName && (
//     <div className="claim-item">
//       <div className="icon user-icon"></div>
//       <div>
//         <p className="claimant-name">{patientName}</p>
//         <p className="fraud-risk">
//           Fraud Risk: <span className="low-risk">Low</span>
//         </p>
//       </div>
//     </div>
//   )}

//   {/* Hospital Name Section */}
//   {hospitalName && (
//     <div className="claim-item">
//       <div className="icon hospital-icon"></div>
//       <div>
//         <p className="hospital-name">{hospitalName}</p>
//         <p className="fraud-risk">
//           Fraud Risk:{' '}
//           <span className={isHospitalBlacklisted ? 'blacklisted-risk' : 'low-risk'}>
//             {isHospitalBlacklisted ? 'Blacklisted' : 'Low'}
//           </span>
//         </p>
//       </div>
//     </div>
//   )}

//   {/* Primary Diagnosis Section */}
//   {primaryDiagnosis && (
//     <div className="claim-item">
//       <div className="icon diagnosis-icon"></div>
//       <div>
//         <p className="primary-diagnosis">{primaryDiagnosis}</p>
//       </div>
//     </div>
//   )}
// </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>Claimed Amount</p>
//             <p className="amount">
//               ₹{' '}
//               {claimedAmount !== null && claimedAmount !== undefined
//                 ? Number(claimedAmount).toLocaleString('en-IN')
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>ICE Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {estEligibleAmountDisplay !== null && estEligibleAmountDisplay !== undefined && !isNaN(estEligibleAmountDisplay)
//                 ? Number(estEligibleAmountDisplay).toLocaleString('en-IN')
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Deductions</p>
//             <p className="amount">
//               ₹{' '}
//               {estRecovery !== 'Calculating...' && estRecovery !== null && estRecovery !== undefined && !isNaN(estRecovery)
//                 ? Number(estRecovery).toLocaleString('en-IN')
//                 : estRecovery}
//             </p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 <p><strong>Discrepancy :</strong> {discrepancyText || 'No discrepancies found.'}</p>
//                 <p>
//                   <strong>Details:  </strong>
//                   <span
//                     dangerouslySetInnerHTML={{
//                       __html: marked(
//                         isExpanded
//                           ? detailsText
//                           : truncateText(detailsText, 200)
//                       ),
//                     }}
//                   />
//                   {!isExpanded && detailsText.length > 200 && (
//                     <span className="read-more" onClick={handleToggleExpand}>
//                       {' '}
//                       read more
//                     </span>
//                   )}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//           <div className="discrepancy-header">
//     <p>
//       {docId === 'stg_document' ? 'Similarity Score' : 'Discrepancy Amount'}
//       <img
//         src={InfoDiscrepancyIcon} // Use the appropriate icon file path
//         alt="Info Icon"
//         className="discrepancy-info-icon"
//         title="You are making changes into the discrepancy amount." // Tooltip text
//       />
//     </p>
//   </div>
//   <div className="editable-amount">
//     {isEditing ? (
//       <input
//         type="text"
//         value={editedAmount}
//         onChange={handleAmountChange}
//         onBlur={handleAmountBlur}
//         onKeyDown={handleKeyDown}
//         autoFocus
//       />
//     ) : (
//       <div className="amount-container">
//         <p className="amount">
//           {docId === 'stg_document'
//             ? `${discrepancyAmount}%`
//             : `₹ ${
//                 typeof discrepancyAmount === 'number'
//                   ? discrepancyAmount.toLocaleString()
//                   : 'Loading...'
//               }`}
//         </p>
//         <img
//           src={PencilIcon}
//           alt="Edit Icon"
//           className="edit-icon-inline"
//           onClick={handleEditClick}
//         />
//       </div>
//     )}
//       {docId === 'tariff_document' && billedAmount !== null && billedAmount !== undefined && !isNaN(billedAmount) && tariffDiscrepancies[discrepancyIndex].type !== 'unnecessary' && (
//         <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//           Billed Amount: ₹{Number(billedAmount).toLocaleString('en-IN')}
//         </p>
//       )}
//       {docId !== 'stg_document' && (
//         <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//           {filteredDiscrepancyKey}
//         </p>
//       )}

//   </div>

//   {isPolicyValid && (
//     <>
//       {overruledDiscrepancies[discrepancyIndex] && (
//         <p
//           className="overruled-text"
//           style={{
//             color: 'red',
//             fontSize: '20px',
//             fontWeight: 'bold',
//             marginTop: '-5px',
//           }}
//         >
//           (OVERRULED)
//         </p>
//       )}
//     </>
//   )}
// </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;




//*************************** */

// Header.js

// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import InfoIcon from '../icons/infoicon.svg';
// import PencilIcon from '../icons/pencilicon.svg';
// import { marked } from 'marked';
// import { API_ENDPOINTS } from '../config';
// import InfoDiscrepancyIcon from '../icons/information.svg';
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// function Header({
//   docId,
//   estEligibleAmount,
//   discrepancyAmount,
//   onOverruleDiscrepancy,
//   onDiscrepancyAmountUpdate,
//   overruledDiscrepancies,
//   currentDiscrepancyKey,
//   showDisclaimer = true,
//   fetchResponseText = true,
//   iceAuthorizedAmount,
//   patientId, // Add patientId prop
//   claimId,   // Add claimId prop
//   setCurrentDiscrepancyKey, // Add this line
// }) {
//   const [hospitalName, setHospitalName] = useState(null);
//   const [patientName, setPatientName] = useState(null);
//   const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
//   const [responseText, setResponseText] = useState({});
//   const [validationResults, setValidationResults] = useState({});
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [claimAmounts, setClaimAmounts] = useState({
//     initial_total_net_amount: null,
//     total_net_amount_after_proportional_deduction: null,
//     total_net_amount_after_copayment: null,
//     total_net_amount_after_sublimit: null,
//     total_exclusion_amount: null,
//     amount_subject_to_proportional_deduction: null,
//     total_net_amount_after_deductible: null,
//     total_copayment: null,
//   });
//   const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
//   const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
//   const [nonpayablesData, setNonpayablesData] = useState([]);
//   const [tariffDiscrepancies, setTariffDiscrepancies] = useState([]);
//   const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
//   const [isPolicyValid, setIsPolicyValid] = useState(true);
//   const [discrepancyIndex, setDiscrepancyIndex] = useState(0);
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedAmount, setEditedAmount] = useState('');
//   const [tpa, setTpa] = useState(null);  // State to store the fetched TPA amount
//   const location = useLocation();

//   useEffect(() => {
//     if (!patientId || !claimId) {
//       console.error('Missing patientId or claimId');
//       return;
//     }

//     // Fetch all necessary data for the header
//     console.log('Fetching initial data...');
//     fetchClaimData(patientId, claimId);
//     fetchClaimAmounts(patientId, claimId);
//     fetchTpaAmount(patientId, claimId);

//     if (fetchResponseText) {
//       fetchResponseTextData(patientId, claimId);
//     }
//     fetchPolicyStatus(patientId, claimId);
//   }, [docId, fetchResponseText, patientId, claimId]);

//   useEffect(() => {
//     console.log('Setting initial edited amount:', discrepancyAmount);
//     if (discrepancyAmount !== undefined && discrepancyAmount !== null) {
//       setEditedAmount(discrepancyAmount);
//     }
//   }, [discrepancyAmount]);

//   const fetchClaimData = async (patientId, claimId) => {
//     try {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/claim-data?patient_id=${patientId}&claim_id=${claimId}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error('Failed to fetch claim data');
//       }

//       const data = await response.json();
//       const { hospital_details, patient_details, admission_details, blacklisted_hospital } = data;

//       setHospitalName(hospital_details?.name || null);
//       setPatientName(patient_details?.name || null);
//       setPrimaryDiagnosis(admission_details?.primary_diagnosis || null);
//       setIsHospitalBlacklisted(blacklisted_hospital === 'True');
//     } catch (error) {
//       console.error('Error fetching claim data:', error);
//     }
//   };

//   const fetchClaimAmounts = async (patientId, claimId) => {
//     try {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/claim-amounts?patient_id=${patientId}&claim_id=${claimId}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error('Failed to fetch claim amounts');
//       }

//       const data = await response.json();
//       setClaimAmounts({
//         initial_total_net_amount: data.initial_total_net_amount,
//         total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
//         total_net_amount_after_copayment: data.total_net_amount_after_copayment,
//         total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
//         total_exclusion_amount: data.total_exclusion_amount,
//         amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
//         total_net_amount_after_deductible: data.total_net_amount_after_deductible,
//         total_copayment: data.total_copayment,
//       });
//     } catch (error) {
//       console.error('Error fetching claim amounts:', error);
//     }
//   };

//   const fetchResponseTextData = async (patientId, claimId) => {
//     try {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data?patient_id=${patientId}&claim_id=${claimId}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error('Failed to fetch response text data');
//       }

//       const data = await response.json();
//       setResponseText(data.response_text || {});
//       setValidationResults(data.validation_results || {});
//     } catch (error) {
//       console.error('Error fetching response text data:', error);
//     }
//   };

//   const fetchTpaAmount = async (patientId, claimId) => {
//     try {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/fetch_tpa_amount?patient_id=${patientId}&claim_id=${claimId}`;
//       const response = await fetch(url);

//       if (!response.ok) {
//         throw new Error('Failed to fetch TPA amount');
//       }

//       const data = await response.json();
//       setTpa(data.final_settlement_amount);
//     } catch (error) {
//       console.error('Error fetching TPA amount:', error);
//     }
//   };

//   const fetchPolicyStatus = async (patientId, claimId) => {
//     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${patientId}&claim_id=${claimId}`;
//     try {
//       console.log('Fetching policy status...');
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Policy status fetching data:', data);

//         if (docId === 'discrepancy_summary') {
//           // Extract missed and mispriced deductions
//           const missedDeductions = data.Missed_Deductions || [];
//           const mispricedDeductions = data.Mispriced_Deductions || [];
          
//           // Prepare the discrepancy amounts based on totals
//           setPolicyDiscrepancyAmounts({
//               missed_deductions: data.Totals.deductions_missed_by_tpa || 0,
//               mispriced_deductions: data.Totals.deductions_mispriced_by_tpa || 0,
//           });
      
//           // Prepare the discrepancy information
//           setPolicyDiscrepancyInfo({
//               missed_deductions: missedDeductions.map((deduction) => ({
//                   item_name: deduction.item_name,
//                   claimed_amount: deduction.claimed_amount,
//                   payable_amount: deduction.payable_amount,
//                   reason: deduction.ice_analysis,
//               })),
//               mispriced_deductions: mispricedDeductions.map((deduction) => ({
//                   item_name: deduction.item_name,
//                   claimed_amount: deduction.claimed_amount,
//                   payable_amount: deduction.payable_amount,
//                   reason: deduction.ice_analysis,
//               })),
//           });
      
//           // Initialize with the first discrepancy amount
//           const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts).filter(
//             (key) => policyDiscrepancyAmounts[key] !== 0
//           );
//           if (nonZeroDiscrepancyKeys.length > 0) {
//             const initialDiscrepancyKey = nonZeroDiscrepancyKeys[discrepancyIndex];
//             const initialDiscrepancyAmount = policyDiscrepancyAmounts[initialDiscrepancyKey];
//             onDiscrepancyAmountUpdate(initialDiscrepancyAmount, initialDiscrepancyKey);
//             setCurrentDiscrepancyKey(initialDiscrepancyKey);
//             // setCurrentDiscrepancyKey(initialDiscrepancyKey);
//           }
//         } else {
//           // Original logic for other docIds
//           if (docId === 'policy_wording_document' && data.policy_wording_document) {
//             setPolicyDiscrepancyAmounts(data.policy_wording_document);
//             setPolicyDiscrepancyInfo(data.policy_wording_document.information);

//             const nonZeroDiscrepancyKeys = Object.keys(data.policy_wording_document).filter(
//               (key) => key !== 'information' && data.policy_wording_document[key] !== 0
//             );

//             if (nonZeroDiscrepancyKeys.length > 0) {
//               const initialDiscrepancyKey = nonZeroDiscrepancyKeys[discrepancyIndex];
//               const initialDiscrepancyAmount = data.policy_wording_document[initialDiscrepancyKey];
//               onDiscrepancyAmountUpdate(initialDiscrepancyAmount, initialDiscrepancyKey);
//               setCurrentDiscrepancyKey(initialDiscrepancyKey);
//             }
//           } else if (docId === 'stg_document' && data.stg_document) {
//             const stgDiscrepancyAmount = data.stg_document.alignment_percentage;
//             onDiscrepancyAmountUpdate(stgDiscrepancyAmount, 'alignment_percentage');
//             setCurrentDiscrepancyKey('alignment_percentage');
//           } else if (docId === 'policy_document' && data.policy_document) {
//             const policyDiscrepancyAmount = data.policy_document.some_property; // Replace with actual property
//             setPolicyDiscrepancyAmounts(data.policy_document);
//             setPolicyDiscrepancyInfo(data.policy_document.information);
//             onDiscrepancyAmountUpdate(policyDiscrepancyAmount, 'some_property');
//             setCurrentDiscrepancyKey('some_property');
//           } else if (docId === 'nonpayables' && data.nonpayables) {
//             const nonpayables = data.nonpayables.categories.items_non_payable_but_billed_in_the_bill || [];
            
//             // Filter out items with a value of 0.0
//             const filteredNonpayables = nonpayables.filter((item) => item.value !== 0);
            
//             setNonpayablesData(filteredNonpayables);
            
//             if (filteredNonpayables.length > 0) {
//               const initialDiscrepancy = filteredNonpayables[discrepancyIndex];
//               onDiscrepancyAmountUpdate(initialDiscrepancy.value, initialDiscrepancy.item_name);
//               setCurrentDiscrepancyKey(initialDiscrepancy.item_name);
//             }
//           } else if (docId === 'tariff_document' && data.tariff) {
//             console.log('Handling tariff discrepancies...');
//             handleTariffDiscrepancies(data.tariff);
//           }
//         }

//         setIsPolicyValid(data.policy_document?.status?.valid !== false);
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     }
//   };

//   const handleTariffDiscrepancies = (tariffData) => {
//     const discrepancies = [];
  
//     const processItem = (item, type, categoryKey) => {
//       const { billed_amount, price_range, justification, item_name, price } = item;
//       let discrepancyAmount = null;
  
//       console.log(`Processing item: ${item_name} | Type: ${type}`);
  
//       if (type === 'necessary' || type === 'non_compliant') {
//         if (
//           price_range &&
//           price_range.min !== null &&
//           price_range.max !== null &&
//           (billed_amount < price_range.min || billed_amount > price_range.max)
//         ) {
//           discrepancyAmount = billed_amount - price;
  
//           console.log(`Discrepancy found for ${item_name}: ${discrepancyAmount}`);
//         }
//       } else if (type === 'unnecessary') {
//         discrepancyAmount = billed_amount;
//         console.log(`Unnecessary item found for ${item_name}: ${discrepancyAmount}`);
//       }
  
//       if (discrepancyAmount !== null) {
//         discrepancies.push({
//           item_name,
//           discrepancyAmount,
//           justification,
//           type,
//           billedAmount: billed_amount,
//           category: categoryKey, // Assign categoryKey here
//         });
//       }
//     };
  
//     // Pass the category key when calling processItem
//     (tariffData.necessary_items || []).forEach((item) => processItem(item, 'necessary', 'necessary_items'));
//     (tariffData.non_compliant_items || []).forEach((item) => processItem(item, 'non_compliant', 'non_compliant_items'));
//     (tariffData.unnecessary_items || []).forEach((item) => processItem(item, 'unnecessary', 'unnecessary_items'));
  
//     console.log('Final tariff discrepancies:', discrepancies);
//     setTariffDiscrepancies(discrepancies);
  
//     if (discrepancies.length > 0) {
//       const initialDiscrepancy = discrepancies[discrepancyIndex];
//       onDiscrepancyAmountUpdate(initialDiscrepancy.discrepancyAmount, initialDiscrepancy.item_name, {
//         docId: docId,
//         category: initialDiscrepancy.category,
//       });
//       setCurrentDiscrepancyKey(initialDiscrepancy.item_name);
//     }
//   };

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const handleNextDiscrepancy = () => {
//     console.log('Navigating to next discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newDiscrepancy.item_name, {
//           docId: docId,
//           category: 'items_non_payable_but_billed_in_the_bill',
//         });
//         setCurrentDiscrepancyKey(newDiscrepancy.item_name);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newDiscrepancy.item_name, {
//           docId: docId,
//           category: newDiscrepancy.category,
//         });
//         setCurrentDiscrepancyKey(newDiscrepancy.item_name);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newDiscrepancyKey);
//         setCurrentDiscrepancyKey(newDiscrepancyKey);
//         return newIndex;
//       });
//     }
//   };

//   const handlePrevDiscrepancy = () => {
//     console.log('Navigating to previous discrepancy...');
//     if (docId === 'nonpayables' && nonpayablesData.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonpayablesData.length) % nonpayablesData.length;
//         const newDiscrepancy = nonpayablesData[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.value, newDiscrepancy.item_name, {
//           docId: docId,
//           category: 'items_non_payable_but_billed_in_the_bill',
//         });
//         setCurrentDiscrepancyKey(newDiscrepancy.item_name);
//         return newIndex;
//       });
//     } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + tariffDiscrepancies.length) % tariffDiscrepancies.length;
//         const newDiscrepancy = tariffDiscrepancies[newIndex];
//         onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newDiscrepancy.item_name, {
//           docId: docId,
//           category: newDiscrepancy.category,
//         });
//         setCurrentDiscrepancyKey(newDiscrepancy.item_name);
//         return newIndex;
//       });
//     } else {
//       const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//         (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//       );

//       if (nonZeroDiscrepancyKeys.length === 0) return;

//       setDiscrepancyIndex((prevIndex) => {
//         const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
//         const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
//         onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newDiscrepancyKey);
//         setCurrentDiscrepancyKey(newDiscrepancyKey);
//         return newIndex;
//       });
//     }
//   };

//   const truncateText = (text, limit) => {
//     if (typeof text !== 'string') {
//       return '';
//     }
//     if (text.length <= limit) return text;
//     return `${text.substring(0, limit)}...`;
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAmountBlur();
//     }
//   };

//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

//   const handleAmountChange = (e) => {
//     setEditedAmount(e.target.value);
//   };

//   const handleAmountBlur = async () => {
//     setIsEditing(false);
//     const updatedAmount = Number(editedAmount);
  
//     if (!isNaN(updatedAmount)) {
//       let payload = {};
//       let categoryKey = null; // Initialize categoryKey
  
//       if (docId === 'stg_document') {
//         payload = { alignment_percentage: updatedAmount };
//       } else if (docId === 'nonpayables') {
//         const itemName = nonpayablesData[discrepancyIndex]?.item_name;
//         if (itemName) {
//           payload = {
//             items_non_payable_but_billed_in_the_bill: [
//               { item_name: itemName, value: updatedAmount },
//             ],
//           };
//           categoryKey = 'items_non_payable_but_billed_in_the_bill';
//         }
//       } else if (docId === 'tariff_document') {
//         const currentDiscrepancy = tariffDiscrepancies[discrepancyIndex];
//         const itemName = currentDiscrepancy?.item_name;
//         categoryKey = currentDiscrepancy?.category;
//         if (itemName && categoryKey) {
//           if (categoryKey === 'necessary_items' || categoryKey === 'non_compliant_items') {
//             payload = {
//               [categoryKey]: [{ item_name: itemName, price: updatedAmount }],
//             };
//           } else if (categoryKey === 'unnecessary_items') {
//             payload = {
//               [categoryKey]: [{ item_name: itemName, billed_amount: updatedAmount }],
//             };
//           } else {
//             console.error('Unknown categoryKey:', categoryKey);
//             return; // Exit the function if categoryKey is unknown
//           }
//         } else {
//           console.error('Failed to construct payload. itemName or categoryKey is missing.');
//           return; // Exit the function if payload cannot be constructed
//         }
//       } else {
//         const discrepancyKey = currentDiscrepancyKey;
//         payload = { [discrepancyKey]: updatedAmount };
//       }
  
//       // Send the update to the server
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${patientId}&claim_id=${claimId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         });
  
//         if (response.ok) {
//           console.log('Discrepancy updated successfully on the server.');
  
//           // Re-fetch updated data from the backend
//           await fetchPolicyStatus(patientId, claimId);
  
//           // Call onDiscrepancyAmountUpdate with extraInfo
//           onDiscrepancyAmountUpdate(updatedAmount, currentDiscrepancyKey, {
//             docId: docId,
//             category: categoryKey,
//           });
//         } else {
//           console.error('Failed to update discrepancy on the server.');
//         }
//       } catch (error) {
//         console.error('Error updating discrepancy:', error);
//       }
//     }
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Tariff',
//     nonpayables: 'Non Payables',
//     discrepancy_summary: 'Discrepancy Summary',
//   };

//   const toTitleCase = (str) => {
//     if (!str) return '';
//     return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   const formattedDocId = toTitleCase(docId);

//   const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
//     (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
//   );
//   const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex] || '';

//   const formatDiscrepancyText = (text) => {
//     return text
//       .split('_')  // Split by underscores
//       .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize first letter of each word
//       .join(' ');  // Join words with space
//   };
  
//   const discrepancyText =
//     docId === 'discrepancy_summary'
//       ? formatDiscrepancyText(filteredDiscrepancyKey)
//     : docId === 'nonpayables'
//     ? nonpayablesData[discrepancyIndex]?.item_name || ''
//     : docId === 'tariff_document'
//     ? tariffDiscrepancies[discrepancyIndex]?.item_name || ''
//     : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.reason || validationResults[docId]?.reason?.[0] || '';

//   const detailsText =
//     docId === 'discrepancy_summary'
//       ? filteredDiscrepancyKey === 'missed_deductions'
//         ? policyDiscrepancyInfo.missed_deductions
//           .map(
//             (item) =>
//               `<strong>${item.item_name}</strong>: Claimed ₹${item.claimed_amount.toLocaleString()} <br>Reason: ${item.reason}`
//           )
//           .join('<br><br>')
//         : filteredDiscrepancyKey === 'mispriced_deductions'
//         ? policyDiscrepancyInfo.mispriced_deductions
//           .map(
//             (item) =>
//               `<strong>${item.item_name}</strong>: Claimed ₹${item.claimed_amount.toLocaleString()} <br>Reason: ${item.reason}`
//           )
//           .join('<br><br>')
//         : ''
//       : docId === 'nonpayables'
//       ? nonpayablesData[discrepancyIndex]?.reason || ''
//       : docId === 'tariff_document'
//       ? tariffDiscrepancies[discrepancyIndex]?.justification || ''
//       : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.details ||
//         responseText[docId]?.[0] ||
//         '';

//   const billedAmount = docId === 'tariff_document' && tariffDiscrepancies[discrepancyIndex]
//     ? tariffDiscrepancies[discrepancyIndex].billedAmount
//     : null;

//     let claimedAmount =
//     claimAmounts.initial_total_net_amount !== null &&
//     claimAmounts.initial_total_net_amount !== undefined
//       ? claimAmounts.initial_total_net_amount
//       : 'Loading...';
  
//   let estEligibleAmountDisplay =
//     iceAuthorizedAmount !== null && iceAuthorizedAmount !== undefined
//       ? iceAuthorizedAmount
//       : 'Loading...';
  
//   let estRecovery =
//     claimedAmount !== 'Loading...' &&
//     estEligibleAmountDisplay !== 'Loading...'
//       ? claimedAmount - estEligibleAmountDisplay
//       : 'Calculating...';
  
//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }
  

//   if (isHospitalBlacklisted) {
//     claimedAmount = 0;
//     estEligibleAmountDisplay = 0;
//     estRecovery = 0;
//   } else if (!isPolicyValid) {
//     estEligibleAmountDisplay = 0;
//     estRecovery = claimedAmount;
//   }

//   const isClaimDetailsPage = location.pathname === '/claim-details';

//   return (
//     <header className="custom-header">
//       <div className="claim-info-link">
//         <h1>
//           <Link to="/claim-details" className="breadcrumb-link">
//             Claim #112256325
//           </Link>
//           {docId && <span> &gt; {formattedDocId}</span>}
//           {isHospitalBlacklisted && (
//             <div className="alert-icon">
//               <div className="tooltip">
//                 The hospital '{hospitalName}' is blacklisted.
//               </div>
//             </div>
//           )}
//         </h1>

//         {!isClaimDetailsPage && (
//           <div className="input-group">
//             <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>
//               <FaArrowLeft style={{ marginRight: '5px' }} /> Prev Deductions
//             </button>
//             <button className="secondary-button next-button" onClick={handleNextDiscrepancy}>
//               Next Deductions <FaArrowRight style={{ marginLeft: '5px' }} />
//             </button>
//           </div>
//         )}
//       </div>
//       <div className="header-content">
//         <div className="claim-info">
//           {patientName && (
//             <div className="claim-item">
//               <div className="icon user-icon"></div>
//               <div>
//                 <p className="claimant-name">{patientName}</p>
//                 <p className="fraud-risk">
//                   Fraud Risk: <span className="low-risk">Low</span>
//                 </p>
//               </div>
//             </div>
//           )}

//           {/* Hospital Name Section */}
//           {hospitalName && (
//             <div className="claim-item">
//               <div className="icon hospital-icon"></div>
//               <div>
//                 <p className="hospital-name">{hospitalName}</p>
//                 <p className="fraud-risk">
//                   Fraud Risk:{' '}
//                   <span className={isHospitalBlacklisted ? 'blacklisted-risk' : 'low-risk'}>
//                     {isHospitalBlacklisted ? 'Blacklisted' : 'Low'}
//                   </span>
//                 </p>
//               </div>
//             </div>
//           )}

//           {/* Primary Diagnosis Section */}
//           {primaryDiagnosis && (
//             <div className="claim-item">
//               <div className="icon diagnosis-icon"></div>
//               <div>
//                 <p className="primary-diagnosis">{primaryDiagnosis}</p>
//               </div>
//             </div>
//           )}
//         </div>
//         <div className="claim-amounts">
//           <div className="summary-item">
//             <p>Claimed Amount</p>
//             <p className="amount">
//               ₹{' '}
//               {claimedAmount !== null && claimedAmount !== undefined
//                 ? Number(claimedAmount).toLocaleString('en-IN')
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>ICE Authorized</p>
//             <p className="amount">
//               ₹{' '}
//               {estEligibleAmountDisplay !== null && estEligibleAmountDisplay !== undefined && !isNaN(estEligibleAmountDisplay)
//                 ? Number(estEligibleAmountDisplay).toLocaleString('en-IN')
//                 : 'Loading...'}
//             </p>
//           </div>
//           <div className="summary-item">
//             <p>Est. Deductions</p>
//             <p className="amount">
//               ₹{' '}
//               {estRecovery !== 'Calculating...' && estRecovery !== null && estRecovery !== undefined && !isNaN(estRecovery)
//                 ? Number(estRecovery).toLocaleString('en-IN')
//                 : estRecovery}
//             </p>
//           </div>
//         </div>
//       </div>
//       {showDisclaimer && (
//         <div className={`discrepancy-info`}>
//           <div className="discrepancy-details-content">
//             <div className="discrepancy-header">
//               <img src={InfoIcon} alt="Info Icon" className="info-icon" />
//               <div className="discrepancy-text">
//                 <p><strong>Discrepancy :</strong> {discrepancyText || 'No discrepancies found.'}</p>
//                 <p>
//                   <strong>Details:  </strong>
//                   <span
//                     dangerouslySetInnerHTML={{
//                       __html: marked(
//                         isExpanded
//                           ? detailsText
//                           : truncateText(detailsText, 200)
//                       ),
//                     }}
//                   />
//                   {!isExpanded && detailsText.length > 200 && (
//                     <span className="read-more" onClick={handleToggleExpand}>
//                       {' '}
//                       read more
//                     </span>
//                   )}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="summary-item discrepancy-amount">
//             <div className="discrepancy-header">
//               <p>
//                 {docId === 'stg_document' ? 'Similarity Score' : 'Discrepancy Amount'}
//                 <img
//                   src={InfoDiscrepancyIcon}
//                   alt="Info Icon"
//                   className="discrepancy-info-icon"
//                   title="You are making changes into the discrepancy amount."
//                 />
//               </p>
//             </div>
//             <div className="editable-amount">
//               {isEditing ? (
//                 <input
//                   type="text"
//                   value={editedAmount}
//                   onChange={handleAmountChange}
//                   onBlur={handleAmountBlur}
//                   onKeyDown={handleKeyDown}
//                   autoFocus
//                 />
//               ) : (
//                 <div className="amount-container">
//                   <p className="amount">
//                     {docId === 'stg_document'
//                       ? `${discrepancyAmount}%`
//                       : `₹ ${
//                           typeof discrepancyAmount === 'number'
//                             ? discrepancyAmount.toLocaleString()
//                             : 'Loading...'
//                         }`}
//                   </p>
//                   <img
//                     src={PencilIcon}
//                     alt="Edit Icon"
//                     className="edit-icon-inline"
//                     onClick={handleEditClick}
//                   />
//                 </div>
//               )}
//               {docId === 'tariff_document' && billedAmount !== null && billedAmount !== undefined && !isNaN(billedAmount) && tariffDiscrepancies[discrepancyIndex].type !== 'unnecessary' && (
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//                   Billed Amount: ₹{Number(billedAmount).toLocaleString('en-IN')}
//                 </p>
//               )}
//               {docId !== 'stg_document' && (
//                 <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
//                   {currentDiscrepancyKey}
//                 </p>
//               )}
//             </div>

//             {isPolicyValid && (
//               <>
//                 {overruledDiscrepancies[currentDiscrepancyKey] && (
//                   <p
//                     className="overruled-text"
//                     style={{
//                       color: 'red',
//                       fontSize: '20px',
//                       fontWeight: 'bold',
//                       marginTop: '-5px',
//                     }}
//                   >
//                     (OVERRULED)
//                   </p>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// export default Header;







import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import InfoIcon from '../icons/infoicon.svg';
import PencilIcon from '../icons/pencilicon.svg';
import { marked } from 'marked';
import { API_ENDPOINTS } from '../config';
import InfoDiscrepancyIcon from '../icons/information.svg';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Import the icons
function Header({
  docId,
  estEligibleAmount,
  discrepancyAmount,
  onOverruleDiscrepancy,
  onDiscrepancyAmountUpdate,
  overruledDiscrepancies,
  currentDiscrepancyKey,
  showDisclaimer = true,
  fetchResponseText = true,
  iceAuthorizedAmount,
  patientId, // Add patientId prop
  claimId,   // Add claimId prop
  setCurrentDiscrepancyKey, // Add this line
  updatePolicyStatus, // Add this line
}) {
  const [hospitalName, setHospitalName] = useState(null);
  const [patientName, setPatientName] = useState(null);
  const [primaryDiagnosis, setPrimaryDiagnosis] = useState(null);
  const [responseText, setResponseText] = useState({});
  const [validationResults, setValidationResults] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [claimAmounts, setClaimAmounts] = useState({
    initial_total_net_amount: null,
    total_net_amount_after_proportional_deduction: null,
    total_net_amount_after_copayment: null,
    total_net_amount_after_sublimit: null,
    total_exclusion_amount: null,
    amount_subject_to_proportional_deduction: null,
    total_net_amount_after_deductible: null,
    total_copayment: null,
  });
  const [policyDiscrepancyAmounts, setPolicyDiscrepancyAmounts] = useState({});
  const [policyDiscrepancyInfo, setPolicyDiscrepancyInfo] = useState({});
  const [nonpayablesData, setNonpayablesData] = useState([]);
  const [tariffDiscrepancies, setTariffDiscrepancies] = useState([]);
  const [isHospitalBlacklisted, setIsHospitalBlacklisted] = useState(false);
  const [isPolicyValid, setIsPolicyValid] = useState(true);
  const [discrepancyIndex, setDiscrepancyIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editedAmount, setEditedAmount] = useState('');
  const [tpa, setTpa] = useState(null);  // State to store the fetched TPA amount
  const location = useLocation();
  // const [estRecovery, setEstRecovery] = useState('Calculating...');



  // const calculateEstRecovery = async () => {
  //   await fetchAndCalculateEstRecovery(); // Fetch and calculate the recovery value
  // };
  
// const fetchAndCalculateEstRecovery = async () => {
//   const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
//   try {
//     console.log('Fetching totals for estRecovery...');
//     const response = await fetch(url, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json; charset=UTF-8',
//       },
//     });

//     if (response.ok) {
//       const data = await response.json();

//       // Extract totals for missed and mispriced deductions
//       const missedDeductions = data.Totals.deductions_missed_by_tpa || 0;
//       const mispricedDeductions = data.Totals.deductions_mispriced_by_tpa || 0;

//       // Calculate and store estRecovery in state
//       const recovery = missedDeductions + mispricedDeductions;
//       console.log('Estimated Recovery:', recovery);

//       setEstRecovery(recovery); // Store the recovery value in state
//     } else {
//       console.error('Failed to fetch totals for estRecovery', await response.text());
//       setEstRecovery('Error calculating recovery');
//     }
//   } catch (error) {
//     console.error('Error fetching totals for estRecovery:', error);
//     setEstRecovery('Error calculating recovery');
//   }
// };

    

  // useEffect(() => {
  //   console.log('Fetching initial data...');
  //   fetchClaimData();
  //   fetchClaimAmounts();
  //   // calculateEstRecovery();
  //   if (fetchResponseText) {
  //     fetchResponseTextData();
  //   }
  //   fetchPolicyStatus();
  // }, [docId, fetchResponseText]);

  // Automatically hide tooltip after 3 seconds
useEffect(() => {
  if (showTooltip) {
    const timer = setTimeout(() => setShowTooltip(false), 3000); // Tooltip disappears after 3 seconds
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }
}, [showTooltip]);
  useEffect(() => {

    // console.log('fetched claim and patientid' ,{patientId})
    if (!patientId || !claimId) {
        console.error('Missing patientId or claimId');
        return;
    }

    // Fetch all necessary data for the header
    console.log('Fetching initial data...');
    fetchClaimData(patientId, claimId);
    fetchClaimAmounts(patientId, claimId);
    fetchTpaAmount(patientId, claimId);

    if (fetchResponseText) {
      fetchResponseTextData(patientId, claimId);
    }
    fetchPolicyStatus(patientId, claimId);
  }, [docId, fetchResponseText, patientId, claimId]);
  //fetch tpa amount
  useEffect(() => {
    // Function to fetch the TPA amount from the API
    const fetchTpaAmount = async () => {
      try {
        const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/fetch_tpa_amount`);
        const data = await response.json();

        // Set the TPA amount with the value from the response
        setTpa(data.final_settlement_amount);
      } catch (error) {
        console.error('Error fetching the TPA amount:', error);
      }
    };

    // Call the fetch function when the component mounts
    fetchTpaAmount();
  }, []); // Empty dependency array means this useEffect runs once when the component mounts


  useEffect(() => {
    console.log('Setting initial edited amount:', discrepancyAmount);
    if (discrepancyAmount !== undefined && discrepancyAmount !== null) {
      setEditedAmount(discrepancyAmount);
    }
  }, [discrepancyAmount]);

  const fetchClaimData = async (patientId, claimId) => {
    try {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/claim-data?patient_id=${patientId}&claim_id=${claimId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Failed to fetch claim data');
      }

      const data = await response.json();
      const { hospital_details, patient_details, admission_details, blacklisted_hospital } = data;

      setHospitalName(hospital_details?.name || null);
      setPatientName(patient_details?.name || null);
      setPrimaryDiagnosis(admission_details?.primary_diagnosis || null);
      setIsHospitalBlacklisted(blacklisted_hospital === 'True');
    } catch (error) {
      console.error('Error fetching claim data:', error);
    }
  };

  const fetchClaimAmounts = async (patientId, claimId) => {
    try {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/claim-amounts?patient_id=${patientId}&claim_id=${claimId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Failed to fetch claim amounts');
      }

      const data = await response.json();
      setClaimAmounts({
        initial_total_net_amount: data.initial_total_net_amount,
        total_net_amount_after_proportional_deduction: data.total_net_amount_after_proportional_deduction,
        total_net_amount_after_copayment: data.total_net_amount_after_copayment,
        total_net_amount_after_sublimit: data.total_net_amount_after_sublimit,
        total_exclusion_amount: data.total_exclusion_amount,
        amount_subject_to_proportional_deduction: data.amount_subject_to_proportional_deduction,
        total_net_amount_after_deductible: data.total_net_amount_after_deductible,
        total_copayment: data.total_copayment,
      });
    } catch (error) {
      console.error('Error fetching claim amounts:', error);
    }
  };

  const fetchResponseTextData = async (patientId, claimId) => {
    try {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data?patient_id=${patientId}&claim_id=${claimId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Failed to fetch response text data');
      }

      const data = await response.json();
      setResponseText(data.response_text || {});
      setValidationResults(data.validation_results || {});
    } catch (error) {
      console.error('Error fetching response text data:', error);
    }
  };

  const fetchTpaAmount = async (patientId, claimId) => {
    try {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/fetch_tpa_amount?patient_id=${patientId}&claim_id=${claimId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Failed to fetch TPA amount');
      }

      const data = await response.json();
      setTpa(data.final_settlement_amount);
    } catch (error) {
      console.error('Error fetching TPA amount:', error);
    }
  };

  const fetchPolicyStatus = async (patientId, claimId) => {
    const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status?patient_id=${patientId}&claim_id=${claimId}`;
    try {
      console.log('Fetching policy status...');
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Policy status fetching data:', data);

        if (docId === 'discrepancy_summary') {
          // Extract missed and mispriced deductions
          const missedDeductions = data.Missed_Deductions || [];
          const mispricedDeductions = data.Mispriced_Deductions || [];
          
          // Prepare the discrepancy amounts based on totals
          setPolicyDiscrepancyAmounts({
              missed_deductions: data.Totals.deductions_missed_by_tpa || 0,
              mispriced_deductions: data.Totals.deductions_mispriced_by_tpa || 0,
          });
      
          // Prepare the discrepancy information
          setPolicyDiscrepancyInfo({
              missed_deductions: missedDeductions.map((deduction) => ({
                  item_name: deduction.item_name,
                  claimed_amount: deduction.claimed_amount,
                  payable_amount: deduction.payable_amount,
                  reason: deduction.ice_analysis,
              })),
              mispriced_deductions: mispricedDeductions.map((deduction) => ({
                  item_name: deduction.item_name,
                  claimed_amount: deduction.claimed_amount,
                  payable_amount: deduction.payable_amount,
                  reason: deduction.ice_analysis,
              })),
          });
      
          // Initialize with the first discrepancy amount
          const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts).filter(
            (key) => policyDiscrepancyAmounts[key] !== 0
          );
          if (nonZeroDiscrepancyKeys.length > 0) {
            const initialDiscrepancyKey = nonZeroDiscrepancyKeys[discrepancyIndex];
            const initialDiscrepancyAmount = policyDiscrepancyAmounts[initialDiscrepancyKey];
            onDiscrepancyAmountUpdate(initialDiscrepancyAmount, initialDiscrepancyKey);
            setCurrentDiscrepancyKey(initialDiscrepancyKey);
            // setCurrentDiscrepancyKey(initialDiscrepancyKey);
          }
        } else {
          // Original logic for other docIds
          if (docId === 'policy_wording_document' && data.policy_wording_document) {
            setPolicyDiscrepancyAmounts(data.policy_wording_document);
            setPolicyDiscrepancyInfo(data.policy_wording_document.information);

            const nonZeroDiscrepancyKeys = Object.keys(data.policy_wording_document).filter(
              (key) => key !== 'information' && data.policy_wording_document[key] !== 0
            );

            if (nonZeroDiscrepancyKeys.length > 0) {
              const initialDiscrepancyKey = nonZeroDiscrepancyKeys[discrepancyIndex];
              const initialDiscrepancyAmount = data.policy_wording_document[initialDiscrepancyKey];
              onDiscrepancyAmountUpdate(initialDiscrepancyAmount, initialDiscrepancyKey);
              setCurrentDiscrepancyKey(initialDiscrepancyKey);
            }
          } else if (docId === 'stg_document' && data.stg_document) {
            const stgDiscrepancyAmount = data.stg_document.alignment_percentage;
            onDiscrepancyAmountUpdate(stgDiscrepancyAmount, 'alignment_percentage');
            setCurrentDiscrepancyKey('alignment_percentage');
          } else if (docId === 'policy_document' && data.policy_document) {
            const policyDiscrepancyAmount = data.policy_document.some_property; // Replace with actual property
            setPolicyDiscrepancyAmounts(data.policy_document);
            setPolicyDiscrepancyInfo(data.policy_document.information);
            onDiscrepancyAmountUpdate(policyDiscrepancyAmount, 'some_property');
            setCurrentDiscrepancyKey('some_property');
          } else if (docId === 'nonpayables' && data.nonpayables) {
            const nonpayables = data.nonpayables.categories.items_non_payable_but_billed_in_the_bill || [];
            
            // Filter out items with a value of 0.0
            const filteredNonpayables = nonpayables.filter((item) => item.value !== 0);
            
            setNonpayablesData(filteredNonpayables);
            
            if (filteredNonpayables.length > 0) {
              const initialDiscrepancy = filteredNonpayables[discrepancyIndex];
              onDiscrepancyAmountUpdate(initialDiscrepancy.value, initialDiscrepancy.item_name);
              setCurrentDiscrepancyKey(initialDiscrepancy.item_name);
            }
          } else if (docId === 'tariff_document' && data.tariff) {
            console.log('Handling tariff discrepancies...');
            handleTariffDiscrepancies(data.tariff);
          }
        }

        setIsPolicyValid(data.policy_document?.status?.valid !== false);
      } else {
        console.error('Failed to fetch policy status', await response.text());
      }
    } catch (error) {
      console.error('Error fetching policy status:', error);
    }
  };

  const handleTariffDiscrepancies = (tariffData) => {
    const discrepancies = [];
  
    const processItem = (item, type, categoryKey) => {
        const { billed_amount, price_range, justification, item_name, price, tariff_amount, no_of_units } = item;
        let discrepancyAmount = null;
  
        console.log(`Processing item: ${item_name} | Type: ${type}`);

        // Skip items with billed_amount of 0
        if (billed_amount === 0) {
            console.log(`Skipping item with billed_amount 0: ${item_name}`);
            return;
        }

        // Additional condition for necessary items: skip if no valid price is present
        // Normalize the price field to handle different data types
    const normalizedPrice = typeof price === "string" ? price.trim().toLowerCase() : price;

    // Additional condition for necessary items: skip if no valid price is present
    if (
      type === 'necessary' &&
      (!normalizedPrice || normalizedPrice === "price not found" || normalizedPrice === null) && // Check for invalid price or null
      (!price_range || 
          Number(price_range.min) === 0 || Number(price_range.max) === 0 || // Check for zero price range
          price_range.min === null || price_range.max === null // Check for null min/max
      )
  ) {
      console.log(`Skipping necessary item with no valid price or price range: ${item_name}`);
      return;
  }
  

        if (type === 'necessary' || type === 'non_compliant') {
            // Additional condition for non-compliant: billed_amount must be >= tariff_amount
            if (
                type === 'non_compliant' && 
                tariff_amount && 
                billed_amount < tariff_amount
            ) {
                console.log(`Skipping non-compliant item with billed_amount < tariff_amount: ${item_name}`);
                return;
            }

            if (
                price_range &&
                price_range.min !== null &&
                price_range.max !== null &&
                price &&
                (billed_amount < price_range.min || billed_amount > price_range.max)
            ) {
                discrepancyAmount = billed_amount;
                console.log(`Discrepancy found for ${item_name}: ${discrepancyAmount}`);
            }
        } else if (type === 'unnecessary') {
            discrepancyAmount = billed_amount;
            console.log(`Unnecessary item found for ${item_name}: ${discrepancyAmount}`);
        }

        if (discrepancyAmount !== null) {
            discrepancies.push({
                item_name,
                discrepancyAmount,
                justification,
                type,
                billedAmount: billed_amount,
                priceAmount: price,
                tariffAmount: tariff_amount,
                noOfUnits: no_of_units,
                category: categoryKey, // Assign categoryKey here
            });
        }
    };
  
    // Pass the category key when calling processItem
    (tariffData.necessary_items || []).forEach((item) => processItem(item, 'necessary', 'necessary_items'));
    (tariffData.non_compliant_items || []).forEach((item) => processItem(item, 'non_compliant', 'non_compliant_items'));
    (tariffData.unnecessary_items || []).forEach((item) => processItem(item, 'unnecessary', 'unnecessary_items'));
  
    console.log('Final tariff discrepancies:', discrepancies);
    setTariffDiscrepancies(discrepancies);
  
    if (discrepancies.length > 0) {
      const initialDiscrepancy = discrepancies[discrepancyIndex];
      onDiscrepancyAmountUpdate(initialDiscrepancy.discrepancyAmount, initialDiscrepancy.item_name, {
        docId: docId,
        category: initialDiscrepancy.category,
      });
      setCurrentDiscrepancyKey(initialDiscrepancy.item_name);
    }
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNextDiscrepancy = () => {
    console.log('Navigating to next discrepancy...');
    if (docId === 'nonpayables' && nonpayablesData.length > 0) {
      setDiscrepancyIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % nonpayablesData.length;
        const newDiscrepancy = nonpayablesData[newIndex];
        onDiscrepancyAmountUpdate(newDiscrepancy.value, newDiscrepancy.item_name, {
          docId: docId,
          category: 'items_non_payable_but_billed_in_the_bill',
        });
        setCurrentDiscrepancyKey(newDiscrepancy.item_name);
        return newIndex;
      });
    } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
      setDiscrepancyIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % tariffDiscrepancies.length;
        const newDiscrepancy = tariffDiscrepancies[newIndex];
        onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newDiscrepancy.item_name, {
          docId: docId,
          category: newDiscrepancy.category,
        });
        setCurrentDiscrepancyKey(newDiscrepancy.item_name);
        return newIndex;
      });
    } else {
      const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
        (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
      );

      if (nonZeroDiscrepancyKeys.length === 0) return;

      setDiscrepancyIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % nonZeroDiscrepancyKeys.length;
        const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
        onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newDiscrepancyKey);
        setCurrentDiscrepancyKey(newDiscrepancyKey);
        return newIndex;
      });
    }
  };

  const handlePrevDiscrepancy = () => {
    console.log('Navigating to previous discrepancy...');
    if (docId === 'nonpayables' && nonpayablesData.length > 0) {
      setDiscrepancyIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + nonpayablesData.length) % nonpayablesData.length;
        const newDiscrepancy = nonpayablesData[newIndex];
        onDiscrepancyAmountUpdate(newDiscrepancy.value, newDiscrepancy.item_name, {
          docId: docId,
          category: 'items_non_payable_but_billed_in_the_bill',
        });
        setCurrentDiscrepancyKey(newDiscrepancy.item_name);
        return newIndex;
      });
    } else if (docId === 'tariff_document' && tariffDiscrepancies.length > 0) {
      setDiscrepancyIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + tariffDiscrepancies.length) % tariffDiscrepancies.length;
        const newDiscrepancy = tariffDiscrepancies[newIndex];
        onDiscrepancyAmountUpdate(newDiscrepancy.discrepancyAmount, newDiscrepancy.item_name, {
          docId: docId,
          category: newDiscrepancy.category,
        });
        setCurrentDiscrepancyKey(newDiscrepancy.item_name);
        return newIndex;
      });
    } else {
      const nonZeroDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
        (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
      );

      if (nonZeroDiscrepancyKeys.length === 0) return;

      setDiscrepancyIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + nonZeroDiscrepancyKeys.length) % nonZeroDiscrepancyKeys.length;
        const newDiscrepancyKey = nonZeroDiscrepancyKeys[newIndex];
        onDiscrepancyAmountUpdate(policyDiscrepancyAmounts[newDiscrepancyKey], newDiscrepancyKey);
        setCurrentDiscrepancyKey(newDiscrepancyKey);
        return newIndex;
      });
    }
  };

  const truncateText = (text, limit) => {
    if (typeof text !== 'string') {
      return '';
    }
    if (text.length <= limit) return text;
    return `${text.substring(0, limit)}...`;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAmountBlur();
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleAmountChange = (e) => {
    setEditedAmount(e.target.value);
  };

  const handleAmountBlur = async () => {
    setIsEditing(false);
    const updatedAmount = Number(editedAmount);
  
    if (!isNaN(updatedAmount)) {
      let payload = {};
      let categoryKey = null; // Initialize categoryKey
  
      if (docId === 'stg_document') {
        payload = { alignment_percentage: updatedAmount };
      } else if (docId === 'nonpayables') {
        const itemName = nonpayablesData[discrepancyIndex]?.item_name;
        if (itemName) {
          payload = {
            items_non_payable_but_billed_in_the_bill: [
              { item_name: itemName, value: updatedAmount },
            ],
          };
          categoryKey = 'items_non_payable_but_billed_in_the_bill';
        }
      } else if (docId === 'tariff_document') {
        const currentDiscrepancy = tariffDiscrepancies[discrepancyIndex];
        const itemName = currentDiscrepancy?.item_name;
        categoryKey = currentDiscrepancy?.category;
        if (itemName && categoryKey) {
          if (categoryKey === 'necessary_items' || categoryKey === 'non_compliant_items') {
            payload = {
              // [categoryKey]: [{ item_name: itemName, price: updatedAmount }],
              [categoryKey]: [{ item_name: itemName, billed_amount: updatedAmount }],
            };
          } else if (categoryKey === 'unnecessary_items') {
            payload = {
              [categoryKey]: [{ item_name: itemName, billed_amount: updatedAmount }],
            };
          } else {
            console.error('Unknown categoryKey:', categoryKey);
            return; // Exit the function if categoryKey is unknown
          }
        } else {
          console.error('Failed to construct payload. itemName or categoryKey is missing.');
          return; // Exit the function if payload cannot be constructed
        }
      } else {
        const discrepancyKey = currentDiscrepancyKey;
        payload = { [discrepancyKey]: updatedAmount };
      }
  
      // Send the update to the server
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${patientId}&claim_id=${claimId}`;
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          console.log('Discrepancy updated successfully on the server.');
  
          // Re-fetch updated data from the backend
          // await fetchPolicyStatus(patientId, claimId);
          updatePolicyStatus(); // Use this instead of fetchPolicyStatus
          // Call onDiscrepancyAmountUpdate with extraInfo
          onDiscrepancyAmountUpdate(updatedAmount, currentDiscrepancyKey, {
            docId: docId,
            category: categoryKey,
          });
        } else {
          console.error('Failed to update discrepancy on the server.');
        }
      } catch (error) {
        console.error('Error updating discrepancy:', error);
      }
    }
  };
  

  const nameMappings = {
    policy_document: 'Enrollment',
    policy_wording_document: 'Policy Wording',
    stg_document: 'Standard Treatment Guidelines',
    tariff_document: 'Tariff',
    nonpayables: 'Non Payables',
    discrepancy_summary: 'Discrepancy Summary',
  };

  const toTitleCase = (str) => {
    if (!str) return '';
    return nameMappings[str] || str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
      if (txt.toLowerCase() === 'stg') {
        return 'STG';
      }
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const formattedDocId = toTitleCase(docId);

  const filteredDiscrepancyKeys = Object.keys(policyDiscrepancyAmounts || {}).filter(
    (key) => key !== 'information' && policyDiscrepancyAmounts[key] !== 0
  );
  const filteredDiscrepancyKey = filteredDiscrepancyKeys[discrepancyIndex] || '';

  const formatDiscrepancyText = (text) => {
    return text
      .split('_')  // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize first letter of each word
      .join(' ');  // Join words with space
  };
  
  const discrepancyText =
    docId === 'discrepancy_summary'
      ? formatDiscrepancyText(filteredDiscrepancyKey)
    : docId === 'nonpayables'
    ? nonpayablesData[discrepancyIndex]?.item_name || ''
    : docId === 'tariff_document'
    ? tariffDiscrepancies[discrepancyIndex]?.item_name || ''
    : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.reason || validationResults[docId]?.reason?.[0] || '';

    const detailsText =
    docId === 'discrepancy_summary'
      ? filteredDiscrepancyKey === 'missed_deductions'
        ? policyDiscrepancyInfo.missed_deductions
          .map(
            (item) =>
              `<strong>${item.item_name}</strong>: Claimed ₹${item.claimed_amount.toLocaleString()} <br>Reason: ${item.reason}`
          )
          .join('<br><br>')
        : filteredDiscrepancyKey === 'mispriced_deductions'
        ? policyDiscrepancyInfo.mispriced_deductions
          .map(
            (item) =>
              `<strong>${item.item_name}</strong>: Claimed ₹${item.claimed_amount.toLocaleString()} <br>Reason: ${item.reason}`
          )
          .join('<br><br>')
        : ''
      : docId === 'nonpayables'
      ? nonpayablesData[discrepancyIndex]?.reason || ''
      : docId === 'tariff_document'
      ? tariffDiscrepancies[discrepancyIndex]?.justification || ''
      : policyDiscrepancyInfo?.[filteredDiscrepancyKey]?.details ||
        responseText[docId]?.[0] ||
        '';
  
  
  const billedAmount = docId === 'tariff_document' && tariffDiscrepancies[discrepancyIndex]
    ? tariffDiscrepancies[discrepancyIndex].billedAmount
    : null;
  const priceAmount = docId === 'tariff_document' && tariffDiscrepancies[discrepancyIndex]
    ? tariffDiscrepancies[discrepancyIndex].priceAmount
    : null;
  const tariffAmount = docId === 'tariff_document' && tariffDiscrepancies[discrepancyIndex]
    ? tariffDiscrepancies[discrepancyIndex].tariffAmount
    : null;

  // if (
  //   !hospitalName ||
  //   !patientName ||
  //   !primaryDiagnosis ||
  //   !claimAmounts.initial_total_net_amount
  // ) {
  //   return <p>Loading...</p>;
  // }

let claimedAmount = claimAmounts.initial_total_net_amount;

// let claimedAmount = tpa ;
let estEligibleAmountDisplay = iceAuthorizedAmount || 'Loading...';
let estRecovery =
  claimedAmount !== null &&
  claimedAmount !== undefined &&
  estEligibleAmountDisplay !== 'Loading...'
    ? claimedAmount - estEligibleAmountDisplay
    : 'Calculating...';




    
  if (isHospitalBlacklisted) {
    claimedAmount = 0;
    estEligibleAmountDisplay = 0;
    estRecovery = 0;
  } else if (!isPolicyValid) {
    estEligibleAmountDisplay = 0;
    estRecovery = claimedAmount;
  }

  const isClaimDetailsPage = location.pathname === '/claim-details';

  return (
    <header className="custom-header">
      <div className="claim-info-link">
        <h1>
          <Link to="/claim-details" className="breadcrumb-link">
            Claim #112256325
          </Link>
          {docId && <span> &gt; {formattedDocId}</span>}
          {isHospitalBlacklisted && (
            <div className="alert-icon">
              <div className="tooltip">
                The hospital '{hospitalName}' is blacklisted.
              </div>
            </div>
          )}
        </h1>

        {!isClaimDetailsPage && (
          <div className="input-group">
          <button className="secondary-button prev-button" onClick={handlePrevDiscrepancy}>
            <FaArrowLeft style={{ marginRight: '5px' }} /> Prev Deductions
          </button>
          <button className="secondary-button next-button" onClick={handleNextDiscrepancy}>
            Next Deductions <FaArrowRight style={{ marginLeft: '5px' }} />
          </button>
        </div>
        )}
      </div>
      <div className="header-content">
        <div className="claim-info">
        {patientName && (
    <div className="claim-item">
      <div className="icon user-icon"></div>
      <div>
        <p className="claimant-name">{patientName}</p>
        <p className="fraud-risk">
          Fraud Risk: <span className="low-risk">Low</span>
        </p>
      </div>
    </div>
  )}

  {/* Hospital Name Section */}
  {hospitalName && (
    <div className="claim-item">
      <div className="icon hospital-icon"></div>
      <div>
        <p className="hospital-name">{hospitalName}</p>
        <p className="fraud-risk">
          Fraud Risk:{' '}
          <span className={isHospitalBlacklisted ? 'blacklisted-risk' : 'low-risk'}>
            {isHospitalBlacklisted ? 'Blacklisted' : 'Low'}
          </span>
        </p>
      </div>
    </div>
  )}

  {/* Primary Diagnosis Section */}
  {primaryDiagnosis && (
    <div className="claim-item">
      <div className="icon diagnosis-icon"></div>
      <div>
        <p className="primary-diagnosis">{primaryDiagnosis}</p>
      </div>
    </div>
  )}
</div>
        <div className="claim-amounts">
          <div className="summary-item">
            <p>Claimed Amount</p>
            <p className="amount">
              ₹{' '}
              {claimedAmount !== null && claimedAmount !== undefined
                ? Number(claimedAmount).toLocaleString('en-IN')
                : 'Loading...'}
            </p>
          </div>
          <div className="summary-item">
            <p>ICE Authorized</p>
            <p className="amount">
              ₹{' '}
              {estEligibleAmountDisplay !== null && estEligibleAmountDisplay !== undefined && !isNaN(estEligibleAmountDisplay)
                ? Number(estEligibleAmountDisplay).toLocaleString('en-IN')
                : 'Loading...'}
            </p>
          </div>
          <div className="summary-item">
            <p>Est. Deductions</p>
            <p className="amount">
              ₹{' '}
              {estRecovery !== 'Calculating...' && estRecovery !== null && estRecovery !== undefined && !isNaN(estRecovery)
                ? Number(estRecovery).toLocaleString('en-IN')
                : estRecovery}
            </p>
          </div>
        </div>
      </div>
      {showDisclaimer && (
  <div className={`discrepancy-info`}>
    <div className="discrepancy-details-content">
      <div className="discrepancy-header">
        <img src={InfoIcon} alt="Info Icon" className="info-icon" />
        <div className="discrepancy-text">
          <p>
            <strong>Discrepancy :</strong> {discrepancyText || 'No discrepancies found.'}
          </p>
          <p>
  <strong>Details: </strong>
  <span>
  {detailsText.split('\n').map((line, index) => {
    // Replace inline bold text with <strong> tags
    const formattedLine = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Check for bullet points
    if (line.trim().startsWith('-')) {
      return (
        <ul key={index} style={{ margin: '5px 0 5px 20px', paddingLeft: '10px' }}>
          <li dangerouslySetInnerHTML={{ __html: formattedLine.replace('- ', '') }}></li>
        </ul>
      );
    }

    // Check for links
    if (line.includes('http')) {
      const [beforeUrl, url] = line.split(/(https?:\/\/[^\s]+)/);
      return (
        <div key={index} style={{ display: 'inline-flex', alignItems: 'center', margin: '5px 0' }}>
          {/* Text before URL */}
          <span dangerouslySetInnerHTML={{ __html: beforeUrl }}></span>

          {/* Link with favicon */}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: '#0073e6',
              fontWeight: 'bold',
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: '5px',
            }}
          >
            <img
              src={`https://www.google.com/s2/favicons?sz=16&domain=${new URL(url).hostname}`}
              alt="Site Icon"
              style={{
                width: '16px',
                height: '16px',
                marginRight: '5px',
              }}
            />
            {new URL(url).hostname}
          </a>
        </div>
      );
    }

    // Default paragraph handling with inline bold text
    return (
      <span key={index} dangerouslySetInnerHTML={{ __html: formattedLine }} style={{ margin: '5px 0' }}></span>
    );
  })}
</span>

  {/* Read more functionality */}
  {!isExpanded && detailsText.length > 200 && (
    <span className="read-more" onClick={handleToggleExpand} style={{ color: '#0073e6', cursor: 'pointer' }}>
      {' '}
      read more
    </span>
  )}
</p>

        </div>
      </div>
    </div>
          <div className="summary-item discrepancy-amount">
          <div className="discrepancy-header">
    <p>
      {docId === 'stg_document' ? 'Similarity Score' : 'Total Discrepancy Amount'}
      <img
        src={InfoDiscrepancyIcon} // Use the appropriate icon file path
        alt="Info Icon"
        className="discrepancy-info-icon"
        title="You are making changes into the discrepancy amount." // Tooltip text
      />
    </p>
  </div>
  <div className="editable-amount">
  <div className="amount-container">
    {isEditing ? (
      <input
        type="text"
        value={editedAmount}
        onChange={handleAmountChange}
        onBlur={() => {
          handleAmountBlur();
          setShowTooltip(false); // Hide tooltip when editing is complete
        }}
        onKeyDown={handleKeyDown}
        autoFocus
      />
    ) : (
      <>
        <p className="amount">
          {docId === 'stg_document'
            ? `${discrepancyAmount}%`
            : `₹ ${
                typeof discrepancyAmount === 'number'
                  ? discrepancyAmount.toLocaleString()
                  : 'Loading...'
              }`}
        </p>
        <img
          src={PencilIcon}
          alt="Edit Icon"
          className="edit-icon-inline"
          onClick={() => {
            setIsEditing(true);
            setShowTooltip(true); // Show tooltip when clicked
          }}
        />
      </>
    )}
    {showTooltip && (
      <div className={`tooltip-box ${showTooltip ? 'show' : ''}`}>
        You are making changes to the discrepancy amount.
      </div>
    )}
  </div>

      {/* {docId === 'tariff_document' && billedAmount !== null && billedAmount !== undefined && !isNaN(billedAmount) && tariffDiscrepancies[discrepancyIndex].type !== 'unnecessary' && (
        <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
          Expected Amount: ₹{Number(billedAmount).toLocaleString('en-IN')}
        </p>
      )} */}
      {/* {docId === 'tariff_document' &&
  (priceAmount > 0 || tariffAmount > 0) && // Exclude 0 or null amounts
  !isNaN(priceAmount) &&
  tariffDiscrepancies[discrepancyIndex]?.type !== 'unnecessary' && (
    <p
      className="tag"
      style={{
        fontSize: 'small',
        marginTop: '5px', // Adjusted spacing
        marginLeft: '0px', // Align to left
        padding: '4px 8px', // Add padding for better appearance
        backgroundColor: '#E7F8F1', // Light background
        color: '#0B6623', // Green text
        borderRadius: '5px', // Rounded corners
        maxWidth: '250px', // Limit width to handle long text
        wordWrap: 'break-word', // Wrap text properly
      }}
    >
      {tariffDiscrepancies[discrepancyIndex]?.type === 'non_compliant'
        ? `Compared Against (Internet Sourced): ₹${Number(tariffAmount).toLocaleString('en-IN')}`
        : `Compared Against (Internet Sourced): ₹${Number(priceAmount).toLocaleString('en-IN')}`}
    </p>
)} */}



{/* {docId === 'tariff_document' &&
  ((tariffDiscrepancies[discrepancyIndex]?.type === 'non_compliant' && tariffAmount > 0) || 
   (tariffDiscrepancies[discrepancyIndex]?.type !== 'non_compliant' && priceAmount > 0)) &&  tariffDiscrepancies[discrepancyIndex]?.type !== 'unnecessary' &&( // Strict null/0 check
    <p
      className="tag"
      style={{
        fontSize: 'small',
        marginTop: '5px', // Adjusted spacing
        marginLeft: '0px', // Align to left
        padding: '4px 8px', // Add padding for better appearance
        backgroundColor: '#E7F8F1', // Light background
        color: '#0B6623', // Green text
        borderRadius: '5px', // Rounded corners
        maxWidth: '250px', // Limit width to handle long text
        wordWrap: 'break-word', // Wrap text properly
      }}
    >
      {tariffDiscrepancies[discrepancyIndex]?.type === 'non_compliant'
        ? `Against Tariff: ₹${Number(tariffAmount).toLocaleString('en-IN')}`
        : `Internet Sourced: ₹${Number(priceAmount).toLocaleString('en-IN')}`}
    </p>
)}
 */}

{docId === 'tariff_document' &&
  ((tariffDiscrepancies[discrepancyIndex]?.type === 'non_compliant' && tariffAmount > 0) || 
   (tariffDiscrepancies[discrepancyIndex]?.type !== 'non_compliant' && priceAmount > 0)) && 
   tariffDiscrepancies[discrepancyIndex]?.type !== 'unnecessary' && ( // Strict null/0 check
    <p
      className="tag"
      style={{
        fontSize: 'small',
        marginTop: '5px', // Adjusted spacing
        marginLeft: '0px', // Align to left
        padding: '4px 8px', // Add padding for better appearance
        backgroundColor: '#E7F8F1', // Light background
        color: '#0B6623', // Green text
        borderRadius: '5px', // Rounded corners
        maxWidth: '250px', // Limit width to handle long text
        wordWrap: 'break-word', // Wrap text properly
      }}
    >
      {`${
        tariffDiscrepancies[discrepancyIndex]?.type === 'non_compliant'
          ? `Against Tariff: ₹${Number(tariffAmount*tariffDiscrepancies[discrepancyIndex]?.noOfUnits).toLocaleString('en-IN')}`
          : `Internet Sourced: ₹${Number(priceAmount*tariffDiscrepancies[discrepancyIndex]?.noOfUnits).toLocaleString('en-IN')}`
      } (${tariffDiscrepancies[discrepancyIndex]?.noOfUnits || 0} unit)`}
    </p>
)}


      
      {docId !== 'stg_document' && (
        <p className="tag" style={{ fontSize: 'small', marginTop: '-5px', marginLeft: '6px' }}>
          {filteredDiscrepancyKey}
        </p>
      )}

  </div>

  {isPolicyValid && (
    <>
        {overruledDiscrepancies[currentDiscrepancyKey] && (
    <p
      className="overruled-text"
      style={{
        color: 'red',
        fontSize: '20px',
        fontWeight: 'bold',
        marginTop: '-5px',
      }}
    >
      (OVERRULED)
    </p>
  )}

    </>
  )}
</div>
        </div>
      )}
    </header>
  );
}

export default Header;

