// src/config.js
// const PYTHON_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://app.icehealth.in:5000';
// const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL || 'https://app.icehealth.in:8000';
// const PYTHON_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://65.2.86.1:5000';
// const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL || 'http://65.2.86.1:8000';
// const PYTHON_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
// const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL || 'http://localhost:8000';

const isAWSExecutionEnv = process.env.REACT_APP_AWS_EXECUTION_ENV === 'True';

const PYTHON_BASE_URL = isAWSExecutionEnv
  ? 'https://gcu6hjqgecpbm2vc7n5pjr4qce0envpy.lambda-url.ap-south-1.on.aws'
  : (process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000');

const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL || 'http://localhost:8000';

export const API_ENDPOINTS = {
  PYTHON_SERVICE: PYTHON_BASE_URL,
  EXPRESS_SERVICE: EXPRESS_BASE_URL,
};

console.log('API_ENDPOINTS:', API_ENDPOINTS);
export default API_ENDPOINTS;

