// import React, { useState } from "react";
// import logo from "../icons/logonew.svg"; // Update the path to your logo file
// import "./AccessPage.css";

// const AccessPage = () => {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form submitted:", formData);
//   };

//   return (
//     <div className="access-page">
//       {/* Logo Section */}
//       <div className="access-logo-container">
//         <img src={logo} alt="ICE Logo" className="access-logo" />
//         <span className="logo-text">ICE</span>
//       </div>

//       {/* Form Section */}
//       <div className="access-container">
//         <h1 className="access-title">Schedule Demo</h1>
//         <form className="access-form" onSubmit={handleSubmit}>
//           <div className="name-row">
//             <div className="form-group">
//               <label htmlFor="firstName">Name</label>
//               <input
//                 type="text"
//                 id="firstName"
//                 name="firstName"
//                 placeholder="Enter your name"
//                 value={formData.firstName}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="lastName">Organization name</label>
//               <input
//                 type="text"
//                 id="lastName"
//                 name="lastName"
//                 placeholder="Enter your name"
//                 value={formData.lastName}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>
//           <div className="form-group-email">
//             <label htmlFor="email">Email</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               placeholder="Enter your email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <button type="submit" className="access-button">
//             Apply for Access
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AccessPage;




// testing 

import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../icons/logonew.svg"; // Update the path to your logo file
import "./AccessPage.css";
import API_ENDPOINTS from '../config';

const AccessPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    organizationName: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/request_access`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Network response was not ok");
      }

      const result = await response.json();
      toast.success("Access request submitted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Clear the form after successful submission
      setFormData({
        firstName: "",
        organizationName: "",
        email: "",
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message || "Failed to submit access request", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="access-page">
      {/* Toast Container */}
      <ToastContainer />

      {/* Logo Section */}
      <div className="access-logo-container">
        <img src={logo} alt="ICE Logo" className="access-logo" />
        <span className="logo-text">ICE</span>
      </div>

      {/* Form Section */}
      <div className="access-container">
        <h1 className="access-title">Schedule Demo</h1>
        <form className="access-form" onSubmit={handleSubmit}>
          <div className="name-row">
            <div className="form-group">
              <label htmlFor="firstName">Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Enter your name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="organizationName">Organization Name</label>
              <input
                type="text"
                id="organizationName"
                name="organizationName"
                placeholder="Enter your organization name"
                value={formData.organizationName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group-email">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="access-button">
            Apply for Access
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccessPage;